import * as Yup from 'yup'

export const medRecordInitialState: any = {
  existing: [],
  allergy: [],
  surgical_history: [],
  disease_note: [],
  existing_text: '',
  allergy_text: '',
  surgical_history_text: '',
  disease_note_text: '',
}

export const medRecordvalidationSchema = Yup.object().shape({
  existing: Yup.array()
    .of(Yup.string())
    .required('Existing_Disease is required'),
  allergy: Yup.array()
    .of(Yup.string())
    .min(1, 'At least one allergy is required'),
  surgical_history: Yup.array()
    .of(Yup.string())
    .required('Past Surgical History is required'),
  disease_note: Yup.array()
    .of(Yup.string())
    .required('Disease Note is required'),
})
