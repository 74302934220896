import React from 'react'
import classNames from 'classnames'

const Input = ({
  value,
  className,
  onClick = () => { },
  onChange = () => { },
  onBlur = () => { },
  type = 'text',
  placeholder,
  name,
  max,
  min,
  maxLength,
  pattern,
  prefix,
  suffix,
  autoComplete,
  inputMode,
  disabled = false,
  defaultValue,
}: {
  name?: string
  value?: any
  max?: any
  min?: any
  maxLength?: any
  pattern?: any
  autoComplete?: string
  type?: string
  inputMode?: any
  className?: string
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  placeholder?: string
  prefix?: React.ReactNode
  suffix?: React.ReactNode
  disabled?: boolean
  defaultValue?: any
}) => {
  return (
    <div className="flex w-full items-center rounded-md border bg-white text-black">
      {prefix && (
        <span className="px-2 font-light text-gray-500">{prefix}</span>
      )}
      <input
        // pattern='yyyy-MM-dd'
        defaultValue={defaultValue}
        disabled={disabled}
        name={name}
        type={type}
        value={value}
        max={max}
        maxLength={maxLength}
        pattern={pattern}
        inputMode={inputMode}
        autoComplete={autoComplete}
        min={min}
        onChange={onChange}
        onBlur={onBlur}
        className={classNames(className, 'w-full rounded-md border-none p-1')}
        placeholder={placeholder}
      ></input>
      {suffix && (
        <span className="px-2 font-light text-gray-500">{suffix}</span>
      )}
    </div>
  )
}

export default Input
