import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import DocumentViewer from '../../doctors/shared/DocumentViewer'
import { togglemedStorePrescription } from '../../../store/slices/modalSlice'
import { useAppDispatch, useAppSelector } from '../../../hooks/home'
import 'swiper/css'
import 'swiper/css/navigation'
import {
  prescriptionCompleted,
  getNewPrescriptionData,
  getDashboardData,
} from '../../../store/slices/MedstoreSlices/medHomeSlice'
import { notifier } from '../../../store/slices/notificationSlice'

function PrescriptionModal() {
  const dispatch = useAppDispatch()

  const url = useAppSelector(
    (state) => state.modal.medStorePrescription.data[0]
  )

  const id = useAppSelector((state) => state.modal.medStorePrescription.data[1])
  const toggle = () => {
    dispatch(togglemedStorePrescription(null))
  }

  const completedPres = () => {
    dispatch(prescriptionCompleted(id)).then(() => {
      dispatch(getNewPrescriptionData())
      dispatch(getDashboardData())
      return notifier.success('Prescription shared successfully')
    })
    dispatch(togglemedStorePrescription(null))
  }

  return (
    <Dialog.Panel className="relative w-screen max-w-xl transform overflow-hidden rounded-lg bg-white px-4 pt-2 pb-4 text-left shadow-xl transition-all">
      <div className="absolute top-0 right-0 hidden pt-4 pr-2 sm:block">
        <button type="button" onClick={toggle}>
          <span className="sr-only">Close</span>
          <XIcon
            className="h-6 w-6 rounded-full bg-greenColor text-white"
            aria-hidden="true"
          />
        </button>
      </div>

      <div className="w-11/12 text-center sm:mt-0 sm:ml-4 sm:text-left">
        {!!url && (
          <DocumentViewer
            documentURL={url + `?session=${localStorage.getItem('token')}`}
            fileName="download.pdf"
          ></DocumentViewer>
        )}
      </div>

      <hr />
      <div className="mt-5 flex justify-center">
        <button
          type="button"
          className="bg-blue-500text-base mx-auto inline-flex justify-center rounded-md border border-transparent bg-[#03B4EA] px-4 py-2 font-medium text-white shadow-sm focus:outline-none sm:text-sm"
          onClick={completedPres}
        >
          Completed
        </button>
      </div>
    </Dialog.Panel>
  )
}

export default PrescriptionModal
