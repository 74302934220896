import React, { Fragment, useEffect, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { SearchIcon } from '@heroicons/react/solid'
import { MenuAlt2Icon, PlusSmIcon } from '@heroicons/react/outline'
import { } from './config'
import ToggleButton from '../buttons/ToggleButton'
import classNames from 'classnames'
import SvgIcon from './shared/SvgIcon'
import Avatar from './shared/Avatar'
import { useAppSelector, useAppDispatch } from '../../hooks/home'
import { setData } from '../../store/slices/docProfileSlice'

const user = {
  name: 'Whitney Francis',
  email: 'whitney.francis@example.com',
  imageUrl:
    'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}

const userNavigation = [
  { name: 'View Profile', href: '/platform/profile' },
  { name: 'Sign out', href: '/api/auth/signout' },
]

const Header = (props: {
  mobileMenuOpen: any
  setMobileMenuOpen: any
  search: string
  setSearch: Function
}): JSX.Element => {
  const [available, setAvailable] = useState(false)
  const user: any = useAppSelector((state) => state.auth.user)
  const doctor = useAppSelector((state) => state.docProfile.doctor)

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setData(user))
  }, [user])



  return (
    <header className="w-full">
      <div className=" relative z-10 flex h-16 flex-shrink-0  bg-midnight shadow-sm md:px-8">
        <button
          type="button"
          className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
          onClick={() => props.setMobileMenuOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <SvgIcon
          className="h-full"
          src="/assets/eonmedDoctor.svg"
          alt="EONMED"
        />

        {/* <div className="flex border-white  justify-between px-4 sm:px-6"> */}
        {/* <div className="m-auto flex-1 flex"> */}
        <div className="flex flex-1 items-center   sm:px-6  ">
          <div className="relative mx-auto my-auto hidden h-10 w-96  rounded-md border border-white text-white sm:px-6 md:block ">
            <input
              onChange={(e) => props.setSearch(e.target.value)}
              value={props.search}
              name="search-field"
              id="search-field"
              className="h-full w-full bg-transparent py-2 pr-8 pl-1 text-base placeholder-white focus:border-transparent   focus:ring-0"
              placeholder="Search Patient By Name"
              type="search"
            />
            <div className="pointer-events-none absolute inset-y-0 right-2 flex items-center">
              <SearchIcon
                className="h-5 w-5 flex-shrink-0"
                aria-hidden="true"
              />
            </div>
          </div>
        </div>

        {/* </div> */}
        <div className="ml-auto flex items-center space-x-4 sm:space-x-6 sm:pr-6 md:pr-0">
          {/* Profile dropdown */}
          <Menu as="div" className="relative flex-shrink-0">
            <div>
              <Menu.Button className="flex rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <span className="sr-only">Open user menu</span>
                <Avatar className='border border-white'>
                  {
                    doctor?.profileImage
                      ? <img className='h-full w-full object-cover bg-white' src={doctor?.profileImage
                      } alt='profilepics' />
                      : <img
                        className="h-full w-full object-cover"
                        src="https://media.istockphoto.com/photos/hologram-human-head-deep-learning-and-artificial-intelligence-picture-id1364105164?b=1&k=20&m=1364105164&s=170667a&w=0&h=0Ehy6gPN70RTirKZ3TP3I0zoQz3bEFpzfSWfi0MhYd8="
                        alt="avatar"
                      />
                  }
                </Avatar>
                {/* <img
                  className="h-8 w-8 rounded-full"
                  // src="https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80"
                  alt=""
                /> */}
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-midnight py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {userNavigation.map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <a
                        href={item.href}
                        className={classNames(
                          active ? 'bg-gray-100 text-midnight' : 'text-white',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        {item.name}
                      </a>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>

          {/* <button
            type="button"
            className="flex items-center justify-center rounded-full bg-indigo-600 p-1 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <PlusSmIcon className="h-6 w-6" aria-hidden="true" />
            <span className="sr-only">Add file</span>
          </button> */}
        </div>
        {/* </div> */}
      </div>
    </header>
  )
}

export default Header
