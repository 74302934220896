import React from 'react'
import SubmissionCard from '../../shared/SubmissionCard'
import SvgIcon from '../../shared/SvgIcon'
import { format } from 'date-fns'

const PatientSubmissionDetails = (
  {
    data,
    goBack
  }: {
    data: any,
    goBack: any
  }
) => {
  return (
    <div className="h-full w-full">
      <div className="mb-4 flex space-x-4">
        <span onClick={goBack} className="cursor-pointer">
          <SvgIcon src="/assets/leftArrow.svg" className="h-6 w-10" />
        </span>
        <span>{format(new Date(data.date), 'MMM dd, yyy hh:mm a')}</span>
      </div>
      <div className="w-2/5">
        <SubmissionCard data={data} />
      </div>
    </div>
  )
}

export default PatientSubmissionDetails
