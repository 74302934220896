import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import { useAppDispatch, useAppSelector } from '../../../hooks/home'
import { toggleEducationSlider } from '../../../store/slices/sliderSlice'
import { Button } from '../../buttons/button'
import { useFormik } from 'formik'
import FormErrorMessage from '../shared/FormErrorMessage'
import { educationInitialState, educationvalidationSchema } from '../../../functionality/education'
import Input from '../../../Comp/Input'
import { changeDoctorProfile } from '../../../store/slices/docProfileSlice'
import SvgIcon from '../shared/SvgIcon'
import { UploadIcon } from '@heroicons/react/outline'

const EducationSlider = () => {
    const [files, setFiles]: [s: any, s: any] = useState([]);
    const uploadRef: any = useRef();
    const dispatch = useAppDispatch()
    const show = useAppSelector((state) => state.slider.educationSlider.show)
    const editState: any = useAppSelector((state) => state.slider.educationSlider.data)

    const toggle = () => {
        dispatch(toggleEducationSlider(null))
    }

    const onSubmit = useCallback((values) => {
        (async () => {
            let formdata = new FormData();
            Object.entries(values).map(([key, value]: [x: any, y: any]) => {
                formdata.append(key, value);
            })
            files.map((f: any) => {
                formdata.append('certificates', f);
            })
            try {

                if (editState) {
                    dispatch(changeDoctorProfile('education', 'update', formdata))
                } else {
                    dispatch(changeDoctorProfile('education', 'add', formdata))
                }
                toggle()
            } catch (err) {
                console.error(err)
            }
        })();
    }, [editState, files])

    const formik = useFormik({
        initialValues: educationInitialState,
        validationSchema: educationvalidationSchema,
        onSubmit: onSubmit,
    })

    //Set Initial Data
    useEffect(() => {
        if (!show) return formik.resetForm()
        if (!editState) return formik.resetForm()
        formik.setValues({
            ...formik.values,
            ...editState,
            certificates: undefined,
        })
    }, [])

    return (
        <Dialog.Panel className="pointer-events-auto h-full w-screen max-w-lg">
            <div className="flex h-full flex-col justify-center overflow-y-scroll bg-gray-100 py-6 shadow-xl">
                {/* <SliderHeader onClose={() => {}} title={undefined} /> */}
                <div className="mt-6 flex flex-col px-4 sm:px-6">
                    <div className="flex items-center justify-between">
                        <span className="text-2xl font-semibold">
                            Education
                        </span>
                        <span className="cursor-pointer rounded-full bg-green-400 p-1 text-white">
                            <XIcon onClick={toggle} className="h-3 w-3" />
                        </span>
                    </div>
                    <div className='flex flex-col space-y-3'>
                        <div className='flex flex-col space-y-2'>
                            <span className="font-semibold">University or Collage Name</span>
                            <Input
                                name="college_name"
                                value={formik.values.college_name}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                            />
                            <FormErrorMessage
                                touched={formik.touched.college_name}
                                error={formik.errors.college_name}
                            />
                        </div>
                        <div className='flex flex-col space-y-2'>
                            <span className="font-semibold">Degree </span>
                            <Input
                                name="degree"
                                value={formik.values.degree}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                            />
                            <FormErrorMessage
                                touched={formik.touched.degree}
                                error={formik.errors.degree}
                            />
                        </div>
                        <div className='flex space-x-2'>
                            <div className='flex flex-col w-1/2'>
                                <span className="font-semibold">Start Date </span>
                                <Input
                                    type='date'
                                    name="start_date"
                                    value={formik.values.start_date}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                />
                                <FormErrorMessage
                                    touched={formik.touched.start_date}
                                    error={formik.errors.start_date}
                                />

                            </div>
                            <div className='flex flex-col w-1/2'>
                                <span className="font-semibold">End Date </span>
                                <Input
                                    type='date'
                                    name="end_date"
                                    value={formik.values.end_date}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                />
                                <FormErrorMessage
                                    touched={formik.touched.end_date}
                                    error={formik.errors.end_date}
                                />
                            </div>
                        </div>
                        <div>
                            <input onChange={(e: any) => setFiles(Array.from(e.target.files))} multiple ref={uploadRef} hidden name='files' type='file' onBlur={formik.handleBlur} />
                            <span onClick={() => uploadRef.current.click()} className='flex text-orange-400 font-semibold cursor-pointer'>
                                <UploadIcon className="h-6 w-6" aria-hidden="true" /> Upload Certificates
                            </span>
                        </div>
                        <div className="flex flex-col">
                            {files.map((f: any, i: number) => (
                                <li key={i} className="flex">
                                    {f.name}
                                    <span className='ml-auto cursor-pointer' onClick={() => setFiles((prev: any[]) => prev.filter((p, j) => j !== i))}>
                                        <SvgIcon src='/assets/xcross.svg' />
                                    </span>
                                </li>
                            ))}
                        </div>


                    </div>
                </div>

                <Button
                    disabled={formik.isSubmitting}
                    onClick={formik.handleSubmit}
                    className="mx-auto mt-6 w-40 text-lg"
                >
                    {formik.isSubmitting ? 'Saving...' : 'Save'}
                </Button>
            </div>
        </Dialog.Panel >
    )
}

export default EducationSlider;
