import React, { useEffect } from 'react'
import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import { useAppDispatch, useAppSelector } from '../../../hooks/home'
import { updatePatient } from '../../../store/slices/prescriptionSlice'
import { toggleProfileSlider } from '../../../store/slices/sliderSlice'
import { Button } from '../../buttons/button'
import { notifier } from '../../../store/slices/notificationSlice'
import Input from '../../../Comp/Input'
import { useFormik } from 'formik'
import { patientInitialState, patientvalidationSchema } from '../../../functionality/patientProfile'
import FormErrorMessage from '../shared/FormErrorMessage'

const PatientProfileSlider = () => {

  const dispatch = useAppDispatch()

  const patient = useAppSelector(
    (state) => state.prescription.selectedPrescription?.patientId
  )

  //console.log(patient, 'patient')

  const toggle = () => {
    dispatch(toggleProfileSlider(null))
  }

  const formik = useFormik({
    initialValues: patientInitialState,
    validationSchema: patientvalidationSchema,
    onSubmit: async (values) => {
      console.log(values, 'values')
      await dispatch(updatePatient(patient._id, values))
      toggle()
    },
  })

  useEffect(() => {
    ;(async () => {
      formik.setValues({ ...formik.values, ...patient })
    })()
  }, [])

  return (
    <Dialog.Panel className="pointer-events-auto h-full w-screen max-w-lg">
      <div className="flex h-full flex-col justify-center overflow-y-scroll bg-gray-100 py-6 shadow-xl">
        {/* <SliderHeader onClose={() => {}} title={undefined} /> */}
        <div className="mt-6 flex flex-col px-4 sm:px-6">
          <div className="flex items-center justify-between">
            <span className="text-2xl font-semibold">Patient Profile:</span>
            <span className="cursor-pointer rounded-full bg-green-400 p-1 text-white">
              <XIcon onClick={toggle} className="h-3 w-3" />
            </span>
          </div>
          <form className="flex h-full w-full flex-col justify-center space-y-3">
            <div className="mt-3 flex  flex-col space-y-3">
              <div className="flex items-end space-x-2">
                <div className="flex flex-1 flex-col space-y-1">
                  <span>Name</span>
                  <Input
                    className="flex-1 rounded-md border p-1 pl-2"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="firstName"
                    type="string"
                    placeholder="First Name"
                    // disabled={!!formik.values.firstName}
                  />
                  <FormErrorMessage
                    touched={formik.touched.firstName}
                    error={formik.errors.firstName}
                  />
                </div>
                <div className="flex flex-1 flex-col space-y-1">
                  <Input
                    className="flex-1 rounded-md border p-1 pl-2"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="lastName"
                    type="string"
                    placeholder="Last Name"
                    // disabled={!!formik.values.lastName}
                  />
                  <FormErrorMessage
                    touched={formik.touched.lastName}
                    error={formik.errors.lastName}
                  />
                </div>
              </div>
              <div className="flex items-end space-x-2">
                <div className="flex flex-1 flex-col space-y-1">
                  <span>Age</span>
                  <Input
                    className="flex-1 rounded-md border p-1 pl-2"
                    value={formik.values.age}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="age"
                    type="string"
                    placeholder="Age"
                    suffix={
                      <span
                        className="cursor-pointer"
                        onClick={() =>
                          formik.setFieldValue(
                            'ageSuffix',
                            formik.values.ageSuffix === 'years'
                              ? 'months'
                              : 'years'
                          )
                        }
                      >
                        {formik.values.ageSuffix}
                      </span>
                    }
                  />
                  <FormErrorMessage
                    touched={formik.touched.age}
                    error={formik.errors.age}
                  />
                </div>
                <div className="flex flex-1 flex-col space-y-1">
                  <span>Gender</span>
                  <Input
                    className="flex-1 rounded-md border p-1 pl-2"
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="gender"
                    type="string"
                    placeholder="Gender"
                  />
                  <FormErrorMessage
                    touched={formik.touched.gender}
                    error={formik.errors.gender}
                  />
                </div>
              </div>
              <div className="flex items-end space-x-2">
                <div className="flex flex-1 flex-col space-y-1">
                  <span>Height</span>
                  <Input
                    className="flex-1 rounded-md border p-1 pl-2"
                    value={formik.values.height}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="height"
                    type="string"
                    placeholder="Height"
                    suffix="cm"
                  />
                  <FormErrorMessage
                    touched={formik.touched.height}
                    error={formik.errors.height}
                  />
                </div>
                <div className="flex flex-1 flex-col space-y-1">
                  <span>Weight</span>
                  <Input
                    className="flex-1 rounded-md border p-1 pl-2"
                    value={formik.values.weight}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="weight"
                    type="string"
                    placeholder="Weight"
                    suffix="kg"
                  />
                  <FormErrorMessage
                    touched={formik.touched.weight}
                    error={formik.errors.weight}
                  />
                </div>
              </div>
              <div className="flex items-end space-x-2">
                <div className="flex flex-1 flex-col space-y-1">
                  <span>Contact No</span>
                  <Input
                    className="flex-1 rounded-md border p-1 pl-2"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="phone"
                    type="string"
                    placeholder="Contact Number"
                  />
                  <FormErrorMessage
                    touched={formik.touched.phone}
                    error={formik.errors.phone}
                  />
                </div>
                <div className="flex flex-1 flex-col space-y-1">
                  <span>Email</span>
                  <Input
                    className="flex-1 rounded-md border p-1 pl-2"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="email"
                    type="string"
                    placeholder="Email"
                  />
                  <FormErrorMessage
                    touched={formik.touched.email}
                    error={formik.errors.email}
                  />
                </div>
              </div>
              <div className="flex items-end space-x-2">
                <div className="flex flex-1 flex-col space-y-1">
                  <span>State</span>
                  <Input
                    className="flex-1 rounded-md border p-1 pl-2"
                    value={formik.values.state}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="state"
                    type="string"
                    placeholder="State"
                  />
                  <FormErrorMessage
                    touched={formik.touched.state}
                    error={formik.errors.state}
                  />
                </div>
                <div className="flex flex-1 flex-col space-y-1">
                  <span>City</span>
                  <Input
                    className="flex-1 rounded-md border p-1 pl-2"
                    value={formik.values.district}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="district"
                    type="string"
                    placeholder="City"
                  />
                  <FormErrorMessage
                    touched={formik.touched.district}
                    error={formik.errors.district}
                  />
                </div>
              </div>
            </div>
            <Button
              disabled={formik.isSubmitting}
              onClick={formik.handleSubmit}
              className="mx-auto mt-6 w-40 text-lg"
            >
              {formik.isSubmitting ? 'Updating...' : 'Update'}
            </Button>
          </form>
        </div>
      </div>
    </Dialog.Panel>
  )
}

export default PatientProfileSlider
