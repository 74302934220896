import * as Yup from 'yup'
import { format } from 'date-fns'
export const experienceInitialState = {
  title: '',
  company_name: '',
  start_date: format(new Date(), 'dd-MM-yyyy'),
  end_date: format(new Date(), 'dd-MM-yyyy'),
  location: '',
  description: '',
}

export const experiencevalidationSchema = Yup.object().shape({
  start_date: Yup.date()
    .typeError('start date should be a valid date')
    .required('start date is required'),
  end_date: Yup.date()
    .typeError('end date should be a valid date')
    .required('end date is required')
    .min(Yup.ref('start_date'), 'end date should be greater than start date'),
  title: Yup.string()
    .required('title is required')
    .max(80, 'title should not exceed 80 characters'),
  company_name: Yup.string()
    .required('company name is required')
    .max(80, 'company name should not exceed 80 characters'),
  location: Yup.string()
    .required('location is required')
    .max(80, 'location should not exceed 80 characters'),
  description: Yup.string().max(
    500,
    'description should not exceed 500 characters'
  ),
})
