import React, { useCallback, useEffect } from 'react'
import { Dialog } from '@headlessui/react'
import SliderHeader from '../SliderHeader'
import { XIcon } from '@heroicons/react/solid'
import { useAppDispatch, useAppSelector } from '../../../hooks/home'
import { toggleDiagnosticSlider } from '../../../store/slices/sliderSlice'
import { Button } from '../../buttons/button'
import { disease_list } from '../../../utils/constants'
import { useFormik } from 'formik'
import {
  diagnosticInitialState,
  diagnosticvalidationSchema,
} from '../../../functionality/addDiagnostic'
import { addDocData, updateDocData } from '../../../store/slices/prescriptionSlice'
import CreatableSelect from 'react-select/creatable'
import FormErrorMessage from '../shared/FormErrorMessage'
import LabelTag from '../shared/LabelTag'
import Input from '../../../Comp/Input'
import { getTestsList } from '../../../store/slices/utilSlice'

const DiagnosticSlider = () => {
  const dispatch = useAppDispatch()
  const testslist: any = useAppSelector((state) => state.util.tests.list)
  const show = useAppSelector(
    (state) => state.slider.diagnosticSlider.show
  )
  const editState = useAppSelector(
    (state) => state.slider.diagnosticSlider.data
  )

  const toggle = () => {
    dispatch(toggleDiagnosticSlider(null))
  }

  useEffect(() => {
    dispatch(getTestsList())
  }, [])

  const onSubmit = useCallback(
    (values) => {
      (async () => {
        try {
          console.log('values', values)
          if (editState) {
            await dispatch(updateDocData('labTests', { test: values }))
          } else {
            await dispatch(addDocData('labTests', { test: values }))
          }
          toggle()
        } catch (err) {
          console.error(err)
        }
      })();
    }, [editState])


  const formik = useFormik({
    initialValues: diagnosticInitialState,
    validationSchema: diagnosticvalidationSchema,
    onSubmit: onSubmit
  })

  //Set Initial Data
  useEffect(() => {
    if (!show) return formik.resetForm()
    if (!editState) return formik.resetForm()
    formik.setValues({
      ...formik.values,
      ...editState,
    })
  }, [])

  const handleDelete = (key: string) => {
    formik.setFieldValue(
      'otherDiseases',
      formik.values.otherDiseases.filter((item: string) => item !== key)
    )
  }

  const handleChange = (key: string) => {
    formik.values.diseases.includes(key)
      ? formik.setFieldValue(
        'diseases',
        formik.values.diseases.filter((s: string) => s !== key)
      )
      : formik.setFieldValue('diseases', [...formik.values.diseases, key])
  }

  const handleNewDisease = () => {
    if (!formik.values.new_disease) {
      return formik.setFieldError('new_disease', 'Please enter a disease')
    }
    if (
      [...formik.values.otherDiseases, ...formik.values.diseases].includes(
        formik.values.new_disease
      )
    ) {
      return formik.setFieldError('new_disease', 'Disease already exists')
    }
    formik.setFieldValue('otherDiseases', [
      ...formik.values.otherDiseases,
      formik.values.new_disease,
    ])
    formik.setFieldValue('new_disease', '')
  }

  return (
    <Dialog.Panel className="pointer-events-auto h-full w-screen max-w-2xl">
      <div className="flex h-full flex-col space-y-3 overflow-y-scroll bg-gray-100 py-6 pt-32 shadow-xl">
        {/* <SliderHeader onClose={() => {}} title={undefined} /> */}
        <div className="mt-6 flex flex-col px-4 sm:px-6">
          <div className="flex items-center justify-between">
            <span className="font-semibold">Diagnostic:</span>
            <span className="cursor-pointer rounded-full bg-green-400 p-1 text-white">
              <XIcon onClick={toggle} className="h-3 w-3" />
            </span>
          </div>
          <span className="font-semibold">Test Name</span>
          <div className="flex space-x-3">
            <div className="flex flex-1 flex-col items-stretch space-y-4">
              <CreatableSelect
                id="test_name"
                placeholder="Select Diagnostoc Test"
                value={
                  formik.values.test_name
                    ? { testName: formik.values.test_name }
                    : null
                }
                options={testslist}
                onChange={(e: any) =>
                  formik.setFieldValue('test_name', e.testName)
                }
                onBlur={() =>
                  formik.handleBlur({ target: { name: 'test_name' } })
                }
                getOptionLabel={(o: any) => o.testName}
                getOptionValue={(o: any) => o.testName}
                getNewOptionData={(o: string) => ({
                  testName: o,
                })}
              />
              <FormErrorMessage
                touched={formik.touched.test_name}
                error={formik.errors.test_name}
              />
              <div className="flex flex-col space-y-2">
                <span className="font-semibold">Remarks</span>
                <textarea
                  className="rounded-md border"
                  name="remark"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.remark}
                />
                <span className='text-right'>{formik.values.remark.length}/ 500</span>
                <FormErrorMessage
                  touched={formik.touched.remark}
                  error={formik.errors.remark}
                />
              </div>
              {/* <div className="flex flex-col space-y-2">
                <span className="font-semibold">Course Duration:</span>
                <div className="flex">
                  <div className="flex items-center space-x-2">
                    <Button
                      active={formik.values.course_duration_type === 'day'}
                      onClick={() =>
                        formik.setFieldValue('course_duration_type', 'day')
                      }
                      className="px-3"
                      gray
                      outline
                    >
                      Days
                    </Button>
                    <Button
                      active={formik.values.course_duration_type === 'week'}
                      onClick={() =>
                        formik.setFieldValue('course_duration_type', 'week')
                      }
                      className="px-3"
                      gray
                      outline
                    >
                      Weeks
                    </Button>
                    <Button
                      active={formik.values.course_duration_type === 'month'}
                      onClick={() =>
                        formik.setFieldValue('course_duration_type', 'month')
                      }
                      className="px-3"
                      gray
                      outline
                    >
                      Months
                    </Button>
                  </div>
                  <Button
                    active={formik.values.course_duration_type === 'manual'}
                    onClick={() =>
                      formik.setFieldValue('course_duration_type', 'manual')
                    }
                    className="ml-auto px-3"
                    gray
                    outline
                  >
                    Manual
                  </Button>
                </div>
                <div className="flex space-x-2">
                  {Array.from({ length: 10 }).map((_, i) => (
                    <Button
                      rounded
                      active={formik.values.course_duration_count === i + 1}
                      onClick={() =>
                        formik.setFieldValue('course_duration_count', i + 1)
                      }
                      className="px-3"
                      gray
                      outline
                    >
                      {i + 1}
                    </Button>
                  ))}
                </div>
                <FormErrorMessage
                  touched={formik.touched.course_duration_type}
                  error={formik.errors.course_duration_type}
                />
              </div> */}
            </div>
            {/* <div className="flex flex-1 flex-col items-stretch space-y-4">
              <div className="grid grid-cols-4 gap-2">
                {Object.entries(disease_list).map(
                  ([key, label]: [key: string, label: any]) => (
                    <Button
                      key={key}
                      onClick={() => handleChange(key)}
                      active={formik.values.diseases.includes(key)}
                      outline
                      className="whitespace-nowrap"
                    >
                      {label}
                    </Button>
                  )
                )}
              </div>

              <div className="flex space-x-2 ">
                {formik.values.otherDiseases.map((disease: string) => (
                  <LabelTag onClick={() => handleDelete(disease)} key={disease}>
                    {disease}
                  </LabelTag>
                ))}
              </div>

              <div className="flex items-center">
                <Input
                  name="new_disease"
                  value={formik.values.new_disease}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  className="mr-3"
                />
                <Button onClick={handleNewDisease} className="px-2">
                  ADD
                </Button>
              </div>

              <FormErrorMessage
                touched={formik.touched.new_disease}
                error={formik.errors.new_disease}
              />
            </div> */}
          </div>

          <Button
            disabled={formik.isSubmitting}
            onClick={formik.handleSubmit}
            className="mx-auto mt-6 w-40 text-lg"
          >
            {editState && (formik.isSubmitting ? 'Updating...' : 'Update')}
            {!editState && (formik.isSubmitting ? 'Saving...' : 'Save')}
          </Button>
        </div>
      </div>
    </Dialog.Panel>
  )
}

export default DiagnosticSlider
