import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import DocumentViewer from '../../doctors/shared/DocumentViewer'
import { togglemedStoreOrderReq } from '../../../store/slices/modalSlice'
import { useAppDispatch, useAppSelector } from '../../../hooks/home'
import {
  orderAccept,
  orderReject,
  getProgressOrderData,
} from '../../../store/slices/MedstoreSlices/medHomeSlice'
import { notifier } from '../../../store/slices/notificationSlice'

function OrderRequestModal() {
  const dispatch = useAppDispatch()
  const url = useAppSelector((state) => state.modal.medStoreOrderReq.data[0])
  const id = useAppSelector((state) => state.modal.medStoreOrderReq.data[1])

  const toggle = () => {
    dispatch(togglemedStoreOrderReq(null))
  }

  const acceptRequest = () => {
    dispatch(orderAccept(id)).then(() => {
      dispatch(getProgressOrderData())
      return notifier.success('Order accepted successfully')
    })
    dispatch(togglemedStoreOrderReq(null))
  }

  const rejectRequest = () => {
    dispatch(orderReject(id)).then(() => {
      dispatch(getProgressOrderData())
      return notifier.success('Order rejected successfully')
    })
    dispatch(togglemedStoreOrderReq(null))
  }

  return (
    <Dialog.Panel className="relative w-screen max-w-xl transform overflow-hidden rounded-lg bg-white px-4 pt-2 pb-4 text-left shadow-xl transition-all">
      <div className="absolute top-0 right-0 hidden pt-4 pr-2 sm:block">
        <button type="button" onClick={toggle}>
          <span className="sr-only">Close</span>
          <XIcon
            className="h-6 w-6 rounded-full bg-greenColor text-white"
            aria-hidden="true"
          />
        </button>
      </div>

      <div className="w-11/12 text-center sm:mt-0 sm:ml-4 sm:text-left">
        {!!url && (
          <DocumentViewer
            documentURL={url + `?session=${localStorage.getItem('token')}`}
            fileName="download.pdf"
          ></DocumentViewer>
        )}
      </div>

      <hr />

      <div className="mx-4 mt-5 flex justify-between space-x-7">
        <button
          type="button"
          className="bg-blue-500text-base mx-auto inline-flex w-full justify-center rounded-md border border-[#03B4EA] bg-white px-4 py-2 font-medium text-[#03B4EA] shadow-sm focus:outline-none sm:text-sm"
          onClick={rejectRequest}
        >
          Reject
        </button>
        <button
          type="button"
          className="bg-blue-500text-base mx-auto inline-flex w-full justify-center rounded-md border border-transparent bg-[#03B4EA] px-4 py-2 font-medium text-white shadow-sm focus:outline-none sm:text-sm"
          onClick={acceptRequest}
        >
          Accept
        </button>
      </div>
    </Dialog.Panel>
  )
}

export default OrderRequestModal
