import React from 'react'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark, faCircleCheck } from "@fortawesome/free-solid-svg-icons";

const Checkbox = ({
  active = false,
  className
}: {
  active?: boolean
  className?: string
}) => {
  return (
    <div className={classNames(
      className,
      ''
    )}
    >
      {!active ? <FontAwesomeIcon className='text-[#F53939]' width={40} icon={faCircleXmark} /> :
        <FontAwesomeIcon className='text-greenColor' width={40} icon={faCircleCheck} />}
    </div>
  )
}

export default Checkbox