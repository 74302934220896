import Nav from "./Nav";
import WebsiteFooter from "./WebsiteFooter";
import ComingSoonModal from "./ComingSoonModal";
type Props = {
  children: React.ReactNode;
};

export default function Layout({ children }: Props) {

  return (
    <>
      <nav>
        <Nav />
      </nav>

      <main>{children}</main>
<ComingSoonModal />
      <WebsiteFooter />
    </>
  );
}
