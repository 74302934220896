import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from '../../../utils/axios'

// declaring the types for our state
export type ClientPackageState = {
    loading: boolean
    error: any | null
    data: {
        instock: {
            data: any[]
            total: 0
            current: 0
        }
        incoming: {
            data: any[]
            total: 0
            current: 0
        }
    }

}

const initialState: ClientPackageState = {
    error: null,
    loading: true,
    data: {
        instock: {
            data: [],
            total: 0,
            current: 0
        },
        incoming: {
            data: [],
            total: 0,
            current: 0
        },
    }
}

export const clientPackageSlice = createSlice({
    name: 'clientpackage',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions.
    // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
    // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setInstockData: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data.instock.data = action.payload
        },
        setInstockTotal: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data.instock.total = action.payload
        },
        setInstockCurrent: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data.instock.current = action.payload
        },
        setIncomingData: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data.incoming.data = action.payload
        },
        setIncomingTotal: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data.incoming.total = action.payload
        },
        setIncomingCurrent: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data.incoming.current = action.payload
        },
        setError: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.error = action.payload
        }
    },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { setLoading, setInstockData, setIncomingCurrent, setIncomingTotal, setInstockCurrent, setInstockTotal, setIncomingData, setError } =
    clientPackageSlice.actions

//API Calls


export const getPackageList =
    (val: any) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                const response = await axios.get(`client-packages/paginate?page=${val}&limit=10`)
                const data = response.data.response.data
                console.log(data.inStock)
                dispatch(setInstockData(data.inStock.docs))
                dispatch(setInstockCurrent(data.inStock.page))
                dispatch(setInstockTotal(data.inStock.totalPages))
                dispatch(setIncomingData(data.incoming.docs))
                dispatch(setIncomingCurrent(data.incoming.page))
                dispatch(setIncomingTotal(data.incoming.totalPages))
            } catch (error) {
                dispatch(setError(error))
            }
        }


export const updatePackageData =
    (values: any) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                let res = await axios.patch(`/packages`, values)
                const response = await axios.get(`client-packages`)
                const data = response.data.response.data
                dispatch(setIncomingData(data?.incoming))
            } catch (error) {
                dispatch(setError(error))
            }
        }


// exporting the reducer here, as we need to add this to the store
export default clientPackageSlice.reducer
