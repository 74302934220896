import React, { Fragment, useEffect } from 'react'
import { Dialog, Popover } from '@headlessui/react'
import SliderHeader from '../SliderHeader'
import { XIcon } from '@heroicons/react/solid'
import { useAppDispatch, useAppSelector } from '../../../hooks/home'
import { toggleSymptomsSlider } from '../../../store/slices/sliderSlice'
import { Button } from '../../buttons/button'
import { symptom_list } from '../../../utils/constants'
import { useFormik } from 'formik'
import {
  symptomsInitialState,
  symptomsvalidationSchema,
} from '../../../functionality/symptoms'
import { addDocData } from '../../../store/slices/prescriptionSlice'
import Input from '../../../Comp/Input'
import FormErrorMessage from '../shared/FormErrorMessage'
import LabelTag from '../shared/LabelTag'

const SymptomsSlider = () => {
  const dispatch = useAppDispatch()
  const prescription = useAppSelector(
    (state) => state.prescription.selectedPrescription
  )

  const toggle = () => {
    dispatch(toggleSymptomsSlider(null))
  }

  const formik = useFormik({
    initialValues: symptomsInitialState,
    validationSchema: symptomsvalidationSchema,
    onSubmit: async (values) => {
      console.log('values', values)
      await dispatch(
        addDocData('symptoms', {
          hopi_symptoms: [...values.symptoms, ...values.otherSymptoms],
        })
      )
      toggle()
    },
  })

  useEffect(() => {
    if (!prescription) return
    formik.setFieldValue(
      'symptoms',
      prescription.hopi_symptoms.filter((s: any) => !s.external)
    )
    formik.setFieldValue(
      'otherSymptoms',
      prescription.hopi_symptoms.filter((s: any) => !!s.external)
    )
  }, [prescription])

  const handleDelete = (key: string) => {
    formik.setFieldValue(
      'otherSymptoms',
      formik.values.otherSymptoms.filter((item: any) => item.name !== key)
    )
  }

  const handleChange = (obj: any) => {
    formik.values.symptoms.some((s: any) => s.key === obj.key)
      ? formik.setFieldValue(
          'symptoms',
          formik.values.symptoms.filter((s: any) => s.key !== obj.key)
        )
      : formik.setFieldValue('symptoms', [...formik.values.symptoms, obj])
  }

  const handlePopOverSave = (name: string, key: string, close: any) => {
    formik.setFieldTouched('duration', true)
    formik.setFieldTouched('frequency', true)
    if (formik.errors.duration || formik.errors.frequency) {
      formik.validateField('duration')
      formik.validateField('frequency')
      return
    }
    formik.setFieldValue('symptoms', [
      ...formik.values.symptoms,
      {
        name,
        key,
        duration: formik.values.duration,
        frequency: formik.values.frequency,
      },
    ])
    formik.setFieldValue('duration', '')
    formik.setFieldValue('frequency', '')
    formik.setFieldTouched('duration', false)
    formik.setFieldTouched('frequency', false)
    setTimeout(close, 500)
  }

  const handleNewSymptom = () => {
    if (formik.errors.new_symptom) return
    if (!formik.values.new_symptom) {
      return formik.setFieldError('new_symptom', 'Please enter a symptom')
    }
    if (
      [...formik.values.otherSymptoms, ...formik.values.symptoms].some(
        (symp) => symp.name === formik.values.new_symptom
      )
    ) {
      return formik.setFieldError('new_symptom', 'Symptom already exists')
    }

    formik.setFieldValue('otherSymptoms', [
      ...formik.values.otherSymptoms,
      { name: formik.values.new_symptom, external: true },
    ])
    formik.setFieldValue('new_symptom', '')
  }

  if (!prescription) return null

  return (
    <Dialog.Panel className="pointer-events-auto h-full w-screen max-w-xl">
      <div className="flex h-full flex-col justify-center overflow-y-scroll bg-gray-100 py-6 shadow-xl">
        {/* <SliderHeader onClose={() => {}} title={undefined} /> */}
        <form onSubmit={formik.handleSubmit}>
          <div className="mt-6 flex flex-col space-y-3 px-4 sm:px-6">
            <div className="flex items-center justify-between">
              <span className="text-2xl font-semibold">Symptoms:</span>
              <span className="cursor-pointer rounded-full bg-green-400 p-1 text-white">
                <XIcon onClick={toggle} className="h-3 w-3" />
              </span>
            </div>

            <div className="grid grid-cols-3 gap-2">
              {Object.entries(symptom_list).map(
                ([key, name]: [x: string, y: any], i: number) => (
                  <Popover key={i} className="relative">
                    {({ open, close }) => {
                      let symp = formik.values.symptoms.find(
                        (s: any) => s.key === key
                      )
                      return (
                        <Fragment>
                          <Popover.Button as={Fragment}>
                            <Button
                              onClick={(e) => {
                                if (
                                  formik.values.symptoms.some(
                                    (s: any) => s.key === key
                                  )
                                ) {
                                  e.preventDefault() // Prevent Popup on Symptom Removal
                                  handleChange({ name, key })
                                }
                              }}
                              active={formik.values.symptoms.some(
                                (s: any) => s.key === key
                              )}
                              outline
                              className="relative min-w-full flex-1 overflow-hidden whitespace-nowrap"
                            >
                              {!symp
                                ? name
                                : symp.name +
                                  (symp.duration ? `| ${symp.duration}` : '') +
                                  (symp.frequency ? `| ${symp.frequency}` : '')}
                            </Button>
                          </Popover.Button>
                          {open &&
                            formik.values.symptoms.every(
                              (s: any) => s.key !== key
                            ) && (
                              <Popover.Panel as={Fragment} static>
                                <div className="absolute top-0 z-10 flex w-48 flex-col space-y-2 rounded-md border border-white bg-blueColor p-3">
                                  <span>
                                    <XIcon
                                      className="absolute top-0 right-0 h-6 w-6 cursor-pointer p-1"
                                      onClick={() => close()}
                                    />
                                  </span>
                                  <Input
                                    name="duration"
                                    value={formik.values.duration}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    placeholder="Duration"
                                    className="flex-1"
                                  />
                                  <FormErrorMessage
                                    touched={formik.touched.duration}
                                    error={formik.errors.duration}
                                  />
                                  <Input
                                    name="frequency"
                                    value={formik.values.frequency}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    placeholder="Frequency"
                                    className="flex-1"
                                  />
                                  <FormErrorMessage
                                    touched={formik.touched.frequency}
                                    error={formik.errors.frequency}
                                  />
                                  <Button
                                    className="rounded-md bg-white py-2 px-4 font-semibold"
                                    onClick={() => {
                                      handlePopOverSave(name, key, close)
                                    }}
                                  >
                                    Save
                                  </Button>
                                </div>
                              </Popover.Panel>
                            )}
                        </Fragment>
                      )
                    }}
                  </Popover>
                )
              )}
            </div>

            <div className="flex space-x-2 ">
              {formik.values.otherSymptoms.map((symptom: any) => (
                <LabelTag
                  onClick={() => handleDelete(symptom.name)}
                  key={symptom.name}
                >
                  {symptom.name}
                </LabelTag>
              ))}
            </div>

            <div className="flex items-center space-x-2">
              <Input
                name="new_symptom"
                value={formik.values.new_symptom}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              <Button onClick={handleNewSymptom} className="px-2">
                ADD
              </Button>
            </div>

            <FormErrorMessage
              touched={formik.touched.new_symptom}
              error={formik.errors.new_symptom}
            />
            <Button
              disabled={formik.isSubmitting}
              onClick={formik.handleSubmit}
              className="mx-auto mt-6 w-40 text-lg"
            >
              {formik.isSubmitting ? 'Saving...' : 'Save'}
            </Button>
          </div>
        </form>
      </div>
    </Dialog.Panel>
  )
}

export default SymptomsSlider
