import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from '../../../utils/axios'

// declaring the types for our state
export type ClientOrderState = {
    loading: boolean
    error: any | null
    data: {
        created: any[]
        createdTotal: any
        createdCurrent: any
        processing: any[]
        processingTotal: any
        processingCurrent: any
        completed: any[]
        completedTotal: any
        completedCurrent: any
    }


}

const initialState: ClientOrderState = {
    error: null,
    loading: true,
    data: {
        created: [],
        createdTotal: 0,
        createdCurrent: 0,
        processing: [],
        processingTotal: 0,
        processingCurrent: 0,
        completed: [],
        completedTotal: 0,
        completedCurrent: 0
    },

}

export const clientOrderSlice = createSlice({
    name: 'clientorder',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions.
    // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
    // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setCreatedData: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data.created = action.payload
        },
        setCreatedTotal: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data.createdTotal = action.payload
        },
        setCreatedCurrentPage: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data.createdCurrent = action.payload
        },
        setProcessingData: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data.processing = action.payload
        },
        setProcessingTotal: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data.processingTotal = action.payload
        },
        setProcessingCurrentPage: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data.processingCurrent = action.payload
        },
        setCompletedData: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data.completed = action.payload
        },
        setCompletedTotal: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data.completedTotal = action.payload
        },
        setCompletedCurrentPage: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data.completedCurrent = action.payload
        },
        setError: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.error = action.payload
        },

    },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { setLoading, setCreatedData, setCreatedTotal, setCreatedCurrentPage,
    setProcessingData, setProcessingCurrentPage, setProcessingTotal, setCompletedCurrentPage, setCompletedTotal,
    setCompletedData, setError } =
    clientOrderSlice.actions

//API Calls

export const getOrderList =
    (page: any) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                const response = await axios.get(`orders/paginate?page=${page}&limit=10`)
                const data = response.data.response.data
                console.log(data, '111')
                dispatch(setCreatedData(data.created.docs))
                dispatch(setCreatedTotal(data.created.totalPages))
                dispatch(setCreatedCurrentPage(data.created.page))
                dispatch(setProcessingData(data.inProcess.docs))
                dispatch(setProcessingTotal(data.inProcess.totalPages))
                dispatch(setProcessingCurrentPage(data.inProcess.page))
                dispatch(setCompletedData(data.completed.docs))
                dispatch(setCompletedTotal(data.completed.totalPages))
                dispatch(setCompletedCurrentPage(data.completed.page))
            } catch (error) {
                dispatch(setError(error))
            }
        }

export const setCreateOrder =
    (values: any) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                const res = await axios.post(`orders`, values)
                const response = await axios.get(`orders`)
                const data = response.data.response.data
                dispatch(setCreatedData(data.created))
            } catch (error) {
                dispatch(setError(error))
            }
        }


export const updateOrder =
    (id: any, values: any) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                const res = await axios.patch(`orders/${id}`, values)
            } catch (error) {
                dispatch(setError(error))
            }
        }
//-----------------------------------------Api call For Search Order Detail---------------------------------------------------- 
export const getOrderSearchDetail =
    (type: any, Query: any) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                let response = await axios.get(`orders/paginate?orderNumber=${Query}`)
                const data = response.data.response.data
                console.log("data", data)
                if (type == "created") {
                    dispatch(setCreatedData(data.created?.docs))
                }
                if (type == "processing") {
                    dispatch(setProcessingData(data.inProcess?.docs))
                }
                if (type == "completed") {
                    dispatch(setCompletedData(data.completed?.docs))
                }


            } catch (error) {
                dispatch(setError(error))
            }
        }


// exporting the reducer here, as we need to add this to the store
export default clientOrderSlice.reducer
