import axios from 'axios'
import { notifier } from '../store/slices/notificationSlice'

const instance = axios.create({
  baseURL: process.env.API_URL,
})
instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token')
  if (token && (!config.headers || !config.headers.Authorization)) {
    config.headers.Authorization = 'bearer ' + token
  }

  return config
})

instance.interceptors.response.use(function (response) {
  console.log('response', response)
  return response
}, (error) => {

  switch (error.response.data.code) {
    //Custom Code as per Status Code 
    case 401:
    default: notifier.error(error.response.data.error[0].msg); break;
  }
  // Will trigger Error Boundary if there is no catch  in the further flow
  // throw Error(error.response.data.error[0].msg) 


  return Promise.reject(error) //Continue the flow
})

export const callApi = (apiPromise) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await apiPromise())
    } catch (err) {
      const options = {
        message: 'Network request fail',
        description: err.message,
      }
      if (err.response) options.description = err.response.data.error[0].msg
      notification.error(options)
      reject(err)
    }
  })
}

export default instance
