/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useCallback, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/outline'
import { useAppDispatch, useAppSelector } from '../../../hooks/home'
import { closeAllModals } from '../../../store/slices/modalSlice'
import ConsultationModal from './ConsultationModal'
import HealthIndicatorModal from './HealthIndicatorModal'
import PrescriptionShared from './PrescriptionShared'
import FollowupModal from './FollowupModal'
import ScheduledConsultationModal from './ScheduledConsultation'
import SignatureModal from './SignatureModal'
import SubmissionModal from './SubmissionModal'
import PDFModal from './PDFModal'
import DocReferModal from './DocReferModal'
import PreviousConsultModal from './PreviousConsultModal'

export default function Modals() {
  const dispatch = useAppDispatch()
  const modalState = useAppSelector((state) => state.modal)

  const getShow = useCallback(() => {
    return Object.entries(modalState).some(([key, { show }]) => show)
  }, [modalState])

  const toggle = () => {
    dispatch(closeAllModals())
  }

  return (
    <Transition.Root show={getShow()} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={toggle}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={'div'}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {modalState.docReferModal.show && <DocReferModal />}
              {modalState.pdfModal.show && <PDFModal />}
              {modalState.consultationModal.show && <ConsultationModal />}
              {modalState.healthIndicatorModal.show && <HealthIndicatorModal />}
              {modalState.prescriptionSharedModal.show && (
                <PrescriptionShared />
              )}
              {modalState.followupModal.show && <FollowupModal />}
              {modalState.scheduledConsultationModal.show && (
                <ScheduledConsultationModal />
              )}
              {modalState.signatureModal.show && <SignatureModal />}
              {modalState.submissionModal.show && <SubmissionModal />}
              {modalState.patientHistory.show && <PreviousConsultModal />}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
