import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from '../../utils/axios'

// declaring the types for our state
export type ModalState = {
  prescriptionModal: {
    show: boolean
    data: any
  }
  prescriptionSharedModal: {
    show: boolean
    data: any
  }
  consultationModal: {
    show: boolean
    data: any
  }
  submissionModal: {
    show: boolean
    data: any
  }
  healthIndicatorModal: {
    show: boolean
    data: any
  }
  signatureModal: {
    show: boolean
    data: any
  }
  followupModal: {
    show: boolean
    data: any
  }
  scheduledConsultationModal: {
    show: boolean
    data: any
  }
  pdfModal: {
    show: boolean
    data: any
  }
  docReferModal: {
    show: boolean
    data: any
  }
  medStorePrescription: {
    show: boolean
    data: any
  }
  medStoreOrderReq: {
    show: boolean
    data: any
  }
  customerViewPres: {
    show: boolean
    data: any
  }
  patientHistory: {
    show: boolean
    data: any
  }
  comingSoon: {
    show: boolean
  }
  diagnosticUpload: {
    show: boolean
    data: any
  }
}

const initialState: ModalState = {
  consultationModal: {
    show: false,
    data: null,
  },
  prescriptionModal: {
    show: false,
    data: null,
  },
  prescriptionSharedModal: {
    show: false,
    data: null,
  },
  submissionModal: {
    show: false,
    data: null,
  },
  healthIndicatorModal: {
    show: false,
    data: null,
  },
  signatureModal: {
    show: false,
    data: null,
  },
  followupModal: {
    show: false,
    data: null,
  },
  scheduledConsultationModal: {
    show: false,
    data: null,
  },
  pdfModal: {
    show: false,
    data: null,
  },
  docReferModal: {
    show: false,
    data: null,
  },
  medStorePrescription: {
    show: false,
    data: null,
  },
  medStoreOrderReq: {
    show: false,
    data: null,
  },
  customerViewPres: {
    show: false,
    data: null,
  },
  patientHistory: {
    show: false,
    data: null,
  },
  comingSoon: {
    show: false,
  },
  diagnosticUpload :{
    show: false,
    data: null,
  }
}

export const sliderSlice = createSlice({
  name: 'modal',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions.
  // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
  // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
  reducers: {
    toggleConsultationModal: (state, action: PayloadAction<any>) => {
      state.consultationModal.show = !state.consultationModal.show
      state.consultationModal.data = action.payload
    },
    togglePrescriptionModal: (state, action: PayloadAction<any>) => {
      state.prescriptionModal.show = !state.prescriptionModal.show
      state.prescriptionModal.data = action.payload
    },
    toggleSubmissionModal: (state, action: PayloadAction<any>) => {
      state.submissionModal.show = !state.submissionModal.show
      state.submissionModal.data = action.payload
    },
    toggleHealthIndicatorModal: (state, action: PayloadAction<any>) => {
      state.healthIndicatorModal.show = !state.healthIndicatorModal.show
      state.healthIndicatorModal.data = action.payload
    },
    toggleSignatureModal: (state, action: PayloadAction<any>) => {
      state.signatureModal.show = !state.signatureModal.show
      state.signatureModal.data = action.payload
    },
    toggleFollowupModal: (state, action: PayloadAction<any>) => {
      state.followupModal.show = !state.followupModal.show
      state.followupModal.data = action.payload
    },
    togglePrescriptionSharedModal: (state, action: PayloadAction<any>) => {
      state.prescriptionSharedModal.show = !state.prescriptionSharedModal.show
      state.prescriptionSharedModal.data = action.payload
    },
    toggleScheduledConsultationModal: (state, action: PayloadAction<any>) => {
      state.scheduledConsultationModal.show =
        !state.scheduledConsultationModal.show
      state.scheduledConsultationModal.data = action.payload
    },
    togglePdfModal: (state, action: PayloadAction<any>) => {
      state.pdfModal.show = !state.pdfModal.show
      state.pdfModal.data = action.payload
    },
    toggleDocReferModal: (state, action: PayloadAction<any>) => {
      state.docReferModal.show = !state.docReferModal.show
      state.docReferModal.data = action.payload
    },
    togglemedStorePrescription: (state, action: PayloadAction<any>) => {
      state.medStorePrescription.show = !state.medStorePrescription.show
      state.medStorePrescription.data = action.payload
    },
    togglemedStoreOrderReq: (state, action: PayloadAction<any>) => {
      state.medStoreOrderReq.show = !state.medStoreOrderReq.show
      state.medStoreOrderReq.data = action.payload
    },
    tooglecustomerViewPres: (state, action: PayloadAction<any>) => {
      state.customerViewPres.show = !state.customerViewPres.show
      state.customerViewPres.data = action.payload
    },
    togglepatientHistory: (state, action: PayloadAction<any>) => {
      state.patientHistory.show = !state.patientHistory.show
      state.patientHistory.data = action.payload
    },
    toggleComingSoon: (state) => {
      state.comingSoon.show = !state.comingSoon.show
    },
    togglediagnosticUpload: (state, action: PayloadAction<any>) => {
      state.diagnosticUpload.show = !state.diagnosticUpload.show
      state.diagnosticUpload.data = action.payload
    },
    closeAllModals: (state) => {
      state.consultationModal.show = false
      state.prescriptionModal.show = false
      state.submissionModal.show = false
      state.healthIndicatorModal.show = false
      state.signatureModal.show = false
      state.followupModal.show = false
      state.prescriptionSharedModal.show = false
      state.scheduledConsultationModal.show = false
      state.pdfModal.show = false
      state.docReferModal.show = false
      state.medStorePrescription.show = false
      state.medStoreOrderReq.show = false
      state.customerViewPres.show = false
      state.patientHistory.show = false
      state.diagnosticUpload.show = false
    },
  },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const {
  toggleConsultationModal,
  togglePrescriptionModal,
  toggleSubmissionModal,
  toggleHealthIndicatorModal,
  toggleSignatureModal,
  toggleFollowupModal,
  closeAllModals,
  togglePrescriptionSharedModal,
  toggleScheduledConsultationModal,
  togglePdfModal,
  toggleDocReferModal,
  togglemedStorePrescription,
  togglemedStoreOrderReq,
  tooglecustomerViewPres,
  togglepatientHistory,
  toggleComingSoon,
  togglediagnosticUpload,
} = sliderSlice.actions

// exporting the reducer here, as we need to add this to the store
export default sliderSlice.reducer
