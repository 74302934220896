import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from '../../utils/axios'
import { notifier } from './notificationSlice'
import { store } from '../../store'

// declaring the types for our state
export type ReportState = {
  loading: Boolean
  reports: any[]
  selectedReport: any | null
  error: any | null
}

const initialState: ReportState = {
  loading: true,
  reports: [],
  selectedReport: null,
  error: null,
}

export const ReportSlice = createSlice({
  name: 'reports',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions.
  // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
  // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
  reducers: {
    setLoading: (state, action: PayloadAction<Boolean>) => {
      state.loading = action.payload
    },
    setReports: (state, action: PayloadAction<any[]>) => {
      state.reports = action.payload
    },
    setSelectedReport: (state, action: PayloadAction<any>) => {
      state.selectedReport = action.payload
    },
    setError: (state, action: PayloadAction<any>) => {
      state.error = action.payload
    },
  },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { setLoading, setReports, setSelectedReport, setError } =
  ReportSlice.actions

//API Calls
export const getReports =
  (type: 'review' | 'reviewed') => async (dispatch: any) => {
    try {
      let query = ''
      if (type === 'reviewed') query = '?reviewed=true'
      dispatch(setLoading(true))
      const response = await axios.get('/prescription/report/review' + query)
      const data = response.data.response.data
      dispatch(setReports(data))
      dispatch(setSelectedReport(data[0]))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setError(error))
      dispatch(setLoading(false))
    }
  }
export const markReportReviewed =
  (prescriptionId: string, testId: string) =>
  async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      const currReports = store.getState().report.reports
      const response = await axios.patch('/prescription/report/review', {
        prescriptionId,
        testId,
      })
      const data = response.data.response.data
      // dispatch(
      //   setReports(
      //     currReports.filter((report) => report.prescriptionId !== data._id)
      //   )
      // )
      notifier.success(response.data.response.msg)
    } catch (error) {}
  }

// exporting the reducer here, as we need to add this to the store
export default ReportSlice.reducer
