import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from '../../../utils/axios'

// declaring the types for our state
export type ClientClinicState = {
    loading: boolean
    error: any | null
    data: any | null
    total: any
    current: any
    oneClinicData: any | null

}

const initialState: ClientClinicState = {
    error: null,
    loading: true,
    data: [],
    total: 0,
    current: 0,
    oneClinicData: [],
}

export const clientClinicSlice = createSlice({
    name: 'clientclinic',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions.
    // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
    // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setData: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data = action.payload
        },
        setTotal: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.total = action.payload
        },
        setCurrent: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.current = action.payload
        },
        setError: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.error = action.payload
        },
        setOneClinicData: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.oneClinicData = action.payload
        },
    },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { setLoading, setData, setCurrent, setTotal, setError, setOneClinicData } =
    clientClinicSlice.actions

//API Calls

export const getClinicList =
    (id: any, page: any) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                const response = await axios.patch(`clinic-by-member/paginate/${id}?page=${page}&limit=10`)
                const data = response.data.response.data
                dispatch(setData(data.docs))
                dispatch(setTotal(data.totalPages))
                dispatch(setCurrent(data.page))
            } catch (error) {
                dispatch(setError(error))
            }
        }

export const saveClinicData =
    (values: any) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                const response = await axios.post(`/clinic`, values)
            } catch (error) {
                dispatch(setError(error))
            }
        }

export const getOneClinicData =
    (id: any) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                const response = await axios.get(`/clinic/${id}`)
                const data = response.data.response.data
                dispatch(setOneClinicData(data))
            }
            catch (error) {
                dispatch(setError(error))
            }
        }

export const updateClinicData =
    (id: any, values: any) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                let response = await axios.patch(`/clinic/${id}`, values)
                const data = response.data.response
            }
            catch (error) {
                dispatch(setError(error))
            }
        }


export const assignClinicData =
    (data: any) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                let response = await axios.post(`/clinic/assign`, data)
            }
            catch (error) {
                dispatch(setError(error))
            }
        }






// exporting the reducer here, as we need to add this to the store
export default clientClinicSlice.reducer
