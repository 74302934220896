import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { store } from '..'
import axios from '../../utils/axios'

// declaring the types for our state
export type PatientState = {
  loading: boolean
  model:boolean
  error: any | null
  patients: any[]
  selectedPatient: any | null
}

const initialState: PatientState = {
  error: null,
  loading: true,
  model:false,
  patients: [],
  selectedPatient: null,
}

export const patientSlice = createSlice({
  name: 'patient',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions.
  // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
  // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setModal: (state, action: PayloadAction<boolean>) => {
      state.model = action.payload
    },
    setData: (state, action: PayloadAction<any>) => {
      state.patients = action.payload
    },
    setError: (state, action: PayloadAction<any>) => {
      state.error = action.payload
    },
    setSelectedPatient: (state, action: PayloadAction<any>) => {
      state.selectedPatient = action.payload
    },
  },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { setLoading, setData, setError, setSelectedPatient,setModal } =
  patientSlice.actions

//API Calls
export const getPatients =
  (type: string, date: string | null) =>
    async (dispatch: (arg0: { payload: any; type: string }) => void) => {
      try {
        dispatch(setLoading(true))
        var response: any = undefined
        if (date) {
          response = await axios.get(`/prescription?${type}=true&date=${date}`)
        } else {
          response = await axios.get(`/prescription?${type}=true`)
        }
        const data = response.data.response.data
        dispatch(setData(data))
        if (!store.getState().patient.selectedPatient) {
          dispatch(setSelectedPatient(data[0]))
        }
        dispatch(setLoading(false))
      } catch (error) {
        dispatch(setError(error))
        dispatch(setLoading(false))
      }
    }

export const getOnePatient =
  (id: string) =>
    async (dispatch: (arg0: { payload: any; type: string }) => void) => {
      try {
        dispatch(setLoading(true))
        const response = await axios.get(`/prescription/${id}`)
        const prescription = response.data.response.data
        console.log({ prescription })
        dispatch(setSelectedPatient(prescription))
        dispatch(setLoading(false))
      } catch (err) {
        console.error(err)
        dispatch(setError(err))
        dispatch(setLoading(false))
      }
    }

export const getSubmissionDetails =
  (id: string) =>
    async (dispatch: (arg0: { payload: any; type: string }) => void) => {
      try {
        // dispatch(setLoading(true))
        // const response = await axios.get(`/prescription/${id}`)
        // const data = response.data.response.data
        // dispatch(setData(data))
      } catch (error) {
        dispatch(setError(error))
      }
    }

// exporting the reducer here, as we need to add this to the store
export default patientSlice.reducer
