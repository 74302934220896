import React from 'react'
import { format } from 'date-fns'
import { Button } from '../../buttons/button'

const HealthStatusCard = ({
  children,
  className,
  onClick,
  item,
}: {
  children?: React.ReactNode
  className?: string
  onClick?: () => void
  item: any,
}) => {

  return (
    <div className="rounded border bg-white p-3 shadow-lg cursor-pointer" onClick={onClick}>
      <p>{item.date ? format(new Date(item.date), 'MMM dd, yyyy') : ''}</p>
      <div className="flex justify-between space-x-2">
        <div className="flex flex-col">
          <span className="font-semibold">Health Status</span>
          <Button className="py-3 px-8" children="HEALTHY" />
        </div>
        <div className="flex flex-col">
          <span className="font-semibold">Submission</span>
          <Button className="py-3 px-8" outline gray children="Pending" />
        </div>
      </div>
    </div>
  )
}

export default HealthStatusCard
