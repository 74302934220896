import {
  HomeIcon,
  ViewGridIcon,
  DatabaseIcon, ViewListIcon, UserGroupIcon, CalendarIcon, UserAddIcon, PaperAirplaneIcon, OfficeBuildingIcon, CogIcon
} from '@heroicons/react/outline'
// import {faPati} from "@fortawesome/free-solid-svg-icons"

export const sidebarNavigation = [
  {
    name: 'Products',
    icon: DatabaseIcon,
    href: '/client/products',
  },
  {
    name: 'Orders',
    icon: ViewListIcon,
    href: '/client/orders',
  },
  {
    name: 'Users',
    icon: UserGroupIcon,
    href: '/client/users',
  },
  // {
  //   name: 'Appointments',
  //   icon: CalendarIcon,
  //   href: '/client/appointment',
  // },
  {
    name: 'Diagnostics',
    icon: CalendarIcon,
    href: '/client/diagnostics',
  },
  {
    name: 'Package',
    icon: PaperAirplaneIcon,
    href: '/client/package',
  },
  {
    name: 'Member',
    icon: UserAddIcon,
    href: '/client/member',
  },
  {
    name: 'Clinic',
    icon: OfficeBuildingIcon,
    href: '/client/clinic',
  },
  {
    name: 'Account',
    icon: CogIcon,
    href: '/client/account',
  }
]

