import React, { useCallback, useEffect } from 'react'
import { Dialog, Popover } from '@headlessui/react'
import SliderHeader from '../SliderHeader'
import { XIcon } from '@heroicons/react/solid'
import { useAppDispatch, useAppSelector } from '../../../hooks/home'
import { toggleHealthIndicatorSlider } from '../../../store/slices/sliderSlice'
import { Button } from '../../buttons/button'
import { frequency, interval, time_slots } from '../config'
import { addDays, format } from 'date-fns'
import { indicator_options, weekDaysList } from '../../../utils/constants'
import { useFormik } from 'formik'
import Select from 'react-select'
import FormErrorMessage from '../shared/FormErrorMessage'
import { addDocData, updateDocData } from '../../../store/slices/prescriptionSlice'
import {
  healthInitialState,
  healthvalidationSchema,
} from '../../../functionality/healthIndicator'
import { Calendar } from 'react-date-range'

const HealthIndicatorSlider = () => {
  const dispatch = useAppDispatch()
  const show = useAppSelector(
    (state) => state.slider.healthIndicatorSlider.show
  )
  const editState = useAppSelector(
    (state) => state.slider.healthIndicatorSlider.data
  )

  const toggle = () => {
    dispatch(toggleHealthIndicatorSlider(null))
  }

  const onSubmit = useCallback(
    (values) => {
      (async () => {
        try {
          console.log('values', values)
          if (editState) {
            await dispatch(updateDocData('healthIndicators', { data: values }))
          } else {
            await dispatch(addDocData('healthIndicators', { data: values }))
          }
          toggle()
        } catch (err) {
          console.error(err)
        }
      })();
    }, [editState])

  const formik = useFormik({
    initialValues: healthInitialState,
    validationSchema: healthvalidationSchema,
    onSubmit: onSubmit,
  })

  //Set Initial Data
  useEffect(() => {
    if (!show) return formik.resetForm()
    if (!editState) return formik.resetForm()
    formik.setValues({
      ...formik.values,
      ...editState,
      fromDt: new Date(),
      toDt: null,
    })
  }, [])


  //Set ToDt
  useEffect(() => {
    let type = formik.values.course_duration_type
    let count = formik.values.course_duration_count
    let value
    if (!type || !count) return
    switch (type) {
      case 'day':
        value = addDays(new Date(), count)
        break
      case 'week':
        value = addDays(new Date(), count * 7)
        break
      case 'month':
        value = addDays(new Date(), count * 30)
        break
      case 'manual':
        value = null
        break
    }
    if (!value) return
    formik.setFieldValue('toDt', format(value, 'yyyy-MM-dd'))
  }, [formik.values.course_duration_count, formik.values.course_duration_type])


  const onManualSchedule = () => {
    formik.setFieldValue('course_duration_type', 'manual')
    if (!formik.values.toDt) formik.setFieldValue('toDt', format(new Date(), 'yyyy-MM-dd'))
  }

  // Symptoms and Diseases onChange
  const handleArrayChange = (key: string, name: string) => {
    formik.setFieldValue(
      key,
      formik.values[key].find((s: any) => s.name === name)
        ? formik.values[key].filter((s: any) => s.name !== name)
        : [...formik.values[key], { name, value: true }]
    )
  }

  //Time Slot
  const handleHour = (h: Number) => {
    if (formik.values.frequency === 24) {
      return
    }
    formik.setFieldValue(
      'time_slot',
      formik.values.time_slot.includes(h)
        ? formik.values.time_slot.filter((i: Number) => i !== h)
        : formik.values.time_slot.length < formik.values.frequency
          ? [...formik.values.time_slot, h]
          : [...formik.values.time_slot.slice(1), h]
    )
  }

  //Interval
  const handleInterval = (i: string) => {
    formik.setValues((v: any) => ({
      ...v,
      interval: i,
      weekDays: i === 'Daily' ? weekDaysList : [],
    }))
  }

  //Frequency
  const handleFrequency = (f: string | number) => {
    formik.setValues((v: any) => ({
      ...v,
      frequency: f,
      time_slot: f === 24 ? time_slots : [],
    }))
  }

  const handleWeekDays = (val: any) => {
    console.log(val)
    formik.setFieldValue('weekDays', val)
    formik.setFieldValue(
      'weekDays',
      val.map((o: any) => o.value)
    )
  }

  return (
    <Dialog.Panel className="pointer-events-auto h-full w-screen max-w-3xl">
      <div className="flex h-full flex-col overflow-y-scroll bg-gray-100 py-6 pt-24 shadow-xl">
        {/* <SliderHeader onClose={() => {}} title={undefined} /> */}
        <div className="mt-6 flex flex-col px-4 sm:px-6">
          <div className="flex items-center justify-between">
            <span className="font-semibold">Health Indicator:</span>
            <span className="cursor-pointer rounded-full bg-green-400 p-1 text-white">
              <XIcon onClick={toggle} className="h-3 w-3" />
            </span>
          </div>
          <div className="flex space-x-3">
            <div className="flex flex-col items-stretch space-y-4">
              <Select
                id="indicator_name"
                placeholder="Select Indicator"
                value={
                  formik.values.indicator_name
                    ? indicator_options.find(
                      (i) => i.label === formik.values.indicator_name
                    )
                    : null
                }
                options={indicator_options}
                onChange={(o: any) =>
                  formik.setFieldValue('indicator_name', o.label)
                }
                onBlur={() =>
                  formik.handleBlur({ target: { name: 'indicator_name' } })
                }
              />
              <FormErrorMessage
                touched={formik.touched.indicator_name}
                error={formik.errors.indicator_name}
              />

              <div className="flex flex-col space-y-2">
                <span className="font-semibold">Course Duration:</span>
                <div className="flex">
                  <div className="flex items-center space-x-2">
                    <Button
                      active={formik.values.course_duration_type === 'day'}
                      onClick={() =>
                        formik.setFieldValue('course_duration_type', 'day')
                      }
                      className="px-3"
                      gray
                      outline
                    >
                      Days
                    </Button>
                    <Button
                      active={formik.values.course_duration_type === 'week'}
                      onClick={() =>
                        formik.setFieldValue('course_duration_type', 'week')
                      }
                      className="px-3"
                      gray
                      outline
                    >
                      Weeks
                    </Button>
                    <Button
                      active={formik.values.course_duration_type === 'month'}
                      onClick={() =>
                        formik.setFieldValue('course_duration_type', 'month')
                      }
                      className="px-3"
                      gray
                      outline
                    >
                      Months
                    </Button>
                  </div>
                  <Popover className="ml-auto">
                    <Popover.Button as={'div'} >
                      <Button
                        className='px-3'
                        active={formik.values.course_duration_type === 'manual'}
                        onClick={onManualSchedule}

                        gray
                        outline
                      >
                        {formik.values.toDt && formik.values.course_duration_type === 'manual' ? format(new Date(formik.values.toDt), 'dd MMMM yyyy') : 'Manual'}
                      </Button>
                    </Popover.Button>
                    <Popover.Panel>
                      {/* @ts-ignore */}
                      <Calendar
                        minDate={new Date()}
                        className='max-w-full absolute'
                        date={formik.values.toDt ? new Date(formik.values.toDt) : undefined}
                        onChange={date => formik.setFieldValue('toDt', format(date, 'yyyy-MM-dd'))}
                      />

                    </Popover.Panel>
                  </Popover>
                </div>
                <FormErrorMessage
                  touched={formik.touched.course_duration_type}
                  error={formik.errors.course_duration_type}
                />
                {formik.values.course_duration_type !== 'manual' && <div className="flex space-x-2">
                  {Array.from({ length: 10 }).map((_, i) => (
                    <Button
                      rounded
                      active={formik.values.course_duration_count === i + 1}
                      onClick={() =>
                        formik.setFieldValue('course_duration_count', i + 1)
                      }
                      className="px-3"
                      gray
                      outline
                    >
                      {i + 1}
                    </Button>
                  ))}
                </div>
                }
                <FormErrorMessage
                  touched={formik.touched.course_duration_count}
                  error={formik.errors.course_duration_count}
                />
              </div>

              <div className="flex flex-col space-y-2">
                <span className="font-semibold">Interval:</span>
                <div className="flex space-x-2">
                  {interval.map((f) => (
                    <Button
                      active={formik.values.interval === f}
                      onClick={() => handleInterval(f)}
                      className="px-3 "
                      gray
                      outline
                    >
                      {f}
                    </Button>
                  ))}
                </div>
                <FormErrorMessage
                  touched={formik.touched.interval}
                  error={formik.errors.interval}
                />
              </div>
              {!!formik.values.interval?.includes('eek') && (
                <div className="flex flex-col space-y-2">
                  <span className="font-semibold">WeekDays:</span>
                  <Select
                    isMulti
                    id="weekDays"
                    placeholder="Select WeekDays"
                    value={formik.values.weekDays.map((day: string) => ({ label: day, value: day }))}
                    options={weekDaysList}
                    onChange={handleWeekDays}
                    onBlur={() =>
                      formik.handleBlur({ target: { name: 'weekDays' } })
                    }
                  />
                  <FormErrorMessage
                    touched={formik.touched.weekDays}
                    error={formik.errors.weekDays}
                  />
                </div>
              )}

              <div className="flex flex-col space-y-2">
                <span className="font-semibold">Frequency:</span>
                <div className="flex space-x-2">
                  {frequency.filter(f => f.value !== 'SOS').map((f) => (
                    <Button
                      active={formik.values.frequency === f.value}
                      onClick={() => handleFrequency(f.value)}
                      className="px-3 "
                      gray
                      outline
                    >
                      {f.name}
                    </Button>
                  ))}
                </div>
                <FormErrorMessage
                  touched={formik.touched.frequency}
                  error={formik.errors.frequency}
                />
              </div>

              <div className="flex flex-col space-y-2">
                <span className="font-semibold">Time:</span>
                <div className="grid flex-shrink grid-cols-4 gap-1">
                  {time_slots.map((hour: Number) => (
                    <Button
                      active={formik.values.time_slot.includes(hour)}
                      onClick={() => handleHour(hour)}
                      outline
                      gray
                      className=" whitespace-nowrap text-center "
                    >
                      {format(
                        new Date().setHours(Number(hour), 0, 0, 0),
                        'h:mm a'
                      )}{' '}
                      -{' '}
                      {format(
                        new Date().setHours(Number(hour) + 2, 0, 0, 0),
                        'h:mm a'
                      )}
                    </Button>
                  ))}
                </div>
                <FormErrorMessage
                  touched={formik.touched.time_slot}
                  error={formik.errors.time_slot}
                />
              </div>
            </div>
          </div>
          <Button
            disabled={formik.isSubmitting}
            onClick={formik.handleSubmit}
            className="mx-auto mt-6 w-40 text-lg"
          >
            {editState && (formik.isSubmitting ? 'Updating...' : 'Update')}
            {!editState && (formik.isSubmitting ? 'Saving...' : 'Save')}
          </Button>
        </div>
      </div>
    </Dialog.Panel>
  )
}

export default HealthIndicatorSlider
