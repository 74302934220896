import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from '../../../utils/axios'
import { notifier } from '../notificationSlice'
// declaring the types for our state
export type MedstoreProfileState = {
    loading: boolean
    error: any | null
    data: any
}

const initialState: MedstoreProfileState = {
    error: null,
    loading: true,
    data: null
}

export const medProfileSlice = createSlice({
    name: 'medprofile',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions.
    // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
    // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setData: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data = action.payload
        },
        setError: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.error = action.payload
        }
    },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { setLoading, setData, setError } =
    medProfileSlice.actions

//API Calls
export const getStoreProfile =
    (id: string) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                const response = await axios.get(`medStore/${id}`)
                const data = response.data.response.data
                dispatch(setData(data))
            } catch (error) {
                dispatch(setError(error))
            }
        }

export const updateStoreProfile =
    (id: string, data: any) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                const response = await axios.patch(`/medStore/${id}`, data)
                const resData = response.data.response.data
                dispatch(setData(resData))
                dispatch(setLoading(false))
            } catch (error) {
                dispatch(setError(error))
                notifier.error("Error try Again")
            }
        }

export const updateWhatsapp =
    (data1: any) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                const res = await axios.post(`/medstore/consent`, data1)
            } catch (error) {
                dispatch(setError(error))
            }
        }

export const changePassword =
    (data: any) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                const response = await axios.patch(`/medstore/settings/changePassword`, data)
                notifier.success(response.data.response)
            } catch (error) {
                dispatch(setError(error))
                notifier.error("Old password is incorrect, Please provide the correct password !")
            }
        }



// exporting the reducer here, as we need to add this to the store
export default medProfileSlice.reducer
