import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon, XIcon, ZoomInIcon } from '@heroicons/react/outline'
import DocumentViewer from '../../doctors/shared/DocumentViewer'
import { tooglecustomerViewPres } from '../../../store/slices/modalSlice'
import { useAppDispatch, useAppSelector } from '../../../hooks/home'

function CustomerViewPresModal() {
    const dispatch = useAppDispatch()
    const url = useAppSelector((state) => state.modal.customerViewPres.data)
    const toggle = () => {
        dispatch(tooglecustomerViewPres(null))
    }
    return (
        <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-2 pb-4 text-left overflow-hidden shadow-xl transform transition-all w-screen max-w-xl">
            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-2">
                <button
                    type="button"
                    onClick={toggle}
                >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6 bg-greenColor text-white rounded-full" aria-hidden="true" />
                </button>
            </div>
            <div className="text-center sm:mt-0 sm:ml-4 sm:text-left w-11/12">

                {!!url && (
                    <DocumentViewer
                        documentURL={url + `?session=${localStorage.getItem('token')}`} fileName="download.pdf"
                    ></DocumentViewer>
                )}


            </div>

            <hr />
           
        </Dialog.Panel>
    )
}

export default CustomerViewPresModal