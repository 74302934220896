import React from 'react'
import classNames from 'classnames'
const HealthButton = ({
  children,
  className,
  onClick = () => {},
}: {
  children: React.ReactNode
  className?: string
  onClick?: () => void
}) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className={classNames(
        className,
        children == 'HEALTHY'
          ? 'bg-greenColor text-white'
          : children == 'PENDING' || children == 'COMPLETED'
          ? 'h-8 border-2 border-blueColor bg-white'
          : 'bg-red-500 text-white',
        'inline-flex w-28 items-center justify-center rounded-md px-4 text-xs font-medium'
      )}
    >
      {children}
    </button>
  )
}

export default HealthButton
