import Link from "next/link";
import dynamic from 'next/dynamic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Nav } from 'react-bootstrap'
import { faFacebook, faTwitter, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons"
import classNames from "classnames";
import { Accordion } from 'react-bootstrap'
import { Fab, Action } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import Router, { useRouter } from "next/router";
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import Image from 'next/image';

function callDoctorRequested() {
    console.log(`Call doctor requested`);
    window.location.href = 'https://user.eonmed.in/';
}

const navigation = {
    know: [
        { name: 'About Us', href: "/about-us" },
        { name: 'Blog', href: "/blog" },
        { name: 'Contact Us', href: "/contact-us" },
    ],
    products: [
        { name: 'Primary HealthCare', href: "/primary-health-care" },
        { name: 'Doctor Consultancy', href: "/doctor-consultancy" },
        { name: 'Covid Care Plus', href: "/covid-care-plus" },
        { name: 'Smart Clinic', href: "/smart-clinic" },
        { name: 'DOCQR', href: "/docqr" },
    ],
    Initiatives: [
        { name: 'Healthcare Corporate Camps', href: "/healthcare-corporate-camps" },
        { name: 'Healthcare School Camps', href: "/school-healthcare-camps" },
        { name: 'Mass Covid Testing', href: "/mass-covid-test" },
    ],
    Policies: [
        { name: 'Privacy Policy', href: "/privacy-policy" },
        { name: 'Terms And Conditions', href: "/terms-and-conditions" },
        { name: 'Cancellation-Refund Policy', href: "/cancellation-refund-policy" },
    ],
    social: [
        {
            name: 'Facebook',
            href: "https://www.facebook.com/EonmedIndia",
            icon: (props: any) => (
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                    <g id="Group_18992" data-name="Group 18992" transform="translate(-4369 -14586)">
                        <rect id="Rectangle_8912" data-name="Rectangle 8912" width="28" height="28" transform="translate(4369 14586)" fill="#fff" opacity="0" />
                        <rect id="Rectangle_8902" data-name="Rectangle 8902" width="24" height="24" rx="12" transform="translate(4371 14588)" fill="#fff" />
                        <path id="Path_33644" data-name="Path 33644" d="M529.615,875.338h-2.557a.775.775,0,0,1-.776-.774V868.8h-1.492a.776.776,0,0,1-.774-.776v-2.472a.775.775,0,0,1,.774-.774h1.492v-1.238a4.215,4.215,0,0,1,1.114-3.019,4.015,4.015,0,0,1,2.96-1.15l1.951,0a.777.777,0,0,1,.774.776v2.294a.777.777,0,0,1-.776.776h-1.312c-.4,0-.5.08-.525.1-.036.041-.078.156-.078.474v.979h1.817a.8.8,0,0,1,.384.1.779.779,0,0,1,.4.679l0,2.47a.776.776,0,0,1-.774.776h-1.824v5.769A.776.776,0,0,1,529.615,875.338Zm2.691-15.032Z" transform="translate(3854.451 13732.627)" fill="#5eb3e4" />
                    </g>
                </svg>
            ),
        },
        {
            name: 'Instagram',
            href: "https://www.instagram.com/eonmedindia",
            icon: (props: any) => (
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                    <g id="Group_18990" data-name="Group 18990" transform="translate(-4445 -14586)">
                        <rect id="Rectangle_8911" data-name="Rectangle 8911" width="28" height="28" transform="translate(4445 14586)" fill="#fff" opacity="0" />
                        <rect id="Rectangle_8904" data-name="Rectangle 8904" width="24" height="24" rx="12" transform="translate(4447 14588)" fill="#fff" />
                        <g id="Group_18967" data-name="Group 18967" transform="translate(4451.014 14592.014)">
                            <path id="Path_33648" data-name="Path 33648" d="M708.951,860.986a2.88,2.88,0,1,0,2.88,2.88A2.883,2.883,0,0,0,708.951,860.986Z" transform="translate(-700.966 -855.881)" fill="#5eb3e4" />
                            <path id="Path_33649" data-name="Path 33649" d="M714.7,857.971h-7.325a4.328,4.328,0,0,0-4.323,4.323v7.325a4.33,4.33,0,0,0,4.323,4.323H714.7a4.328,4.328,0,0,0,4.323-4.323v-7.325A4.327,4.327,0,0,0,714.7,857.971Zm-3.663,12.353a4.367,4.367,0,1,1,4.367-4.367A4.372,4.372,0,0,1,711.042,870.324Zm4.472-7.7a1.29,1.29,0,1,1,1.29-1.29A1.291,1.291,0,0,1,715.514,862.619Z" transform="translate(-703.056 -857.971)" fill="#5eb3e4" />
                        </g>
                    </g>
                </svg>

            ),
        },
        {
            name: 'Twitter',
            href: "https://twitter.com/Eonmedindia",
            icon: (props: any) => (
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                    <g id="Group_18991" data-name="Group 18991" transform="translate(-4407 -14586)">
                        <rect id="Rectangle_8909" data-name="Rectangle 8909" width="28" height="28" transform="translate(4407 14586)" fill="#fff" opacity="0" />
                        <rect id="Rectangle_8906" data-name="Rectangle 8906" width="24" height="24" rx="12" transform="translate(4409 14588)" fill="#fff" />
                        <path id="Path_33652" data-name="Path 33652" d="M617.331,873.412c-2.665,0-4.773-.466-5.781-1.279l-.019-.015-.019-.019a.819.819,0,0,1-.215-.862l.01-.025a.832.832,0,0,1,.76-.523,6.9,6.9,0,0,0,1.82-.251,3.458,3.458,0,0,1-1.82-2.1.743.743,0,0,1,.261-.869.8.8,0,0,1,.227-.117,5.01,5.01,0,0,1-1.033-1.851l-.007-.02,0-.02a.766.766,0,0,1,.726-.938,3.939,3.939,0,0,1-.293-.9,3.759,3.759,0,0,1,.4-2.5l.1-.2a.553.553,0,0,1,.886-.142l.18.18a8.506,8.506,0,0,0,4.911,2.689,3.918,3.918,0,0,1,1.057-2.255,3.7,3.7,0,0,1,2.645-1.165h.007a5.314,5.314,0,0,1,2.186.711c.471-.154,1.038-.391,1.626-.643a.735.735,0,0,1,.991.948c-.044.119-.091.235-.146.351a.727.727,0,0,1,.217.149.805.805,0,0,1,.2.75l-.007.025a3.067,3.067,0,0,1-1.233,1.69C625.867,869.3,622.032,873.412,617.331,873.412Z" transform="translate(3801.677 13733.16)" fill="#5eb3e4" />
                    </g>
                </svg>

            ),
        },
        {
            name: 'Linkdln',
            href: "https://www.linkedin.com/company/eonmed",
            icon: (props: any) => (
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                    <g id="Group_18988" data-name="Group 18988" transform="translate(-4521 -14586)">
                        <rect id="Rectangle_8914" data-name="Rectangle 8914" width="28" height="28" transform="translate(4521 14586)" fill="#fff" opacity="0" />
                        <rect id="Rectangle_8903" data-name="Rectangle 8903" width="24" height="24" rx="12" transform="translate(4523 14588)" fill="#fff" />
                        <g id="Group_18965" data-name="Group 18965" transform="translate(4527.886 14592.016)">
                            <path id="Path_33645" data-name="Path 33645" d="M706.819,843.591h-2.692a.548.548,0,0,0-.547.547v10.146a.548.548,0,0,0,.547.547h2.692a.548.548,0,0,0,.549-.547V844.138A.548.548,0,0,0,706.819,843.591Z" transform="translate(-703.565 -838.862)" fill="#5eb3e4" />
                            <path id="Path_33646" data-name="Path 33646" d="M705.479,840.8a1.908,1.908,0,1,0,1.91,1.908A1.91,1.91,0,0,0,705.479,840.8Z" transform="translate(-703.571 -840.798)" fill="#5eb3e4" />
                            <path id="Path_33647" data-name="Path 33647" d="M714.065,844.78a3.5,3.5,0,0,0-2.3-.809,1.548,1.548,0,0,0-1.5.918v-.776a.522.522,0,0,0-.522-.521H707a.522.522,0,0,0-.523.521v10.2a.522.522,0,0,0,.523.522h2.8a.467.467,0,0,0,.467-.467v-6.079a.97.97,0,0,1,.928-.977.959.959,0,0,1,.985.957v6.108a.458.458,0,0,0,.459.459h2.686a.458.458,0,0,0,.459-.459v-5.923A4.765,4.765,0,0,0,714.065,844.78Z" transform="translate(-701.555 -838.862)" fill="#5eb3e4" />
                        </g>
                    </g>
                </svg>

            ),
        },

    ],
}


export default function Index() {

    const { pathname } = useRouter()

    return (
        <>
            {!pathname.includes('/smart-clinic') &&
                <section id="doanloadApp" className="pt-5 mt-5 doanloadApp1">
                    <div className="container mx-auto">
                        <div className="grid grid-cols-2 max-w-6xl mx-auto space-x-5">
                            <div>
                                <Image src="/images/app.png" alt="APP" width={500}
                                    height={680} objectFit="contain" />
                            </div>
                            <div className="flex justify-center items-center mb-14">

                                <div>
                                    <h2 className="text-3xl">Download the EONMED app</h2>
                                    <p className="mb-2">Get 24/7 consultation from top doctors with just one click through the EONMED app. With our effective products like Primary Healthcare kit and video consultation, the EONMED app is a one-stop solution for all your primary care needs.</p>
                                    <br />
                                    <ul className="mb-5">
                                        <li className="flex items-center space-x-2">

                                            <Image
                                                src="/images/download1.png"
                                                layout="intrinsic"
                                                height={24}
                                                width={24}
                                                objectFit="contain"
                                                alt="download" />


                                            <span className="text-white text-base">Easy on-boarding</span> </li>
                                        <li className="flex items-center space-x-2 mt-2">
                                            <Image
                                                src="/images/download2.png"
                                                layout="intrinsic"
                                                height={24}
                                                width={24}
                                                objectFit="contain"
                                                alt="download" />


                                            <span className="text-white text-base">Highly-experienced doctors from trusted institutions</span>  </li>
                                        <li className="flex items-center space-x-2 mt-2">
                                            <Image
                                                src="/images/download3.png"
                                                layout="intrinsic"
                                                height={24}
                                                width={24}
                                                objectFit="contain"
                                                alt="download" />
                                            <span className="text-white text-base">Disease management through regular monitoring</span>  </li>
                                    </ul>

                                    <a href="https://play.google.com/store/apps/details?id=com.eonbt.health" target="_blank" className="me-3">
                                        <Image
                                            src="/images/google-play-store-logo.png" alt="google-play-store-logo"
                                            layout="intrinsic"
                                            height={50}
                                            width={150}
                                            objectFit="contain"
                                        />
                                    </a>
                                    <a href="https://apps.apple.com/in/app/eonbt-health-app/id1544722909" target="_blank" className="ml-3">
                                        <Image
                                            src="/images/apple-app-store-logo.png" alt="apple-app-store-logo"
                                            layout="intrinsic"
                                            height={50}
                                            width={150}
                                            objectFit="contain"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }

            <footer className="bg-[#5EB3E4]" aria-labelledby="footer-heading">
                <h2 id="footer-heading" className="sr-only">
                    Footer
                </h2>
                <div className="max-w-7xl mx-auto py-5 px-4 sm:px-6 lg:py-10 lg:px-8">


                    <div className="mt-1 grid grid-cols-2 md:grid-cols-5 gap-2 xl:mt-0">

                        <div>
                            <h3 className="text-sm font-semibold text-white tracking-wider uppercase mb-3">Know Us</h3>
                            <ul role="list" className="mt-4 space-y-4">
                                {navigation.know.map((item) => (
                                    <li key={item.name}>
                                        <a href={item.href} className="text-base text-white hover:text-gray-900">
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="md:mt-0">
                            <h3 className="text-sm font-semibold text-white tracking-wider uppercase mb-3">Products</h3>
                            <ul role="list" className="mt-4 space-y-4">
                                {navigation.products.map((item) => (
                                    <li key={item.name}>
                                        <a href={item.href} className="text-base text-white hover:text-gray-900">
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>


                        <div className="-mt-12 md:mt-0">
                            <h3 className="text-sm font-semibold text-white tracking-wider uppercase mb-3">Our Initiatives</h3>
                            <ul role="list" className="mt-4 space-y-4">
                                {navigation.Initiatives.map((item) => (
                                    <li key={item.name}>
                                        <a href={item.href} className="text-base text-white hover:text-gray-900">
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="mt-7 md:mt-0">
                            <h3 className="text-sm font-semibold text-white tracking-wider uppercase mb-3">Our Policies</h3>
                            <ul role="list" className="mt-4 space-y-4">
                                {navigation.Policies.map((item) => (
                                    <li key={item.name}>
                                        <a href={item.href} className="text-base text-white hover:text-gray-900">
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="-mt-4 md:mt-0">
                            <h3 className="text-sm font-semibold text-white tracking-wider uppercase mb-3">Follow Us</h3>
                            <div className="flex space-x-6">
                                {navigation.social.map((item) => (
                                    <a key={item.name} href={item.href} className="text-blue-500 hover:text-gray-500">
                                        <span className="sr-only">{item.name}</span>
                                        <item.icon className="h-6 w-6" aria-hidden="true" />
                                    </a>
                                ))}
                            </div>
                            <div className="md:hidden mt-3">
                                <h6 className="text-white text-xs">Download the EONMED app</h6>
                                <div className="flex space-x-2 p-1 mt-2">
                                    <a href="https://play.google.com/store/apps/details?id=com.eonbt.health" target="_blank" className="me-3">
                                        <Image
                                            src="/images/google-play-store-logo.png" alt="google-play-store-logo"
                                            layout="intrinsic"
                                            height={50}
                                            width={150}
                                            objectFit="contain"
                                        />
                                    </a>
                                    <a href="https://apps.apple.com/in/app/eonbt-health-app/id1544722909" target="_blank" className="ml-3">
                                        <Image
                                            src="/images/apple-app-store-logo.png" alt="apple-app-store-logo"
                                            layout="intrinsic"
                                            height={50}
                                            width={150}
                                            objectFit="contain"
                                        />
                                    </a>

                                </div>
                            </div>
                            <h6 className="mt-4 mb-1 text-white">Our Payment Partners</h6>
                            <div className="grid grid-cols-2 gap-2">

                                <Image
                                    src="/images/googlepay.svg" alt="Google Pay"
                                    layout="intrinsic"
                                    height={50}
                                    width={150}
                                    objectFit="contain"
                                />
                                <Image
                                    src="/images/paytm.svg" alt="Paytm"
                                    layout="intrinsic"
                                    height={50}
                                    width={150}
                                    objectFit="contain"
                                />
                                <Image
                                    src="/images/upi.svg" alt="UPI"
                                    layout="intrinsic"
                                    height={50}
                                    width={150}
                                    objectFit="contain"
                                />
                                <Image
                                    src="/images/razorpay.svg" alt="upi"
                                    layout="intrinsic"
                                    height={50}
                                    width={150}
                                    objectFit="contain"
                                />
                            </div>
                        </div>


                    </div>

                    <div className="mt-8 -mb-5 p-1">
                        <div className="text-center space-y-2">
                            <Image
                                src="/images/logo-white.svg" alt="Eonmed"
                                layout="intrinsic"
                                height={50}
                                width={150}
                                objectFit="contain"
                            />

                            <p className="text-white">Copyright &copy; 2022 EONMED. All Rights Reserved. | A Product Of EONbt Pvt. Ltd.</p>
                        </div>
                        {typeof window !== 'undefined' && <div>
                            <Fab
                                event="hover"
                                alwaysShowTitle={true}
                                mainButtonStyles={{
                                    backgroundColor: "#00b5ad"
                                }}
                                icon={<FontAwesomeIcon icon={faPhone} />}
                            >
                                <Action
                                    text="Call Doctor"
                                    onClick={callDoctorRequested}
                                >
                                    <FontAwesomeIcon icon={faPhone} />
                                </Action>
                            </Fab>
                        </div>}
                    </div>
                </div>
            </footer>
        </>
    );
}