import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from '../../utils/axios'

export type UtilState = {
  medicines: {
    loading: boolean
    list: any[]
    error: any | null
  }
  tests: {
    loading: boolean
    list: any[]
    error: any | null
  }
}

export const initialState: UtilState = {
  medicines: {
    loading: false,
    list: [],
    error: null,
  },
  tests: {
    loading: false,
    list: [],
    error: null,
  },
}

export const utilSlice = createSlice({
  name: 'util',
  initialState,
  reducers: {
    setMedicinesLoading: (state, action: PayloadAction<boolean>) => {
      state.medicines.loading = action.payload
    },
    setMedicinesList: (state, action: PayloadAction<any[]>) => {
      state.medicines.list = action.payload
    },
    setMedicinesError: (state, action: PayloadAction<any>) => {
      state.medicines.error = action.payload
    },
    setTestsLoading: (state, action: PayloadAction<boolean>) => {
      state.tests.loading = action.payload
    },
    setTestsList: (state, action: PayloadAction<any[]>) => {
      state.tests.list = action.payload
    },
    setTestsError: (state, action: PayloadAction<any>) => {
      state.tests.error = action.payload
    },
  },
})

export const {
  setMedicinesLoading,
  setMedicinesList,
  setMedicinesError,
  setTestsLoading,
  setTestsList,
  setTestsError,
} = utilSlice.actions

export const getMedicineList = () => async (dispatch: any) => {
  try {
    dispatch(setMedicinesLoading(true))
    const response = await axios.get('/medicines/all')
    const medicines = response.data.response.data
    dispatch(setMedicinesList(medicines))
    dispatch(setMedicinesLoading(false))
  } catch (error) {
    dispatch(setMedicinesError(error))
    dispatch(setMedicinesLoading(false))
  }
}

export const getTestsList = () => async (dispatch: any) => {
  try {
    dispatch(setTestsLoading(true))
    const response = await axios.get('/tests')
    const tests = response.data.response.data
    dispatch(setTestsList(tests))
    dispatch(setTestsLoading(false))
  } catch (error) {
    dispatch(setTestsError(error))
    dispatch(setTestsLoading(false))
  }
}

export default utilSlice.reducer
