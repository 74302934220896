export const symptom_list: any = {
  cough: 'Cough',
  diarrhea: 'Diarrhea',
  arthiritis: 'Arthiritis',
  nausea: 'Nausea',
  soreThroat: 'Sore Throat',
  chestPain: 'Chest Pain',
  sputum: 'Sputum',
  abdominalPain: 'Abdominal Pain',
  vomitting: 'Vomitting',
  haemoptysis: 'Haemoptysis',
  bodyAche: 'Body Ache',
  fever: 'Fever',
  runnyNose: 'Runny Nose',
  lossOfSmell: 'Loss Of Smell',
  lossOfTaste: 'Loss Of Taste',
  headAche: 'Head Ache',
  chill: 'Chill',
  breathlessness: 'Breathlessness',
}

export const disease_list = {
  copd: 'Chronic Obstructive Pulmonary Disease',
  bronchitis: 'Bronchitis',
  chronicRenalDisease: 'Chronic Renal Disease',
  heartDisease: 'Heart Disease',
  diabetes: 'Diabetes',
  malignancy: 'Malignancy',
  hypertension: 'Hypertension',
  hypothyroidism: 'Hypothyroidism',
  asthma: 'Asthama',
  immunocompromisedCondition: 'Immuno Compromised Condition',
}

export const weekDaysList = [
  { label: 'Monday', value: 'Monday' },
  { label: 'Tuesday', value: 'Tuesday' },
  { label: 'Wednesday', value: 'Wednesday' },
  { label: 'Thursday', value: 'Thursday' },
  { label: 'Friday', value: 'Friday' },
  { label: 'Saturday', value: 'Saturday' },
  { label: 'Sunday', value: 'Sunday' },
]

export const indicator_options = [
  { label: 'Temperature', value: 'temperature' },
  { label: 'Blood Pressure', value: 'bp' },
  { label: 'PR/BPM', value: 'prbpm' },
  { label: 'Blood Glucose', value: 'blood_glucose' },
  { label: 'Respiratory Rate', value: 'respiratory_rate' },
  { label: 'SpO2', value: 'spo2' },
  { label: 'Hemoglobin', value: 'hemoglobin' },
]


export const availableTimeSlots = [
  '6:30AM - 7:30AM',
  '7:30AM - 8:30AM',
  '8:30AM - 9:30AM',
  '9:30AM - 10:30AM',
  '10:30AM - 11:30AM',
  '11:30AM - 12:30PM',
  '12:30PM - 01:30PM',
  '01:30PM - 02:30PM',
  '02:30PM - 03:30PM',
  '03:30PM - 04:30PM',
  '04:30PM - 05:30PM',
  '05:30PM - 06:30PM',
  '06:30PM - 07:30PM',
  '07:30PM - 08:30PM',
  '08:30PM - 09:30PM',
  '09:30PM - 10:30PM',
];

//export const razorpaykey = 'rzp_test_rjyOB5xXocEsns'
