import { Fragment, useCallback } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useAppDispatch, useAppSelector } from '../../hooks/home'
import {
  PatientProfileSlider,
  SymptomsSlider,
  NoteSlider,
  VitalsSlider,
  MedicalRecordSlider,
  MedicineScheduleSlider,
  HealthIndicatorSlider,
} from './sliders'
import DiagnosticSlider from './sliders/DiagnosticSlider'
import ExperienceSlider from './sliders/ExperienceSlider'
import EducationSlider from './sliders/EducationSlider'

export default function Slider() {
  const dispatch = useAppDispatch()
  const sliderState = useAppSelector((state) => state.slider)

  const getShow = useCallback(() => {
    return Object.entries(sliderState).some(([key, { show }]) => show)
  }, [sliderState])

  const toggle = () => {
    // dispatch(closeAllSliders())
  }

  return (
    <Transition.Root show={getShow()} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={toggle}>
        {getShow() && (
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
        )}
        <div className="fixed inset-0 overflow-hidden">
          <div onClick={toggle} className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={'div'}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                {sliderState.profileSlider.show && <PatientProfileSlider />}
                {sliderState.symptomsSlider.show && <SymptomsSlider />}
                {sliderState.medicalRecordSlider.show && (
                  <MedicalRecordSlider />
                )}
                {sliderState.vitalsSlider.show && <VitalsSlider />}
                {sliderState.noteSlider.show && <NoteSlider />}
                {sliderState.medicineScheduleSlider.show && (
                  <MedicineScheduleSlider />
                )}
                {sliderState.diagnosticSlider.show && <DiagnosticSlider />}
                {sliderState.healthIndicatorSlider.show && (
                  <HealthIndicatorSlider />
                )}
                {sliderState.experienceSlider.show && <ExperienceSlider />}
                {sliderState.educationSlider.show && <EducationSlider />},

              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
