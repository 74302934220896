/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useCallback, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/outline'
import { useAppDispatch, useAppSelector } from '../../../hooks/home'
import { closeAllModals } from '../../../store/slices/modalSlice'
import DiagnosticUpload from './DiagnosticUpload'

export default function Modals() {
  const dispatch = useAppDispatch()
  const modalState = useAppSelector((state) => state.modal)

  const getShow = useCallback(() => {
    return Object.entries(modalState).some(([key, { show }]) => show)
  }, [modalState])

  const toggle = () => {
    dispatch(closeAllModals())
  }


  return (
    <Transition.Root show={getShow()} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={toggle}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={'div'}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
             
              {modalState.diagnosticUpload.show && <DiagnosticUpload />}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
