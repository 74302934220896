import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from '../../utils/axios'

// declaring the types for our state
export type HomeState = {
  loading: boolean
  error: any | null
  data: {
    prescriptionReview: any[]
    reportsReview: any[]
    patientsInQueue: Array<any>
    activePatients: Array<any>
    dashboardData: {
      consulted: Number
      refered: Number
      reviewed: Number
      reportsToReview: Number
    }
  }
}

const initialState: HomeState = {
  error: null,
  data: {
    prescriptionReview: [],
    reportsReview: [],
    patientsInQueue: [],
    activePatients: [],
    dashboardData: {
      consulted: 0,
      refered: 0,
      reviewed: 0,
      reportsToReview: 0,
    },
  },
  loading: true,
}

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions.
  // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
  // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setQueuePatients: (state, action: PayloadAction<any>) => {
      state.data.patientsInQueue = action.payload
    },
    setData: (state, action: PayloadAction<any>) => {
      state.data.dashboardData = action.payload.dashboardData
      state.data.activePatients = action.payload.activePatients
      state.data.reportsReview = action.payload.reportsReview
    },
    setPrescriptionReview: (state, action: PayloadAction<any>) => {
      state.data.prescriptionReview = action.payload
    },
    setError: (state, action: PayloadAction<any>) => {
      state.loading = false
      state.error = action.payload
    },
  },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const {
  setLoading,
  setData,
  setError,
  setQueuePatients,
  setPrescriptionReview,
} = homeSlice.actions

//API Calls
export const getData =
  () => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(setLoading(true))
      const response = await axios.post('/doctor/dashboard')
      const data = response.data.response.data
      const response2 = await axios.get('/prescription?status=Review')
      const data2 = response2.data.response.data
      dispatch(setData(data))
      dispatch(setPrescriptionReview(data2))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setError(JSON.stringify(error)))
      dispatch(setLoading(false))
    }
  }

// exporting the reducer here, as we need to add this to the store
export default homeSlice.reducer
