import * as Yup from 'yup'

export const vitalsInitialState = {
  temperature: '',
  bp: '',
  prbpm: '',
  blood_glucose: '',
  respiratory_rate: '',
  spo2: '',
  hemoglobin: '',
}

export const vitalsvalidationSchema = Yup.object().shape({
  temperature: Yup.string()
    .min(2, 'Too Short!')
    .max(20, 'Too Long!'),
  bp: Yup.string()
    .min(2, 'Too Short!')
    .max(20, 'Too Long!'),
  prbpm: Yup.string()
    .min(2, 'Too Short!')
    .max(20, 'Too Long!'),
  blood_glucose: Yup.string()
    .min(2, 'Too Short!')
    .max(20, 'Too Long!'),
  respiratory_rate: Yup.string()
    .min(2, 'Too Short!')
    .max(20, 'Too Long!'),
  spo2: Yup.string()
    .min(2, 'Too Short!')
    .max(20, 'Too Long!'),
  hemoglobin: Yup.string()
    .min(2, 'Too Short!')
    .max(20, 'Too Long!'),
})
