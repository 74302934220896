import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { format } from 'date-fns'
import React from 'react'
import { useAppDispatch } from '../../../hooks/home'
import { togglePrescriptionSharedModal } from '../../../store/slices/modalSlice'
import PatientFeed from '../shared/PatientFeed'
import { Button, OutlineButton } from '../../buttons/button'
// import SharedPrescriptionSVG from '../../../public/assets/shared-prescription.svg'

export default function PrescriptionShared() {
  const dispatch = useAppDispatch()
  const toggle = () => {
    dispatch(togglePrescriptionSharedModal(null))
  }

  return (
    <Dialog.Panel className="relative h-auto w-screen max-w-lg transform overflow-hidden rounded-lg bg-white p-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
      <div className="flex h-full flex-col text-center">
        <div className="">
          <span className="text-2xl font-semibold">
            Prescription Has Been Shared
          </span>
          {/* <span className="cursor-pointer rounded-full bg-green-400 p-1 text-white">
            <XIcon onClick={toggle} className="h-3 w-3" />
          </span> */}
        </div>
        <div className="mb-12 p-10">
          <img
            src="/assets/shared_prescription.svg"
            className="h-full w-full object-cover"
          />
        </div>
        <div className="mt-auto space-x-4 text-center">
          <OutlineButton className="py-1 px-3">Go to Home Screen</OutlineButton>
          <OutlineButton className="py-1 px-3">
            Continue Consultation
          </OutlineButton>
        </div>
      </div>
    </Dialog.Panel>
  )
}
