import React from 'react'
import Checkbox from '../../../Comp/Checkbox'
import HealthButton from '../../../Comp/HealthButton'
import { format } from 'date-fns'

const SubmissionCard = (
  {
    data,
  }: {
    data: any,
  }
) => {

  console.log(data)

  if (data.submission.oxymeter || data.submission.thermometer || data.submission.medicines) {
    return (
      <div className="w-full rounded-md border-2 bg-white p-3 text-sm font-light">
        <div>
          <p className="font-semibold">SPO2 / Pulse</p>
          <div className="mt-3">
            {data.submission.oxymeter.length > 0 ? data.submission.oxymeter.map((item: any, index: any) => (
              <p key={index}>
                <span>%SpO2</span>
                <span className="ml-2 font-semibold">{item.spo2}%</span>
                <span className="ml-4">Prbpm</span>
                <span className="font-semibold">{item.prbpm}</span>
                <span className="ml-4">{item.date_time ? format(new Date(item.date_time), 'hh:mm a') : ''}</span>
              </p>
            ))
              :
              <p>No Data Found!</p>
            }
          </div>
        </div>

        <div className="mt-4">
          <p className="font-semibold">
            Thermometer <br />
            (range/value)
          </p>
          <div className="mt-3">
            {data.submission.thermometer.length > 0 ? data.submission.thermometer.map((item: any, index: any) => (
              <p key={index}>
                <span>{item.date_time ? format(new Date(item.date_time), 'hh:mm a') : ''}</span>
                <span className="ml-2 font-semibold">{item.value + '°' + item.range}</span>
              </p>
            ))
              :
              <p>No Data Found!</p>
            }
          </div>
        </div>

        <div className="mt-4">
          <p className="font-semibold">Medicine</p>
          {data.submission.medicines.length > 0 ? data.submission.medicines.map((item: any, index: any) => (
            <div className="mt-3 text-xs" key={index}>
              <div className=" flex justify-between">
                <p>
                  <span>{item.time_slot}</span>
                  <span className="ml-2 font-semibold">
                    {item.medicines}
                  </span>
                </p>
                <Checkbox active={item.taken} />
              </div>
            </div>
          ))
            :
            <p>No Data Found!</p>
          }
        </div>


        <div className="mt-5">
          <p className="font-bold">Health Status</p>
          <HealthButton className="mt-1 py-4" children="HEALTHY" />
        </div>
      </div>
    )
  } else {
    return (
      <div className="w-full rounded-md border-2 bg-white p-3 text-sm font-light">
        <p>Not Data Found!</p>
      </div>
    )
  }

}

export default SubmissionCard
