import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import React from 'react'
import { useAppDispatch } from '../../../hooks/home'
import { toggleSignatureModal } from '../../../store/slices/modalSlice'
import { Button, OutlineButton } from '../../buttons/button'
// import SharedPrescriptionSVG from '../../../public/assets/shared-prescription.svg'

export default function SignatureModal() {
  const dispatch = useAppDispatch()
  const toggle = () => {
    dispatch(toggleSignatureModal(null))
  }

  return (
    <Dialog.Panel className="relative h-auto w-screen max-w-lg transform overflow-hidden rounded-lg bg-white p-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
      <div className="flex h-full flex-col space-y-5">
        <div className="flex items-center justify-between">
          <span className="font-semibold">Signature</span>
          <span className="cursor-pointer rounded-full bg-green-400 p-1 text-white">
            <XIcon onClick={toggle} className="h-3 w-3" />
          </span>
        </div>
        <div className="flex items-center justify-center space-x-3">
          <Button>Draw</Button>
          <Button>Upload</Button>
        </div>
        <div className="h-40 rounded-lg border"></div>

        <div className="mt-10 space-x-4 text-center">
          <OutlineButton className="border-green-400 py-1 px-3 uppercase">
            Update
          </OutlineButton>
        </div>
      </div>
    </Dialog.Panel>
  )
}
