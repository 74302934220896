import * as Yup from 'yup'

export const diagnosticInitialState: any = {
  test_name: null,
  remark: '',
  diseases: [],
  otherDiseases: [],
  new_disease: '',
  // course_duration_type: '',
  // course_duration_count: '',
}

export const diagnosticvalidationSchema = Yup.object().shape({
  test_name: Yup.string()
    .required('Test name is required')
    .max(80, 'Test name should not exceed 80 characters')
    .nullable(),
  // course_duration_type: Yup.string().required('Course Duration is required'),
  // course_duration_count: Yup.string().required('Course Duration is required'),
  // diseases: Yup.array()
  //   .of(Yup.string())
  //   .required('Possible Diasease is required'),
  remark: Yup.string()
    .min(2, 'Too Short!')
    .max(60, 'Remark cannot be more than 500 characters'),
})
