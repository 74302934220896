import { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Header from './Header'
import { useRouter } from 'next/router'
import Modals from './modals/Modals'
import NotificationContainer from './shared/NotificationContainer'
import axios from '../../utils/axios'
import SearchMedStore from '../MedSearchBar'
import SearchModal from './SearchModal'
import { setModal } from '../../store/slices/MedstoreSlices/medHomeSlice'
import { useAppDispatch, useAppSelector } from '../../hooks/home'
import DiagnosticSearchModel from './DiagnosticSearchModel'
import ComingSoonModal from '../../store/slices/modalSlice'

export default function MedstoreLayout({
  children,
}: {
  children: React.ReactNode
}) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const { pathname } = useRouter()
  const [medstoreSearchList, setMedstoreSearchList] = useState([])
  const [search, setMedSearch] = useState('')
  const [open, setOpen] = useState(true)
  const [diagnosticList, setDiagnosticList] = useState([])
  const authState = useAppSelector((state) => state.auth)
  console.log("authState", authState)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!search || pathname.includes('/diagnostics')) return
    axios
      .get('doctor/search/patient?name=' + search)
      .then((response) => {
        const data = response.data.response.data
        setMedstoreSearchList(data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [search])

  useEffect(() => {
    if (!search || !pathname.includes('/diagnostics')) return
    axios
      .patch('testsByFilter',
        {
          "city": authState?.user?.city,
          "category": "",
          "testName": search
        })
      .then((response) => {
        const data = response.data.response.data
        console.log("diagnostic", data)
        setDiagnosticList(data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [search])

  useEffect(() => {
    if (search) {
      dispatch(setModal(true))
    }

  }, [search])

  useEffect(() => {
    setMedSearch('')
  }, [pathname])

  return (
    <>
      <div className="flex h-screen flex-col">
        {!pathname.includes('/auth/') && (
          <Header
            search={search}
            setMedSearch={setMedSearch}
            mobileMenuOpen={mobileMenuOpen}
            setMobileMenuOpen={setMobileMenuOpen}
          />
        )}

        {/* Hide Header */}
        <div className="flex flex-1 flex-row overflow-hidden">
          {/* Hide sidebar */}
          {!pathname.includes('/auth/') && (
            <Sidebar
              mobileMenuOpen={mobileMenuOpen}
              setMobileMenuOpen={setMobileMenuOpen}
            />
          )}

          {/* Main content */}
          <div className="flex flex-1 items-stretch overflow-hidden">
            <main className="flex-1 overflow-y-auto">
              {/* Primary column */}
              <section
                aria-labelledby="primary-heading"
                className="flex h-full min-w-0 flex-1 flex-col lg:order-last"
              >
                {
                  children
                }
                {search && !pathname.includes('/diagnostics') &&

                  <SearchModal {...{ open, setOpen }} setSearch={setMedSearch} search={search} medstoreSearchList={medstoreSearchList} />

                }
                {search && pathname.includes('/diagnostics') &&
                <DiagnosticSearchModel {...{ open, setOpen }} setSearch={setMedSearch} search={search} diagnosticList={diagnosticList} />
                }
              </section>
            </main>
          </div>
        </div>
        {/* <Slider /> */}
        <Modals />
        <NotificationContainer />
      </div>
    </>
  )
}

