import React, { useEffect } from 'react'
import { Dialog } from '@headlessui/react'
import SliderHeader from '../SliderHeader'
import { XIcon } from '@heroicons/react/solid'
import { useAppDispatch, useAppSelector } from '../../../hooks/home'
import { toggleMedicalRecordSlider } from '../../../store/slices/sliderSlice'
import { Button } from '../../buttons/button'
import { useFormik } from 'formik'
import {
  medRecordInitialState,
  medRecordvalidationSchema,
} from '../../../functionality/medicalRecord'
import Input from '../../../Comp/Input'
import FormErrorMessage from '../shared/FormErrorMessage'
import LabelTag from '../shared/LabelTag'
import { updateMedicalRecord } from '../../../store/slices/prescriptionSlice'

const MedicalRecordSlider = () => {
  const dispatch = useAppDispatch()
  const prescription = useAppSelector(
    (state) => state.prescription.selectedPrescription
  )

  const toggle = () => {
    dispatch(toggleMedicalRecordSlider(null))
  }

  const formik = useFormik({
    initialValues: medRecordInitialState,
    validationSchema: medRecordvalidationSchema,
    onSubmit: async (values) => {
      console.log('values', values)
      await dispatch(
        updateMedicalRecord({
          existing: values.existing,
          allergy: values.allergy,
          surgical_history: values.surgical_history,
          disease_note: values.disease_note,
        })
      )
      toggle()
    },
  })

  useEffect(() => {
    if (!prescription) return
    //Set Field Values
    formik.setFieldValue(
      'existing',
      prescription.patientId?.medicalRecords?.existing || []
    )
    formik.setFieldValue(
      'allergy',
      prescription.patientId?.medicalRecords?.allergy || []
    )
    formik.setFieldValue(
      'surgical_history',
      prescription.patientId?.medicalRecords?.surgical_history || []
    )
    formik.setFieldValue(
      'disease_note',
      prescription.patientId?.medicalRecords?.disease_note || []
    )
  }, [])

  const handleDelete = (key: string, value: string) => {
    formik.setFieldValue(
      key,
      formik.values[key].filter((s: string) => s !== value)
    )
  }

  const handleNewItem = (key: string, array_key: string) => {
    if (!formik.values[key]) {
      return formik.setFieldError(key, 'Please enter a value')
    }
    if (formik.values[array_key].includes(formik.values[key])) {
      return formik.setFieldError(key, 'Please enter a unique value')
    }
    formik.setFieldValue(array_key, [
      ...formik.values[array_key],
      formik.values[key],
    ])
    formik.setFieldValue(key, '')
  }

  return (
    <Dialog.Panel className="pointer-events-auto h-full w-screen max-w-lg">
      <div className="flex h-full flex-col justify-center overflow-y-scroll bg-gray-100 py-6 shadow-xl">
        {/* <SliderHeader onClose={() => {}} title={undefined} /> */}
        <div className="mt-6 flex flex-col space-y-2 px-4 sm:px-6">
          <div className="flex items-center justify-between">
            <span className="text-2xl font-semibold">Medical Record:</span>
            <span className="cursor-pointer rounded-full bg-green-400 p-1 text-white">
              <XIcon onClick={toggle} className="h-3 w-3" />
            </span>
          </div>

          <div className="flex flex-col space-y-1">
            <span className="text-sm">Existing Disease:</span>
            <div className="flex space-x-2 ">
              {formik.values.existing.map((key: string) => (
                <LabelTag
                  onClick={() => handleDelete('existing', key)}
                  key={key}
                >
                  {key}
                </LabelTag>
              ))}
            </div>
            <div className="flex items-center">
              <Input
                name="existing_text"
                value={formik.values.existing_text}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className="mr-3"
              />
              <Button
                className="px-2"
                onClick={() => handleNewItem('existing_text', 'existing')}
              >
                ADD
              </Button>
            </div>
            <FormErrorMessage
              touched={formik.touched.existing_text}
              error={formik.errors.existing_text}
            />
          </div>
          <div className="flex flex-col space-y-1">
            <span className="text-sm">Allergy:</span>
            <div className="flex space-x-2 ">
              {formik.values.allergy.map((key: string) => (
                <LabelTag
                  onClick={() => handleDelete('allergy', key)}
                  key={key}
                >
                  {key}
                </LabelTag>
              ))}
            </div>
            <FormErrorMessage touched={formik.touched.allergy} error={formik.errors.allergy} />
            <div className="flex items-center">
              <Input
                name="allergy_text"
                value={formik.values.allergy_text}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className="mr-3"
              />
              <Button
                className="px-2"
                onClick={() => handleNewItem('allergy_text', 'allergy')}
              >
                ADD
              </Button>
            </div>
            <FormErrorMessage
              touched={formik.touched.allergy_text}
              error={formik.errors.allergy_text}
            />
          </div>
          <div className="flex flex-col space-y-1">
            <span className="text-sm">Past Surgical History:</span>
            <div className="flex space-x-2 ">
              {formik.values.surgical_history.map((key: string) => (
                <LabelTag
                  onClick={() => handleDelete('surgical_history', key)}
                  key={key}
                >
                  {key}
                </LabelTag>
              ))}
            </div>
            <div className="flex items-center">
              <Input
                name="surgical_history_text"
                value={formik.values.surgical_history_text}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className="mr-3"
              />
              <Button
                className="px-2"
                onClick={() =>
                  handleNewItem('surgical_history_text', 'surgical_history')
                }
              >
                ADD
              </Button>
            </div>
            <FormErrorMessage
              touched={formik.touched.surgical_history_text}
              error={formik.errors.surgical_history_text}
            />
          </div>
          <div className="flex flex-col space-y-1">
            <span className="text-sm">Disease Note:</span>
            <div className="flex space-x-2 ">
              {formik.values.disease_note.map((key: string) => (
                <LabelTag
                  onClick={() => handleDelete('disease_note', key)}
                  key={key}
                >
                  {key}
                </LabelTag>
              ))}
            </div>
            <div className="flex items-center">
              <Input
                name="disease_note_text"
                value={formik.values.disease_note_text}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className="mr-3"
              />
              <Button
                className="px-2"
                onClick={() =>
                  handleNewItem('disease_note_text', 'disease_note')
                }
              >
                ADD
              </Button>
            </div>
            <FormErrorMessage
              touched={formik.touched.disease_note_text}
              error={formik.errors.disease_note_text}
            />
          </div>

          <Button
            disabled={formik.isSubmitting}
            onClick={formik.handleSubmit}
            className="mx-auto mt-6 w-40 text-lg"
          >
            {formik.isSubmitting ? 'Saving...' : 'Save'}
          </Button>
        </div>
      </div>
    </Dialog.Panel>
  )
}

export default MedicalRecordSlider
