import { FormikConfig } from 'formik'
import * as Yup from 'yup'

export const symptomsInitialState: any = {
  symptoms: [],
  otherSymptoms: [],
  new_symptom: '',
  duration: '',
  frequency: '',
}

export const symptomsvalidationSchema = Yup.object().shape({
  new_symptom: Yup.string().max(100, 'Symptom can be at most 100 characters'),
  duration: Yup.string().max(100, 'Duration can be at most 100 characters'),
  frequency: Yup.string().max(100, 'Frequency can be at most 100 characters'),
  // symptoms: Yup.array().of(
  //   Yup.object().shape({
  //     key: Yup.string().required('Symptom key is required'),
  //     name: Yup.string().required('Symptom name is required'),
  //   })
  // ),
})
