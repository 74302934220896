import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { store } from '../..'
import { paymentValidationSchema } from '../../../components/client/orders/schema/paymentModal'
import axios from '../../../utils/axios'
import { notifier } from '../notificationSlice'

// declaring the types for our state
export type ClientAppointmentState = {
    loading: boolean
    error: any | null
    data: {
        booked: {
            data: any[]
            total: any
            current: any
        }
        cancelled: {
            data: any[]
            total: any
            current: any
        }
        completed: {
            data: any[]
            total: any
            current: any
        }
        total: any
        current: any
    },
    memberData: any | null,
    testData: any | null,
    clinicData: any | null,
    searchData: any | null,
    testSelectedId: any | null
}

const initialState: ClientAppointmentState = {
    error: null,
    loading: true,
    data: {
        booked: {
            data: [],
            total: 0,
            current: 0
        },
        cancelled: {
            data: [],
            total: 0,
            current: 0
        },
        completed: {
            data: [],
            total: 0,
            current: 0
        },
        total: 0,
        current: 0
    },
    memberData: null,
    testData: [],
    clinicData: null,
    searchData: null,
    testSelectedId: []
}

export const clientAppointmentSlice = createSlice({
    name: 'clientappointment',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions.
    // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
    // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setBookedData: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data.booked.data = action.payload
        },
        setBookedCurrent: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data.booked.current = action.payload
        },
        setBookedTotal: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data.booked.total = action.payload
        },
        setCompletedData: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data.completed.data = action.payload
        },
        setCompletedTotal: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data.completed.total = action.payload
        },
        setCompletedCurrent: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data.completed.current = action.payload
        },
        setCancelledData: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data.cancelled.data = action.payload
        },
        setCancelledTotal: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data.cancelled.total = action.payload
        },
        setCancelledCurrent: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data.cancelled.current = action.payload
        },
        setError: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.error = action.payload
        },
        setMemberData: (state, action: PayloadAction<any>) => {
            state.memberData = action.payload
        },
        setTestData: (state, action: PayloadAction<any>) => {
            state.testData = action.payload
        },
        setClinicData: (state, action: PayloadAction<any>) => {
            state.clinicData = action.payload
        },
        setSearchData: (state, action: PayloadAction<any>) => {
            state.searchData = action.payload
        },
        setTestSelectedId: (state,action: PayloadAction<any>)=>{
            state.testSelectedId = action.payload
        }
    },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { setLoading, setBookedData, setCompletedData, setCancelledData, setError,
    setMemberData, setTestData, setClinicData,
    setBookedCurrent, setBookedTotal, setCancelledCurrent, setCancelledTotal, setCompletedCurrent, setCompletedTotal,setTestSelectedId } =
    clientAppointmentSlice.actions

//API Calls

export const getAppointmentList =
    (val: any) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                const response = await axios.get(`appointmentByClinic/paginate?page=${val}&limit=10`)
                const data = response.data.response.data
                const bookedData = data.booked
                const cancelledData = data.cancelled
                const completedData = data.completed
                dispatch(setBookedData(bookedData.docs))
                dispatch(setBookedCurrent(bookedData.page))
                dispatch(setBookedTotal(bookedData.totalPages))
                dispatch(setCompletedData(completedData.docs))
                dispatch(setCompletedCurrent(completedData.page))
                dispatch(setCompletedTotal(completedData.totalPages))
                dispatch(setCancelledData(cancelledData.docs))
                dispatch(setCancelledCurrent(cancelledData.page))
                dispatch(setCancelledTotal(cancelledData.totalPages))
            } catch (error) {
                dispatch(setError(error))
            }
        }

export const updateUserDetails = (values: any, address: any) => async (dispatch) => {
    try {
        const res = await axios.post("/users/ByNurse", values)
        const data = res.data.response.data
        data.addressData = address
        dispatch(setMemberData(data))
        // notifier.success('User Updated Successfully')
    } catch (err) {
        console.error(err)
    }
}

export const updateUserAddressDetails = (values: any) => async (dispatch) => {
    try {
        console.log(values, 'asdd')
        dispatch(setMemberData(values))
    } catch (err) {
        console.error(err)
    }
}

export const bookAppointment = (values: any) => async (dispatch) => {
    try {
        const res = await axios.post("/appointmentByClinic", values)
        const data = res.data.response.data
        const booked = store.getState().clientAppointment.data.booked
        // @ts-ignore
        dispatch(setBookedData([data, ...booked]))
        notifier.success('Appointment Booked Successfully ! \n Please make the payment through the link sent to your mobile number')
        // this.props.history.push('/appointment')
    } catch (err) {
        console.error(err)
    }

}

//-----------------------------------------AppointMent Search Detail Call---------------------------------------------------- 
export const getAppointMentSearchDetail =
    (type: any, id: any) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                let response = await axios.get(`appointmentByClinic/paginate?page=1&limit=10&order_id=${id}`)
                const data = response.data.response.data
                if (type == "booked") {
                    const bookedData = data?.booked?.docs
                    dispatch(setBookedData(bookedData))
                }

                if (type == "completed") {
                    const completedData = data?.completed?.docs
                    dispatch(setCompletedData(completedData))
                }

                if (type == "cancelled") {
                    const cancelledData = data?.cancelled?.docs
                    dispatch(setCancelledData(cancelledData))
                }

            } catch (error) {
                dispatch(setError(error))
            }
        }



// exporting the reducer here, as we need to add this to the store
export default clientAppointmentSlice.reducer
