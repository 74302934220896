import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from '../../../utils/axios'

// declaring the types for our state
export type ClientMemberState = {
    loading: boolean
    error: any | null
    data: any | null
    current: any
    total: any
    oneMemberData: any | null

}

const initialState: ClientMemberState = {
    error: null,
    loading: true,
    data: [],
    current: 0,
    total: 0,
    oneMemberData: []
}

export const clientMemberSlice = createSlice({
    name: 'clientmember',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions.
    // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
    // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setData: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data = action.payload
        },
        setCurrent: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.current = action.payload
        },
        setTotal: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.total = action.payload
        },
        setError: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.error = action.payload
        },
        setOneMemberData: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.oneMemberData = action.payload
        },
    },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { setLoading, setData, setCurrent, setTotal, setError, setOneMemberData } =
    clientMemberSlice.actions

//API Calls

export const getMemberList =
    (page: any) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                const response = await axios.get(`orgMembers/paginate?page=${page}&limit=10`)
                const data = response.data.response.data
                dispatch(setData(data.docs))
                dispatch(setTotal(data.totalPages))
                dispatch(setCurrent(data.page))
            } catch (error) {
                dispatch(setError(error))
            }
        }



export const getOneMemberData =
    (id: string) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                const response = await axios.get(`/orgMembers/${id}`)
                const data = response.data.response.data
                dispatch(setOneMemberData(data))
            } catch (error) {
                dispatch(setError(error))
            }
        }



export const saveMemberData =
    (values: any) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                await axios.post(`/orgMembers`, values)
                const response = await axios.get(`orgMembers`)
                const data = response.data.response.data
                dispatch(setData(data))
            } catch (error) {
                dispatch(setError(error))
            }
        }

export const updateMemberData =
    (id: any, values: any) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                await axios.patch(`/orgMembers/${id}`, values)
                const response = await axios.get(`orgMembers`)
                const data = response.data.response.data
                dispatch(setData(data))
            } catch (error) {
                dispatch(setError(error))
            }
        }
//----------------------------------------Api call for Search Member---------------------------------------------------- 
export const getMemberSearchDetail =
    (Query: any) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                let response = await axios.get(`orgMembers/paginate?page=1&limit=10&firstName=${Query}`)
                const data = response.data.response.data?.docs
                dispatch(setData(data))
            } catch (error) {
                dispatch(setError(error))
            }
        }


// exporting the reducer here, as we need to add this to the store
export default clientMemberSlice.reducer
