import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'

import authReducer from './slices/authSlice'
import homeReducer from './slices/homeSlice'
import patientReducer from './slices/patientSlice'
import prescriptionSlice from './slices/prescriptionSlice'
import sliderSlice from './slices/sliderSlice'
import modalSlice from './slices/modalSlice'
import notificationSlice from './slices/notificationSlice'
import reportSlice from './slices/reportSlice'
import utilSlice from './slices/utilSlice'
import docProfileSlice from './slices/docProfileSlice'
import rechargeSlice from './slices/rechargeSlice'
import medHomeSlice from './slices/MedstoreSlices/medHomeSlice'
import medProfileSlice from './slices/MedstoreSlices/medProfileSlice'
import medOrderStatusSlice from './slices/MedstoreSlices/medOrderStatusSlice'
import medTestSlice from './slices/MedstoreSlices/medTestSlice'
import clientProductSlice from './slices/ClientSlices/productsSlice'
import clientOrderSlice from './slices/ClientSlices/ordersSlice'
import clientAccountSlice from './slices/ClientSlices/accountsSlice'
import clientUserSlice from './slices/ClientSlices/usersSlice'
import clientAppointmentSlice from './slices/ClientSlices/appointmentsSlice'
import clientPackageSlice from './slices/ClientSlices/packageSlice'
import clientMemberSlice from './slices/ClientSlices/memberSlice'
import clientClinicSlice from './slices/ClientSlices/clinicSlice'
import medDiagnosticSlice from './slices/MedstoreSlices/medDiagnosticSlice'
import clientdiagnostic from './slices/ClientSlices/clientDiagnosticSlice'
import medRefferalSlice from './slices/MedstoreSlices/medRefferalSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    home: homeReducer,
    patient: patientReducer,
    slider: sliderSlice,
    modal: modalSlice,
    notification: notificationSlice,
    prescription: prescriptionSlice,
    report: reportSlice,
    util: utilSlice,
    docProfile: docProfileSlice,
    medHome: medHomeSlice,
    medProfile: medProfileSlice,
    medOrderStatus: medOrderStatusSlice,
    medTest: medTestSlice,
    medDiagnostic: medDiagnosticSlice,
    clientDiagnostic: clientdiagnostic,
    clientProduct: clientProductSlice,
    clientOrder: clientOrderSlice,
    clientAccount: clientAccountSlice,
    clientUser: clientUserSlice,
    clientAppointment: clientAppointmentSlice,
    clientPackage: clientPackageSlice,
    clientMember: clientMemberSlice,
    clientClinic: clientClinicSlice,
    recharge: rechargeSlice,
    medRefferal: medRefferalSlice
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
