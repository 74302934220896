/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon, ThumbUpIcon, UserIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { format } from 'date-fns'
import React, { Fragment, useState } from 'react'
import { useAppDispatch } from '../../../hooks/home'
import {
  togglePdfModal,
  toggleSubmissionModal,
} from '../../../store/slices/modalSlice'
import { notifier } from '../../../store/slices/notificationSlice'
import { deactivatePrescription } from '../../../store/slices/prescriptionSlice'
import { indicator_options, symptom_list } from '../../../utils/constants'
import { Button } from '../../buttons/button'
import MediaShared from './MediaShared'

const timeline = [
  {
    id: 1,
    content: 'Applied to',
    target: 'Front End Developer',
    href: '#',
    date: 'Sep 20',
    datetime: '2020-09-20',
    icon: UserIcon,
    iconBackground: 'bg-gray-400',
  },
  {
    id: 2,
    content: 'Advanced to phone screening by',
    target: 'Bethany Blake',
    href: '#',
    date: 'Sep 22',
    datetime: '2020-09-22',
    icon: ThumbUpIcon,
    iconBackground: 'bg-blue-500',
  },
  {
    id: 3,
    content: 'Completed phone screening with',
    target: 'Martha Gardner',
    href: '#',
    date: 'Sep 28',
    datetime: '2020-09-28',
    icon: CheckIcon,
    iconBackground: 'bg-green-500',
  },
  {
    id: 4,
    content: 'Advanced to interview by',
    target: 'Bethany Blake',
    href: '#',
    date: 'Sep 30',
    datetime: '2020-09-30',
    icon: ThumbUpIcon,
    iconBackground: 'bg-blue-500',
  },
  {
    id: 5,
    content: 'Completed interview with',
    target: 'Katherine Snyder',
    href: '#',
    date: 'Oct 4',
    datetime: '2020-10-04',
    icon: CheckIcon,
    iconBackground: 'bg-green-500',
  },
]

export default function PatientFeed({
  prescriptionHistory,
}: {
  prescriptionHistory: any[]
}) {
  const dispatch = useAppDispatch()
  const [media, setMedia] = useState(null)

  const toggle = (type: string, data?: any) => {
    switch (type) {
      case 'submission':
        dispatch(toggleSubmissionModal(data))
        break;
      case 'prescription':
        dispatch(togglePdfModal(data))
        break;
      case 'attachment':
        dispatch(togglePdfModal(data))
        break;
      default:
        break
    }
  }



  return (
    <div className="mt-4 flow-root h-full  px-4">
      <ul role="list" className="-mb-8">
        {prescriptionHistory.map((event, eventIdx) => (
          <li key={event._id}>
            <div className="relative pb-8">
              {eventIdx !== timeline.length - 1 ? (
                <span
                  className="absolute top-4 left-6 -ml-px h-full w-0.5 bg-gray-200"
                  aria-hidden="true"
                />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  <span
                    className={classNames(
                      'bg-midnight text-center text-white',
                      'block h-12 w-12 rounded-md p-1 align-middle text-xs ring-8 ring-white'
                    )}
                  >
                    {event.createdAt ? format(new Date(event.createdAt), 'dd') : ''}
                    <br />
                    {event.createdAt ? format(new Date(event.createdAt), 'MMM') : ''}
                  </span>
                </div>
                <div className="flex flex-col md:flex-row h-auto w-full justify-between overflow-hidden rounded-lg bg-white p-2 pr-12 shadow-lg">
                  <div className="flex flex-col md:space-x-3">
                    <div className="text-sm font-semibold">
                      Doctor : {event.doctorId?.firstName} |{' '}
                      {event.createdAt ? format(new Date(event.createdAt), 'hh:mm a') : ''}
                    </div>
                    <table className="mt-2 w-fit px-3 text-sm">
                      <tbody>
                        <tr>
                          <td className="pr-3">Symptoms:</td>
                          <td className="pr-3 notesLineClamp">
                            {/* {Object.keys(event.symptomsId || {})
                              .filter((key) => !!symptom_list[key])
                              .map((key) => symptom_list[key])
                              .join(', ')} */}
                            {
                              event.hopi_symptoms.map((item) =>
                                (item.name)).join(', ')

                            }
                          </td>
                        </tr>
                        <tr>
                          <td className="pr-3">Consultation Disease:</td>
                          <td className="pr-3 notesLineClamp">
                            {event.diagnosis?.text || ''}
                          </td>
                        </tr>
                        <tr>
                          <td className="pr-3">Doctor Note:</td>
                          <td className="pr-3 notesLineClamp">
                            {event.notes
                              .map(({ text }: { text: string }) => text)
                              .join(', ')}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="mt-3 text-sm font-semibold">Vitals</div>
                    <div className="flex space-x-3 flex-wrap">
                      {Object.entries(event?.vitals?.doctor || {})
                        .filter(([key, value]) => !!value)
                        .map(([key, value]: any) => (
                          <span
                            key={key}
                            className="flex flex-col items-center"
                          >
                            <span>
                              {
                                indicator_options.find((i) => i.value === key)
                                  ?.label
                              }
                            </span>
                            <span>{value}</span>
                          </span>
                        ))}
                    </div>

                    <div className="mt-3 text-sm font-semibold">Notes</div>
                    <div className="mt-1 text-sm font-light notesLineClamp">
                      {/* {event.notes
                        .map(({ text }: { text: string }) => text)
                        .join('\n')} */}
                      {
                        event.followup_notes
                      }
                    </div>
                  </div>
                  <div className="flex h-full flex-shrink flex-col justify-center space-y-2">
                    <div className="flex flex-col space-y-1">
                      <span className="text-bold">Prescription</span>
                      <Button
                        onClick={() => toggle('prescription', event.prescription_pdf)}
                        className="rounded-md py-1 px-16"
                      >
                        Open
                      </Button>
                    </div>
                    <div className="flex flex-col space-y-1">
                      <span className="text-bold">Submission</span>
                      <Button
                        onClick={() => toggle('submission', event)}
                        className="rounded-md py-1 px-16"
                      >
                        Open
                      </Button>
                    </div>
                    {!!event?.filesShared?.length && <div className="flex flex-col space-y-1">
                      <span className="text-bold">Attachements</span>
                      <Button
                        onClick={() => toggle('attachment', event.filesShared)}
                        className="rounded-md py-1 px-16"
                      >
                        Open
                      </Button>
                    </div>}

                    {!!event?.mediaShared?.length &&
                      <div className="flex flex-col space-y-1">
                        <span className="text-bold">Media Files</span>
                        <Button
                          onClick={() => setMedia(event)}
                          className="rounded-md py-1 px-16"
                        >
                          Open
                        </Button>
                      </div>
                    }
                  </div>

                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
      {!!media
        &&
        <div className='h-screen absolute inset-0 bg-black bg-opacity-50' onClick={() => setMedia(null)}>
          <MediaShared prescription={media} />
        </div>
      }

    </div>
  )
}
