import * as Yup from 'yup'

export const noteInitialState = {
  text: '',
}

export const notevalidationSchema = Yup.object().shape({
  text: Yup.string()
    .required('User Note is required')
    .min(2, 'Too Short!')
    .max(500, 'Max 500 chars!'),
})
