import React from 'react'
import classNames from 'classnames'
const Avatar = ({
  children,
  className,
  onClick = () => {},
  size,
}: {
  children: React.ReactNode
  className?: string
  onClick?: () => void
  size?: string
}) => {
  return (
    <div
      onClick={onClick}
      className={classNames(
        className,
        size === 'sm'
          ? 'h-6 w-6'
          : size === 'md'
          ? 'h-14 w-14'
          : size === 'lg'
          ? 'h-20 w-20'
          : 'h-10 w-10',

        'flex h-10 w-10 items-center justify-center overflow-hidden rounded-full bg-[#54B2E533] text-blueColor'
      )}
    >
      {children}
    </div>
  )
}

export default Avatar
