import { FormikErrors, FormikTouched } from 'formik'
import React from 'react'

const FormErrorMessage = ({
  error,
  touched,
}: {
  error: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined
  touched: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined
}) => {
  if (!error || !touched) {
    return <></>
  }

  return (
    <div className=''>
      {error && touched && (
        <div role="alert" className="text-red-500">
          {error}
        </div>
      )}
    </div>
  )
}
export default FormErrorMessage
