import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from '../../../utils/axios'

// declaring the types for our state
export type ClientUserState = {
    loading: boolean
    error: any | null
    data: any | null
    total: any
    current: any
    userData: any | null

}

const initialState: ClientUserState = {
    error: null,
    loading: true,
    total: 0,
    current: 0,
    data: [],
    userData: [],
}

export const clientUserSlice = createSlice({
    name: 'clientuser',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions.
    // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
    // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setData: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data = action.payload
        },
        setTotal: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.total = action.payload
        },
        setCurrent: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.current = action.payload
        },
        setError: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.error = action.payload
        },
        setOneUserData: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.userData = action.payload
        },

    },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { setLoading, setData, setOneUserData, setCurrent, setTotal, setError } =
    clientUserSlice.actions

//API Calls

export const getUserList =
    (value: any) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                const response = await axios.get(`patients-list/paginate?limit=10&page=${value}`)
                const data = response.data.response.data?.docs
                const current = response.data.response.data.page
                const total = response.data.response.data.totalPages
                dispatch(setData(data))
                dispatch(setTotal(total))
                dispatch(setCurrent(current))
            } catch (error) {
                dispatch(setError(error))
            }
        }


export const getOneUserDetail =
    (userId: any, testId: any) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                let response = await axios.get(`/patients-list/${userId}/${testId}`)
                const data = response.data.response.data
                dispatch(setOneUserData(data))
            } catch (error) {
                dispatch(setError(error))
            }
        }
//------------------Api Call For Get Search User Detail----------------
export const getUserSearchDetail =
    (type: string, Query: any, secondType: string, secQuery: any) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                let response = await axios.get(`patients-list/paginate?${type}=${Query}&${secondType}=${secQuery}&limit=10&page=1`)
                const data = response.data.response.data?.docs
                dispatch(setData(data))
            } catch (error) {
                dispatch(setError(error))
            }
        }





// exporting the reducer here, as we need to add this to the store
export default clientUserSlice.reducer
