import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import { Button } from 'antd'
import classNames from 'classnames'
import { format } from 'date-fns'
import React, { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/home'
import SocketService from '../../../services/socketService'
import { updatePrescription } from '../../../store/slices/prescriptionSlice'
import { toggleDocReferModal } from '../../../store/slices/modalSlice'
import { notifier } from '../../../store/slices/notificationSlice'
import axios from '../../../utils/axios'
import emitter from '../../../services/emitterService'
import * as Sentry from "@sentry/browser"

export default function DocReferModal() {
  const dispatch = useAppDispatch()
  const modalState = useAppSelector((state) => state.modal.docReferModal)
  const type: 'review' | 'refer' = modalState.data
  const open = modalState.show
  const prescription = useAppSelector(
    (state) => state.prescription.selectedPrescription
  )

  //Doc List
  const [loading, setloading] = useState(true)
  const [data, setData] = useState([])

  const toggle = () => {
    dispatch(toggleDocReferModal(null))
  }

  useEffect(() => {
    setloading(true)
    ;(async () => {
      try {
        const response = await axios.get(`/docReferList`)
        let doctorList = response.data.response.data
        setData(doctorList.filter((e: { doctorId: any }) => !!e.doctorId))
        setloading(false)
      } catch (err) {
        setloading(false)
        console.error(err)
      }
    })()
  }, [])

  const referConsultation = useCallback(
    async (doctorId) => {
      try {
        Sentry.captureMessage(`Refer Prescription - ${prescription._id}`)
        await dispatch(
          updatePrescription(prescription._id, {
            doctorId: doctorId._id,
            status: 'Referred',
            isVideoConsultationDone: false,
            consultationStarted: false,
          })
        )
        toggle()
        notifier.success('Referred Sucessfully')
        let socket = SocketService.socket
        socket.emit('refer', doctorId._id)
        //Redirect to Dashboard
        window.location.href = '/platform/dashboard'
      } catch (e) {
        console.log(e)
      }
    },
    [prescription]
  )

  const reviewConsultation = useCallback(
    async (doctorId) => {
      try {
        //End Call
        emitter.emit('endCall')
        await dispatch(
          updatePrescription(prescription._id, {
            doctorId: doctorId._id,
            status: 'Review',
            isVideoConsultationDone: true,
          })
        )
        toggle()
        notifier.success('Prescription Set For Review')
        location.pathname = '/platform/dashboard'
      } catch (e) {
        console.log(e)
      }
    },
    [prescription]
  )

  const onSubmit = useCallback(
    (doctorId) => {
      type === 'review'
        ? reviewConsultation(doctorId)
        : referConsultation(doctorId)
    },
    [type]
  )

  return (
    // <ModalContainer open={open} onClose={toggle}>
    <Dialog.Panel className="relative w-screen max-w-lg transform overflow-hidden rounded-lg bg-white p-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6 z-30">
      <div className="flex flex-col">
        <div className="flex items-center justify-between">
          <span className="font-semibold">Doctor Refer List:</span>
          <span className="cursor-pointer rounded-full bg-green-400 p-1 text-white">
            <XIcon onClick={toggle} className="h-3 w-3" />
          </span>
        </div>
        <div className="flex-grow p-0">
          {loading ? (
            <div className="text-center">
              <h3>Loading</h3>
            </div>
          ) : !data.length ? (
            <div className="text-center">
              <h3>No data</h3>
            </div>
          ) : (
            <div className="flex flex-col">
              {data.map((doctor: any, i) => (
                <div
                  onClick={() => onSubmit(doctor.doctorId)}
                  key={i}
                  className={classNames(
                    'text-blueColor hover:bg-cyan-200',
                    'flex cursor-pointer flex-row items-stretch rounded-md  p-3'
                  )}
                >
                  <div
                    className={classNames(
                      'bg-cyan-100',
                      'mr-3 inline-flex h-8 w-8 items-center justify-center rounded-full p-6 shadow'
                    )}
                  >
                    {doctor.doctorId.firstName?.slice(0, 1)}
                    {doctor.doctorId.lastName?.slice(0, 1)}
                  </div>

                  <div className="flex flex-col">
                    <span className="font-semibold">
                      {doctor.doctorId.firstName}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </Dialog.Panel>
    // </ModalContainer>
  )
}
