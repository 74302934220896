import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from '../../../utils/axios'
import { useAppSelector } from '../../../hooks/home'

export type ClientAccountState = {
    loading: boolean
    error: any | null
    data: any | null

}

const initialState: ClientAccountState = {
    error: null,
    loading: true,
    data: {}
}

export const clientAccountSlice = createSlice({
    name: 'clientaccount',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions.
    // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
    // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setData: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data = action.payload
        },
        setError: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.error = action.payload
        }
    },
})

export const { setLoading, setData, setError } =
    clientAccountSlice.actions
//-----------------------------------------Get Account List---------------------------------------------------- 
export const getAccountList =
    (id: string) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                const response = await axios.get(`orgs/${id}`)
                const data = response.data.response.data
                dispatch(setData(data))
            } catch (error) {
                dispatch(setError(error))
            }
        }
//-----------------------------------------Update Account Data---------------------------------------------------- 
export const updateAccountData =
    (id: string, data: any) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                const res = await axios.patch(`/orgs/${id}`, data)
                const data1 = res.data.response.msg
            } catch (error) {
                dispatch(setError(error))
            }
        }


// exporting the reducer here, as we need to add this to the store
export default clientAccountSlice.reducer
