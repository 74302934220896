import {
  HomeIcon,
  ViewGridIcon,
  PhotographIcon,
  UserGroupIcon,
  PhoneIcon,
  PencilIcon,
  CurrencyRupeeIcon
} from '@heroicons/react/outline'

// import {faPati} from "@fortawesome/free-solid-svg-icons"

export const sidebarNavigation = [
  { name: 'Home', icon: HomeIcon, href: '/platform/dashboard' },
  {
    name: 'Prescription',

    icon: ViewGridIcon,
    href: '/platform/prescriptions',
  },
  {
    name: 'Report',

    icon: PhotographIcon,
    href: '/platform/reports',
  },
  {
    name: 'Patients',

    icon: UserGroupIcon,
    href: '/platform/patients',
  },
  { name: 'Camp', icon: HomeIcon, href: '/platform/camp' },
]

export const externalSidebarNavigation = [
  // { name: 'Home', icon: HomeIcon, href: '/platform/dashboard' },
  { name: 'Write Prescription', icon: PencilIcon, href: '/platform/writepres' },
  // {
  //   name: 'Prescription',

  //   icon: ViewGridIcon,
  //   href: '/platform/prescriptions',
  // },
  {
    name: 'Report',

    icon: PhotographIcon,
    href: '/platform/reports',
  },
  {
    name: 'Patients',

    icon: UserGroupIcon,
    href: '/platform/patients',
  },
  {
    name: 'Recharge',

    icon: CurrencyRupeeIcon,
    href: '/platform/recharge',
  }
]

export const navigation = [
  {
    name: 'Inboxes',

    children: [
      { name: 'Technical Support' },
      { name: 'Sales' },
      { name: 'General' },
    ],
  },
  { name: 'Reporting', children: [] },
  { name: 'Settings', children: [] },
]

export const frequency = [
  { name: 'Every Hour', value: 24 },
  { name: 'Once in a Day', value: 1 },
  { name: '2 times a day', value: 2 },
  { name: '3 times a day', value: 3 },
  { name: '4 times a day', value: 4 },
  { name: 'SOS', value: 'SOS' },
]

export const interval = [
  'Daily',
  'Alternate Day',
  'Once a Week',
  'Once in 2 Week',
  'Twice in a Week',
  'Monthly',
]

export const time_slots = [6, 8, 10, 12, 14, 16, 18, 20, 22]

export const medicineTypeList = [
  'Tab',
  'Capsule',
  'Lotion',
  'Syrup',
  'Ointment',
  'Injection',
  'Lozenges',
  'Satchet',
]
