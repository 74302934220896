import React from 'react'
import classNames from 'classnames'
import Avatar from '../shared/Avatar'
import { format } from 'date-fns'
import Router, { useRouter } from 'next/router'
import { setSelectedPatient } from '../../../store/slices/patientSlice'
import { useAppDispatch } from '../../../hooks/home'
import { setModal } from '../../../store/slices/patientSlice'
import { togglePdfModal } from '../../../store/slices/modalSlice'


const PatientRefered = ({
  className,
  onClick = () => { },
  patient = {},
}: {
  className?: string
  onClick?: () => void
  patient: any
}) => {
  console.log(patient, 'patient')
  const dispatch = useAppDispatch()
  const router = useRouter()

  const goToPatient = (patient: any) => {
    dispatch(togglePdfModal(patient.prescription_pdf))
    //dispatch(setSelectedPatient(patient))
    //router.push('/platform/patients')
    //dispatch(setModal(false))
  }




  return (
    <div
      className='card rounded-md border bg-white p-3 cursor-pointer'
      onClick={() => goToPatient(patient)}
    >
      <div className='flex justify-between'>
        <div className="flex space-x-2 items-center">
          <div
            className={classNames('bg-cyan-100',
              'mr-3 inline-flex h-8 w-8 items-center justify-center rounded-full p-6 shadow'
            )}
          >
            {patient.patients?.firstName?.slice(0, 1)}
            {patient.patients?.lastName?.slice(0, 1)}
          </div>
          <div className="flex flex-col">
            <span className="text-lg font-bold text-blueColor">{patient.patients?.firstName + " " + patient.patients?.lastName}</span>
            <span className="text-xs text-[#000000]">+91 {patient.patients?.phone}</span>
          </div>
        </div>
        <span className='text-[#000000] text-sm'>{patient.createdAt ? format(new Date(patient.createdAt), 'MMM dd, yyyy h:mm a') : ''}</span>
      </div>
      <div className="mt-3 flex justify-between">
        {patient.isVideoConsultationDone == true &&
          <p className='bg-[#03B4EA33] text-[#175069] py-2 px-4 rounded-md'>Consultation Completed</p>
        }
        {patient.isVideoConsultationDone == false &&
          <p className='bg-[#BF4B4B33] text-[#5C2323] py-2 px-4 rounded-md'>Consultation Not Completed</p>
        }
        {patient.isPaid == true &&
          <p className='bg-[#6ABF4B33] text-[#325C23] py-2 px-4 rounded-md'>Payment Completed</p>
        }
        {patient.isPaid == false &&
          <p className='bg-[#BF4B4B33] text-[#5C2323] py-2 px-4 rounded-md'>Payment Not Completed</p>
        }
      </div>
    </div>
  )
}

export default PatientRefered
