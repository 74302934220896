import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/home'
import { toggleSubmissionModal } from '../../../store/slices/modalSlice'
import { getSubmissionReport } from '../../../store/slices/prescriptionSlice'
import PatientSubmissionDetails from '../patients/Content/PatientSubmissionDetails'
import HealthStatusCard from '../shared/HealthStatusCard'

export default function SubmissionModal() {

  const dispatch = useAppDispatch();

  const [dataPatient, setDataPatient] = useState(null)

  const prescription = useAppSelector((state) => state.modal.submissionModal.data);

  const submissionData = useAppSelector((state) => state.prescription.submissionData);

  useEffect(() => {
    if (prescription) {
      dispatch(getSubmissionReport(prescription))
    }
  }, [prescription])

  const toggle = () => {
    dispatch(toggleSubmissionModal(null))
  }

  return (
    <Dialog.Panel className="relative h-auto w-screen transform overflow-hidden rounded-lg bg-cyan-50 p-4  pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-5xl sm:p-6">
      <div className="flex h-full flex-col space-y-5">
        <div className="flex items-center justify-between">
          <span className="font-semibold">Submission</span>
          <span className="cursor-pointer rounded-full bg-green-400 p-1 text-white">
            <XIcon onClick={toggle} className="h-3 w-3" />
          </span>
        </div>
        {
          dataPatient
            ?
            <PatientSubmissionDetails data={dataPatient} goBack={() => setDataPatient(null)} />
            :
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
              {submissionData.map((item, i) => (
                <HealthStatusCard
                  key={i}
                  item={item}
                  onClick={() => setDataPatient(item)}
                />
              ))}
            </div>
        }
      </div>
    </Dialog.Panel>
  )
}


