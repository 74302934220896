import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from '../../../utils/axios'

// declaring the types for our state
export type HomeState = {
    loading: boolean
    model:boolean
    error: any | null
    data: {
        newOrder: any[]
        newPrescription: any[]
        progressOrder: any[]
        orderDeliveredList: any[]
        selectedOrder: any
        sharedPrescriptionList: any[]
        selectedPrescription: any
        selectedEarningPrescription: any
        selectedMonthlyGenerate: any
        qrScannedList: any[]
        prescriptionList: any[]
        monthlyGenerateList: any[]
        dashboardData: {
            monthlyPrescription: Number
            prescriptionGenerated: Number
            qrScanned: Number
            totalEarnings: Number
        }
        totalEarnings: {
            currentMonthEarnings: Number
            totalEarnings: Number
            details: any[]
        },
        totalEarningPaymentHistory: {
            totalEarnings: Number
            details: any[]
        }
    }
}

const initialState: HomeState = {
    error: null,
    data: {
        newOrder: [],
        newPrescription: [],
        progressOrder: [],
        orderDeliveredList: [],
        selectedOrder: [],
        sharedPrescriptionList: [],
        selectedPrescription: [],
        selectedEarningPrescription: [],
        selectedMonthlyGenerate: [],
        qrScannedList: [],
        prescriptionList: [],
        monthlyGenerateList: [],
        dashboardData: {
            monthlyPrescription: 0,
            prescriptionGenerated: 0,
            qrScanned: 0,
            totalEarnings: 0,
        },
        totalEarnings: {
            currentMonthEarnings: 0,
            totalEarnings: 0,
            details: []
        },
        totalEarningPaymentHistory: {
            totalEarnings: 0,
            details: []
        }
    },
    loading: true,
    model:false,
}

export const medHomeSlice = createSlice({
    name: 'medhome',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions.
    // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
    // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setModal: (state, action: PayloadAction<boolean>) => {
            state.model = action.payload
        },
        setNewOrderData: (state, action: PayloadAction<any>) => {
            state.data.newOrder = action.payload
        },
        setNewPrescriptionData: (state, action: PayloadAction<any>) => {
            state.data.newPrescription = action.payload
        },
        setOrderDeliveredList: (state, action: PayloadAction<any>) => {
            state.data.orderDeliveredList = action.payload
        },
        setSelectedOrder: (state, action: PayloadAction<any>) => {
            state.data.selectedOrder = action.payload
        },
        setSharedPrescriptionList: (state, action: PayloadAction<any>) => {
            state.data.sharedPrescriptionList = action.payload
        },
        setSelectedPrescription: (state, action: PayloadAction<any>) => {
            state.data.selectedPrescription = action.payload
        },
        setSelectedEarningPrescription: (state, action: PayloadAction<any>) => {
            state.data.selectedEarningPrescription = action.payload
        },
        setSelectedMonthlyGenerate: (state, action: PayloadAction<any>) => {
            state.data.selectedMonthlyGenerate = action.payload
        },
        setQrScannedList: (state, action: PayloadAction<any>) => {
            state.data.qrScannedList = action.payload
        },
        setPrescriptionList: (state, action: PayloadAction<any>) => {
            state.data.prescriptionList = action.payload
        },
        setMonthlyGenerateList: (state, action: PayloadAction<any>) => {
            state.data.monthlyGenerateList = action.payload
        },
        setData: (state, action: PayloadAction<any>) => {
            state.data.dashboardData = action.payload.dashboardData
        },
        setTotalEarningsData: (state, action: PayloadAction<any>) => {
            state.data.totalEarnings = action.payload
        },
        setTotalEarningsPaymentData: (state, action: PayloadAction<any>) => {
            state.data.totalEarningPaymentHistory = action.payload
        },
        setProgressOrderData: (state, action: PayloadAction<any>) => {
            state.data.progressOrder = action.payload
        },
        setError: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.error = action.payload
        },
    },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const {
    setLoading,
    setModal,
    setData,
    setTotalEarningsData,
    setTotalEarningsPaymentData,
    setError,
    setNewOrderData,
    setNewPrescriptionData,
    setProgressOrderData,
    setOrderDeliveredList,
    setSelectedOrder,
    setSharedPrescriptionList,
    setSelectedPrescription,
    setQrScannedList,
    setPrescriptionList,
    setMonthlyGenerateList,
    setSelectedMonthlyGenerate,
    setSelectedEarningPrescription
} = medHomeSlice.actions

//API Calls
export const getDashboardData =
    () => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
        try {
            dispatch(setLoading(true))
            const response = await axios.post('/medstore/dashboard')
            const data = response.data.response.data
            dispatch(setData(data))
            dispatch(setLoading(false))
        } catch (error) {
            dispatch(setError(error))
            dispatch(setLoading(false))
        }
    }

export const getTotalEarningsData =
    () => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
        try {
            dispatch(setLoading(true))
            const response = await axios.get('/medstore/earnings')
            const data = response.data.response.data
            dispatch(setTotalEarningsData(data))
            dispatch(setLoading(false))
        } catch (error) {
            dispatch(setError(error))
            dispatch(setLoading(false))
        }
    }

export const getTotalEarningsPaymentData =
    (month: string, year: string) => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
        try {
            dispatch(setLoading(true))
            const response = await axios.get(`/medstore/earnings?month=${month}&year=${year}`)
            const data = response.data.response.data
            dispatch(setTotalEarningsPaymentData(data))
            dispatch(setSelectedEarningPrescription(data.details[0]))
            dispatch(setLoading(false))
        } catch (error) {
            dispatch(setError(error))
            dispatch(setLoading(false))
        }
    }

export const getNewOrderData =
    () => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
        try {
            dispatch(setLoading(true))
            const response = await axios.get('medstore/prescriptions?order=true')
            const data = response.data.response.data
            dispatch(setNewOrderData(data))
            dispatch(setLoading(false))
        } catch (error) {
            dispatch(setError(error))
            dispatch(setLoading(false))
        }
    }

export const getNewPrescriptionData =
    () => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
        try {
            dispatch(setLoading(true))
            const response = await axios.get('medstore/prescriptions?prescription=true')
            const data = response.data.response.data
            dispatch(setNewPrescriptionData(data))
            dispatch(setLoading(false))
        } catch (error) {
            dispatch(setError(error))
            dispatch(setLoading(false))
        }
    }

export const getProgressOrderData =
    () => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
        try {
            dispatch(setLoading(true))
            const response = await axios.post('/prescriptions/order')
            const data = response.data.response.data
            dispatch(setProgressOrderData(data))
            dispatch(setLoading(false))
        } catch (error) {
            dispatch(setError(error))
            dispatch(setLoading(false))
        }
    }

export const getOrderDeliveredList =
    (dat: string) => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
        try {
            dispatch(setLoading(true))
            const response = await axios.post('/prescriptions/order', { status: "DELIVERED", date: dat })
            const data = response.data.response.data
            dispatch(setOrderDeliveredList(data))
            dispatch(setSelectedOrder(data[0]))
            dispatch(setLoading(false))
        } catch (error) {
            dispatch(setError(error))
            dispatch(setLoading(false))
        }
    }

export const getSharedPrescriptionList =
    (dat: string) => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
        try {
            dispatch(setLoading(true))
            const response = await axios.get(`medstore/prescriptions?generated=true&date=${dat}`)
            const data = response.data.response.data
            dispatch(setSharedPrescriptionList(data))
            dispatch(setSelectedPrescription(data[0]))
            dispatch(setLoading(false))
        } catch (error) {
            dispatch(setError(error))
            dispatch(setLoading(false))
        }
    }


export const getPrescriptionList =
    (type: string, dat: string) => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
        try {
            dispatch(setLoading(true))
            const response = await axios.get(`medstore/prescriptions?${type}=true&date=${dat}`)
            const data = response.data.response.data
            dispatch(setPrescriptionList(data))
            dispatch(setSelectedPrescription(data[0]))
            dispatch(setLoading(false))
        } catch (error) {
            dispatch(setError(error))
            dispatch(setLoading(false))
        }
    }



export const getMonthlyGenerateList =
    () => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
        try {
            dispatch(setLoading(true))
            const response = await axios.get(`medstore/prescriptions?generate=true&month=true`)
            const data = response.data.response.data
            dispatch(setMonthlyGenerateList(data))
            dispatch(setSelectedMonthlyGenerate(data[0]))
            dispatch(setLoading(false))
        } catch (error) {
            dispatch(setError(error))
            dispatch(setLoading(false))
        }
    }

export const getCalenderMonthlyGenerateList =
    (dat: string) => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
        try {
            dispatch(setLoading(true))
            const response = await axios.get(`medstore/prescriptions?generate=true&date=${dat}`)
            const data = response.data.response.data
            dispatch(setMonthlyGenerateList(data))
            dispatch(setSelectedMonthlyGenerate(data[0]))
            dispatch(setLoading(false))
        } catch (error) {
            dispatch(setError(error))
            dispatch(setLoading(false))
        }
    }



export const getQrScannedList =
    (dat: string) => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
        try {
            dispatch(setLoading(true))
            const response = await axios.get(`medstore/prescriptions?date=${dat}`)
            const data = response.data.response.data
            dispatch(setQrScannedList(data))
            dispatch(setLoading(false))
        } catch (error) {
            dispatch(setError(error))
            dispatch(setLoading(false))
        }
    }


export const orderAccept =
    (id: string) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                const res = await axios.patch(`/orders/${id}`, {
                    status: "PROCESSING",
                })
                const response = await axios.get('medstore/prescriptions?order=true')
                const data = response.data.response.data
                dispatch(setNewOrderData(data))
            } catch (error) {
                dispatch(setError(error))
            }
        }

export const orderReject =
    (id: string) =>

        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                const res = await axios.patch(`/orders/${id}`, { status: "CANCELLED" })
                const response = await axios.get('medstore/prescriptions?order=true')
                const data = response.data.response.data
                dispatch(setNewOrderData(data))
            } catch (error) {
                dispatch(setError(error))
            }
        }


export const prescriptionCompleted =
    (id: string) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                const res = await axios.patch(`/prescription/shared/${id}`)
            } catch (error) {
                dispatch(setError(error))
            }
        }



export const orderPackaging =
    (id: string, data1: any) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                const res = await axios.patch(`/orders/${id}`, data1)
                const response = await axios.post('/prescriptions/order')
                const data = response.data.response.data
                dispatch(setProgressOrderData(data))
            } catch (error) {
                dispatch(setError(error))
            }
        }



// exporting the reducer here, as we need to add this to the store
export default medHomeSlice.reducer
