import {
  HomeIcon,
  ViewGridIcon,
  ViewListIcon,
  ClipboardListIcon,
  CalendarIcon,
  ShareIcon,
  FireIcon,
  UserIcon,
} from '@heroicons/react/outline'


export const sidebarNavigation = [
  {
    name: 'Home',
    icon: HomeIcon,
    href: '/medstore/dashboard'
  },
  {
    name: 'Orders',
    icon: ViewListIcon,
    href: '/medstore/order',
    soon: true
  },
  {
    name: 'Prescriptions',
    icon: ClipboardListIcon,
    href: '/medstore/prescriptions'
  },
  // {
  //   name: 'Tests',
  //   icon: CalendarIcon,
  //   href: '/medstore/tests',
  // },
  {
    name: 'Diagnostics',
    icon: CalendarIcon,
    href: '/medstore/diagnostics',
  },
  {
    name: 'Your Unique Customer',
    icon: FireIcon,
    href: '/medstore/referral',
  },
  {
    name: 'Add Exclusive Customer',
    icon: UserIcon,
    href: '/medstore/customer',
    phone: true
  },
  {
    name: 'My Info',
    icon: ViewGridIcon,
    href: '/medstore/store',
  }
]

