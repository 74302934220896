import React from 'react'
import classNames from 'classnames'
const TextArea = ({
    value,
    className,
    onClick = () => { },
    onChange = () => { },
    onBlur = () => { },
    type = 'text',
    placeholder,
    name,
    rows,
    suffix,
    disabled = false,
    defaultValue,
}: {
    name?: string
    value?: any
    rows?: any
    type?: string
    className?: string
    onClick?: any
    onChange?: any
    onBlur?: any
    placeholder?: string,
    suffix?: React.ReactNode
    disabled?: boolean
    defaultValue?: any
}) => {
    return (
        <div>
            <textarea
                rows={rows}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={placeholder}
                className={classNames(
                    className,
                    'py-1 px-3 w-full rounded-md border'
                )} ></textarea>
        </div>
    )
}


export default TextArea