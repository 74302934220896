import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { useAppDispatch, useAppSelector } from "../hooks/home";
import { toggleComingSoon } from "../store/slices/modalSlice";
import  Button from '../Comp/Button'
import SvgIcon from '../components/medstore/shared/SvgIcon'

const ComingSoonModal = () => {
    const dispatch = useAppDispatch()
    const show = useAppSelector((state) => state.modal.comingSoon.show)

    const toggle = () => {
        dispatch(toggleComingSoon())
    }

    return (
        <Transition appear show={show} as={Fragment} >
            <Dialog as="div" className="relative z-10" onClose={toggle}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-opacity-75 bg-gray-400" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full single-article max-w-xs transform overflow-hidden rounded-2xl h-[380px] text-[#1B1E1F] bg-white p-6 text-center align-middle shadow-xl transition-all">


                                <div className='my-4 pb-2'>
                                    <SvgIcon src="/images/ourTeamProduct.svg" className='object-contain mx-auto' alt='ourTeamProduct' />
                                    <p className='text-[#003B4D] font-semibold text-xl md:text-2xl mt-3 text-center mx-auto'>Our Team is working hard to deliver you the best product. </p>
                                    <Button onClick={toggle} children="Go Back" className='p-3 px-5 w-fit mt-6' />
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default ComingSoonModal;