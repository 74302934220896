import React, { Fragment, useState } from 'react'
import { sidebarNavigation } from './config'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { Dialog, Transition } from '@headlessui/react'
import SvgIcon from './shared/SvgIcon'
import { XIcon } from '@heroicons/react/solid'
import { useAppDispatch, useAppSelector } from '../../hooks/home'
import { toggleComingSoon } from '../../store/slices/modalSlice'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Input from '../../Comp/Input'
import SubmitButton from '../../Comp/SubmitButton'
import { XCircleIcon } from '@heroicons/react/solid'
import FormErrorMessage from '../../components/medstore/shared/FormErrorMessage'
import { getListData, sendOTP, resendOTP, confirmOTP } from '../../store/slices/MedstoreSlices/medRefferalSlice'
import { notifier } from '../../store/slices/notificationSlice'
import OtpInput from "react-otp-input";

const Sidebar = ({
  mobileMenuOpen,
  setMobileMenuOpen,
}: {
  mobileMenuOpen: any
  setMobileMenuOpen: any
}) => {
  const [inputModel, setInputModel] = useState(false)
  const [otpModel, setOtpModel] = useState(false)
  const [otp, setOtp] = useState<any>('')
  const [phone, setPhone] = useState<any>('')

  const { pathname } = useRouter()
  const dispatch = useAppDispatch()

  const otpdata = useAppSelector(
    (state) => state.medRefferal?.otpResponse
  )

  const phoneVerify = () => {
    formik.resetForm()
    setInputModel(true)
  }

  const closeModalOtp = () => {
    setOtpModel(false)
    setOtp("")
  }

  const verifyOtpModal = () => {
    console.log("otp", otp)
    let val = {
      phone: '',
      requestId: '',
      otp: '',
      referral: true
    }
    val.phone = phone
    val.requestId = otpdata?.requestId
    val.otp = otp
    dispatch(confirmOTP(val))
    setOtpModel(false)
  }

  const resendOtp = () => {
    let val = {
      phone: '',
      requestId: '',
      referral: true
    }
    val.phone = phone
    val.requestId = otpdata?.requestId
    dispatch(resendOTP(val))
    notifier.success('OTP resent successfully')
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      phone: "",
    },
    validationSchema: Yup.object().shape({
      phone: Yup.string().required(
        'Phone is Required'
      ).matches(/^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/g, '* Only Numbers Required'),

    }),
    onSubmit: async (values: any) => {
      try {
        console.log("values", values)
        let phon = `+91${values.phone}`
        setPhone(phon)
        let val = {
          phone: phon,
          referral: true
        }
        dispatch(sendOTP(val))
        setInputModel(false)
        setOtp("")
        setOtpModel(true)

      } catch (e) {
        console.error(e)

      }
    },
  })

  return (
    <Fragment>
      {/* Desktop  */}

      <div className="hidden w-28 overflow-y-auto bg-blueColor md:block">
        <div className="flex w-full flex-col items-center">
          <div className="w-full flex-1 space-y-1 px-2">
            {sidebarNavigation.map((item) => (
              <Link key={item.name} href={item.soon || item.phone ? "#" : item.href}>
                <div
                  onClick={() => item.soon && dispatch(toggleComingSoon()) || item.phone && phoneVerify()}
                  className={classNames(
                    item.href === pathname
                      ? 'bg-white font-semibold text-blueColor'
                      : 'text-indigo-100 hover:text-white',
                    'group flex w-full cursor-pointer flex-col items-center rounded-md p-3 text-xs font-medium'
                  )}
                >
                  <item.icon
                    className={classNames(
                      item.href === pathname
                        ? 'text-blueColor'
                        : 'group-hover:text-white',
                      'h-6 w-6'
                    )}
                    aria-hidden="true"
                  />
                  <span className="mt-2 text-center">{item.name}</span>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      {/* Mobile */}
      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-20 md:hidden"
          onClose={setMobileMenuOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-blueColor pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-1 right-0 -mr-14 p-1">
                    <button
                      type="button"
                      className="flex h-12 w-12 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Close sidebar</span>
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 items-center px-4">
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/workflow-mark.svg?color=white"
                    alt="Workflow"
                  />
                </div>
                <div className="mt-5 h-0 flex-1 overflow-y-auto px-2">
                  <nav className="flex h-full flex-col">
                    <div className="space-y-1">
                      {sidebarNavigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.href === pathname
                              ? 'bg-blueColor text-white'
                              : 'text-indigo-100 hover:bg-blueColor hover:text-white',
                            'group flex items-center rounded-md py-2 px-3 text-sm font-medium'
                          )}
                          aria-current={
                            item.href === pathname ? 'page' : undefined
                          }
                        >
                          <item.icon
                            className={classNames(
                              item.href === pathname
                                ? 'text-white'
                                : ' group-hover:text-white',
                              'mr-3 h-6 w-6'
                            )}
                            aria-hidden="true"
                          />
                          <span>{item.name}</span>
                        </a>
                      ))}
                    </div>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* model for input */}

      <Transition appear show={inputModel} as={Fragment} >
        <Dialog as="div" className="relative z-10" onClose={setInputModel}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-opacity-75 bg-gray-400" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full single-article max-w-xs transform overflow-hidden rounded-2xl text-[#1B1E1F] bg-white p-4 text-center align-middle shadow-xl transition-all">
                  <div className='flex justify-between items-center mb-4'>
                    <Dialog.Title
                      as="h3"
                      className="text-lg pt-1 font-bold leading-6 text-gray-900"
                    >
                      Enter Phone Number
                    </Dialog.Title>

                  </div>
                  <form className='flex flex-col space-y-3' onSubmit={formik.handleSubmit}>
                    <Input
                      name='phone'
                      placeholder='Enter Phone Number'
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <FormErrorMessage touched={formik.touched.phone} error={formik.errors.phone} />
                    <button type='submit' className='bg-blueColor p-2 text-white font-semibold rounded-md border shadow'>Invite</button>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>


      {/* model for OTP */}

      <Transition appear show={otpModel} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModalOtp}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full single-article max-w-lg transform overflow-hidden rounded-2xl bg-white p-4 align-middle shadow-xl transition-all">
                  <div className='flex justify-between items-center'>
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Enter OTP
                    </Dialog.Title>
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={closeModalOtp}
                    >
                      <span className="sr-only">Close</span>
                      <XCircleIcon className="h-6 w-6" aria-hidden={true} />
                    </button>
                  </div>
                  <hr className='mt-3' />
                  <div className='flex flex-col justify-center items-center mx-auto'>
                    {/* @ts-ignore */}
                    <OtpInput
                      className='mt-7'
                      value={otp}
                      isInputNum={true}
                      onChange={(e: any) => setOtp(e)}
                      numInputs={5}
                      separator={<span></span>}
                      inputStyle={{
                        width: "2rem",
                        height: "2rem",
                        margin: "0 0.7rem",
                        fontSize: "1rem",
                        borderRadius: 4,
                        border: "1px solid rgba(0,0,0,0.3)"
                      }}
                    />

                  </div>
                  <p onClick={resendOtp} className='cursor-pointer text-blueColor text-left mt-2'>Resend OTP</p>
                  <SubmitButton onClick={verifyOtpModal} children="Confirm OTP" className="mt-3 bg-blueColor w-fit text-white px-4 py-2" />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>


    </Fragment>
  )
}
export default Sidebar
