import * as Yup from 'yup'
import { format } from 'date-fns'

export const adviceInitialState: any = {
  fromDt: format(new Date(), 'yyyy-MM-dd'),
  toDt: null,
  medicine_name: null,
  medicine_type: null,
  frequency: 1,
  interval: 'Daily',
  weekDays: [],
  time_slot: [],
  symptoms: [],
  otherSymptoms: [],
  new_symptom: '',
  diseases: [],
  course_duration_type: null, // day, week, month , manual
  course_duration_count: null, //1 - 10
  remarks: '',
}

export const advicevalidationSchema = Yup.object().shape({
  remarks: Yup.string().max(500, 'Remark cannot be more than 500 characters'),
  course_duration_type: Yup.string().when('frequency', {
    is: 'SOS',
    then: Yup.string().nullable(),
    otherwise: Yup.string()
      .required('Course Duration Type is required')
      .nullable(),
  }),
  course_duration_count: Yup.string().when('frequency', {
    is: 'SOS',
    then: Yup.string().nullable(),
    otherwise: Yup.string().when('course_duration_type', {
      is: 'manual',
      then: Yup.string().nullable(),
      otherwise: Yup.string()
        .required('Course Duration Count is required')
        .nullable(),
    }),
  }),
  fromDt: Yup.date()
    .required('From date is required')
    .min(
      new Date(new Date().setDate(new Date().getDate() - 1)),
      "From date should be greater than today's date"
    )
    .typeError('From date should be a valid date'),
  toDt: Yup.date().when('frequency', {
    is: 'SOS',
    then: Yup.date().nullable(),
    otherwise: Yup.date()
      .required('To date is required')
      .min(Yup.ref('fromDt'), 'To date should be greater than from date')
      .typeError('To date should be a valid date'),
  }),
  medicine_name: Yup.string()
    .required('Medicine name is required')
    .max(200, 'Medicine name should not exceed 200 characters')
    .nullable(),
  medicine_type: Yup.string()
    .required('Medicine type is required')
    .matches(
      /^[a-zA-Z0-9 ]+$/,
      'Medicine type should contain only alphabets and numbers'
    )
    .max(50, 'Medicine type should not exceed 50 characters')
    .nullable(),
  frequency: Yup.mixed().required('Frequency is required'),
  interval: Yup.string().when('frequency', {
    is: 'SOS',
    then: Yup.string().nullable(),
    otherwise: Yup.string().required('Interval is required'),
  }),
  weekDays: Yup.array()
    .required('Week days is required')
    .test({
      name: 'weekDays',
      message: 'Please Select weekdays',
      test: function (value: any, ctx) {
        if (ctx.parent.frequency === 'SOS') return true
        if (!ctx.parent.interval) return false
        if (ctx.parent.interval.includes('eek')) {
          if (ctx.parent.interval.includes('Twice') && value.length !== 2) {
            return this.createError({
              message: 'Please select 2 weekdays',
              path: 'weekDays',
            })
          }
          if (ctx.parent.interval.includes('Once') && value.length !== 1) {
            return this.createError({
              message: 'Please select only one weekday',
              path: 'weekDays',
            })
          }
        }
        return true
      },
    }),
  time_slot: Yup.array()
    .required('Time slot is required')
    .test({
      name: 'time_slot',
      message: 'No. of time slots should be same as frequency',
      test: (value: any, ctx) => {
        console.log(ctx.parent.frequency)
        if (ctx.parent.frequency === 'SOS') return true
        if (ctx.parent.frequency === 24) {
          return true
        }
        return ctx.parent.frequency === value.length
      },
    }),
})
