import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/home'
import { togglePdfModal } from '../../../store/slices/modalSlice'
import DocumentViewer from '../shared/DocumentViewer'
// import PDFViewer from 'pdf-viewer-reactjs'

export default function PDFModal() {
  const dispatch = useAppDispatch()
  const url = useAppSelector((state) => state.modal.pdfModal.data)

  const toggle = () => {
    dispatch(togglePdfModal(null))
  }

  return (
    <Dialog.Panel className="relative  h-auto w-screen max-w-2xl transform overflow-hidden rounded-lg bg-cyan-50  p-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
      <div className="flex flex-col">
        <div className="flex items-center justify-between">
          <span className="font-semibold">File :</span>
          <span className="cursor-pointer rounded-full bg-green-400 p-1 text-white">
            <XIcon onClick={toggle} className="h-3 w-3" />
          </span>
        </div>
        <div className="h-full flex-grow p-0">
          {!!url && Array.isArray(url) ? (
            <DocumentViewer
              documentURL={url} fileName="download.pdf"
            ></DocumentViewer>
          ) : !!url && !Array.isArray(url) ? (
            <DocumentViewer
              documentURL={url + `?session=${localStorage.getItem('token')}`} fileName="download.pdf"
            ></DocumentViewer>
          ) : <span>No Prescription Generated</span>}
        </div>
      </div>
    </Dialog.Panel>
  )
}
