import React, { Fragment, useEffect, useState, useRef } from 'react'
import { Menu, Transition, Switch } from '@headlessui/react'
import { SearchIcon } from '@heroicons/react/solid'
import { MenuAlt2Icon, PlusSmIcon } from '@heroicons/react/outline'
import { } from './config'
import ToggleButton from '../buttons/ToggleButton'
import classNames from 'classnames'
import SvgIcon from './shared/SvgIcon'
import Avatar from './shared/Avatar'
import { useAppSelector, useAppDispatch } from '../../hooks/home'
import { getStoreProfile } from '../../store/slices/MedstoreSlices/medProfileSlice'
import { getCartData } from '../../store/slices/MedstoreSlices/medDiagnosticSlice'
import { useRouter } from 'next/router'
import { togglediagnosticUpload } from '../../store/slices/modalSlice'

const user = {
  name: 'Whitney Francis',
  email: 'whitney.francis@example.com',
  imageUrl:
    'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}

const userNavigation = [
  { name: 'View Profile', href: '/medstore/store' },
  { name: 'Sign out', href: '/api/auth/signout' },
]

const Header = (props: {
  mobileMenuOpen: any
  setMobileMenuOpen: any
  search: string
  setMedSearch: Function
}): JSX.Element => {
  const [available, setAvailable] = useState(false)
  const [enabled, setEnabled] = useState(false)
  const [files, setFiles]: [s: any, s: any] = useState([]);
  const authState = useAppSelector((state) => state.auth)
  const medStore = useAppSelector((state) => state.medProfile?.data)

  const dispatch = useAppDispatch()
  const router = useRouter()
  const uploadRef: any = useRef();

  useEffect(() => {
    dispatch(getStoreProfile(authState.user._id))
    dispatch(getCartData())
  }, [])

  const cartData = useAppSelector((state) => state.medDiagnostic?.cartData?.cartDetails?.appointmentCart[0]?.appointment)

  // useEffect(() => {
  //   if (files.length) {
  //     dispatch(togglediagnosticUpload([URL.createObjectURL(files[0]), authState.user._id]))
  //   }

  // }, [files])

  return (
    <header className="w-full">
      <div className=" relative z-10 flex h-16 flex-shrink-0  bg-blueColor shadow-sm md:px-8">
        <button
          type="button"
          className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
          onClick={() => props.setMobileMenuOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <SvgIcon
          className="h-full py-1"
          src="/assets/eonbtLogo.svg"
          alt="EONMED"
        />

        {/* for input */}
        <div className="flex flex-1 items-center   sm:px-6  ">
          <div className="relative mx-auto my-auto hidden h-10 w-96  rounded-md border border-white text-white sm:px-6 md:block ">
            <input
              onChange={(e) => props.setMedSearch(e.target.value)}
              value={props.search}
              name="search-field"
              id="search-field"
              className="h-full w-full bg-transparent py-2 pr-8 pl-1 text-base placeholder-white focus:border-transparent   focus:ring-0"
              placeholder="Search Name"
              type="search"
            />
            <div className="pointer-events-none absolute inset-y-0 right-2 flex items-center">
              <SearchIcon
                className="h-5 w-5 flex-shrink-0"
                aria-hidden="true"
              />
            </div>
          </div>
        </div>


        <div className='flex justify-center items-center pr-7'>
          <input onChange={(e: any) => setFiles(Array.from(e.target.files))} multiple ref={uploadRef} hidden name='files' type='file' />
          {/* <div onClick={() => uploadRef.current.click()} className='flex items-center p-1 space-x-1 bg-[#D73A3A] rounded-full text-xs mr-2 cursor-pointer'>
            <SvgIcon src="/medstore/uploadPrescription.svg" alt="uploadPrescription" className='object-contain' />
            <span className='text-white'>Upload Prescription</span>
          </div> */}
          <div onClick={() => router.push("/medstore/diagnostics/cart")} className='flex items-center space-x-0 cursor-pointer'>
            <SvgIcon src="/medstore/cart.svg" alt="cart" className='object-contain' />
            {cartData && cartData.length > 0 &&
              <span className='bg-[#343E45] text-white px-2 py-1 rounded-full flex items-center justify-center h-7'>{cartData?.length}</span>
            }
          </div>

          {/* <p className='pr-3 text-white'>Available</p>
          <Switch
            checked={enabled}
            onChange={setEnabled}
            className="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none"
          >
            <span className="sr-only">Use setting</span>
            <span aria-hidden="true" className="pointer-events-none absolute w-full h-full rounded-md" />
            <span
              aria-hidden="true"
              className={classNames(
                enabled ? 'bg-[#6ABF4B]' : 'bg-gray-200',
                'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200'
              )}
            />
            <span
              aria-hidden="true"
              className={classNames(
                enabled ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none absolute left-0 inline-block h-5 w-5 rounded-full bg-white shadow transform transition-transform ease-in-out duration-200'
              )}
            />
          </Switch> */}
        </div>

        <div className="ml-auto flex items-center space-x-4 sm:space-x-6 sm:pr-6 md:pr-0">
          {/* Profile dropdown */}
          <Menu as="div" className="relative flex-shrink-0">
            <div>
              <Menu.Button className="flex rounded-full text-sm focus:outline-none focus:ring-offset-2">
                <span className="sr-only">Open user menu</span>
                <div
                  className={classNames('flex h-10 w-10 items-center justify-center overflow-hidden border-2 border-white rounded-full bg-[#F5F5F5] text-blueColor'
                  )}
                >
                  {
                    medStore?.images ? <img className='h-full w-full object-cover bg-white' src={medStore?.images} alt='profilepics' />
                      : "Store"
                  }
                </div>

              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-blueColor py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {userNavigation.map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <a
                        href={item.href}
                        className={classNames(
                          active ? 'bg-gray-100 text-blueColor' : 'text-white',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        {item.name}
                      </a>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>


        </div>

      </div>
    </header>
  )
}

export default Header
