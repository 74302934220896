import React from 'react'
import classNames from 'classnames'
const Button = ({
  disabled = false,
  children,
  className,
  onClick = () => {},
}: {
  disabled?: boolean
  children: React.ReactNode
  className?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type="button"
      className={classNames(
        'inline-flex  items-center justify-center rounded-lg border border-transparent bg-blueColor  text-white',
        className
      )}
    >
      {children}
    </button>
  )
}

export default Button
