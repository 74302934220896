import { useRouter } from 'next/router'
import React from 'react'
import PatientRefered from './doctors/patientreferred/PatientRefered'

const SearchBar = ({ patientList, onClose }: { patientList: any, onClose: any }) => {
  const router = useRouter();

  const handleSearch = (id: string) => {
    onClose();
    router.push({
      pathname: '/platform/patients/[id]',
      query: { id },
    });
  }

  return (
    <div className="h-full bg-[#F5FAFD] p-3">
      <div className="grid grid-cols-3 gap-4">
        {patientList.map((patient: any) => {
          return <PatientRefered onClick={() => handleSearch(patient._id)} key={patient._id} patient={patient} />
        })}
      </div>
    </div>
  )
}

export default SearchBar
