import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import { Button } from 'antd'
import classNames from 'classnames'
import { format } from 'date-fns'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../hooks/home'
import { togglepatientHistory } from '../../../store/slices/modalSlice'
import PatientFeed from '../shared/PatientFeed'

export default function PreviousConsultModal() {
    const dispatch = useAppDispatch()
    const modalState = useAppSelector((state) => state.modal)
    //const prescriptionHistory: any[] = []

    const toggle = () => {
        dispatch(togglepatientHistory(null))
    }

    const prescriptionHistory = useAppSelector(
        (state) => state.prescription.history
    )

    if (modalState.submissionModal.show || modalState.pdfModal.show) {
        return null;
    }


    return (
        <Dialog.Panel className="relative w-screen max-w-4xl transform max-h-[500px] overflow-auto rounded-lg bg-white p-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
            <div className="flex flex-col">
                <div className="flex items-center justify-between">
                    <span className="font-semibold">Previous Consultation:</span>
                    <span className="cursor-pointer rounded-full bg-green-400 p-1 text-white">
                        <XIcon onClick={toggle} className="h-3 w-3" />
                    </span>
                </div>
                <div className="flex-grow p-0">
                    {!prescriptionHistory.length ? (
                        'Select a patient to view'
                    ) : (
                        <PatientFeed prescriptionHistory={prescriptionHistory} />
                    )}
                </div>
            </div>
        </Dialog.Panel>
    )
}
