import { Dialog } from '@headlessui/react'
import { XIcon, TrashIcon, CheckIcon } from '@heroicons/react/outline'
import DocumentViewer from '../../doctors/shared/DocumentViewer'
import { togglediagnosticUpload } from '../../../store/slices/modalSlice'
import { useAppDispatch, useAppSelector } from '../../../hooks/home'
import 'swiper/css'
import 'swiper/css/navigation'
import { notifier } from '../../../store/slices/notificationSlice'

function DiagnosticUpload() {
    const dispatch = useAppDispatch()

    const url = useAppSelector(
        (state) => state.modal.diagnosticUpload.data[0]
    )
   
    const id = useAppSelector((state) => state.modal.diagnosticUpload.data[1])
    const toggle = () => {
        dispatch(togglediagnosticUpload(null))
    }

    const ulpoadPres = () => {
        console.log("uploaded", url)
    }

    return (
        <Dialog.Panel className="relative w-screen max-w-xl transform overflow-hidden rounded-lg bg-white px-4 pt-2 pb-4 text-left shadow-xl transition-all">
            <h1>Upload Prescription</h1>
            <div className="absolute top-0 right-0 hidden pt-4 pr-2 sm:block">
                <button type="button" onClick={toggle}>
                    <span className="sr-only">Close</span>
                    <XIcon
                        className="h-6 w-6 rounded-full bg-greenColor text-white"
                        aria-hidden="true"
                    />
                </button>
            </div>

            <div className="w-11/12 text-center sm:mt-0 sm:ml-4 sm:text-left">
                {!!url && (
                    <DocumentViewer
                        documentURL={url}
                        fileName="download.pdf"
                    ></DocumentViewer>
                )}
            </div>

           
            <div className="mt-2 flex justify-center items-center space-x-5">
                <div onClick={toggle} className='p-3 bg-white flex items-center justify-center border rounded-full cursor-pointer'>
                    <TrashIcon className='w-8 h-8 text-red-700' />
                </div>
                <div onClick={ulpoadPres} className='p-3 bg-white flex items-center justify-center border rounded-full cursor-pointer'>
                    <CheckIcon className='w-8 h-8 text-greenColor' />
                </div>
            </div>
        </Dialog.Panel>
    )
}

export default DiagnosticUpload
