import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { format } from 'date-fns'
import React from 'react'
import { useAppDispatch } from '../../../hooks/home'
import { toggleScheduledConsultationModal } from '../../../store/slices/modalSlice'
import PatientFeed from '../shared/PatientFeed'
import { Button, OutlineButton } from '../../buttons/button'
// import SharedPrescriptionSVG from '../../../public/assets/shared-prescription.svg'

export default function ScheduledConsultationModal() {
  const dispatch = useAppDispatch()
  const toggle = () => {
    dispatch(toggleScheduledConsultationModal(null))
  }

  return (
    <Dialog.Panel className="relative h-auto w-screen max-w-lg transform overflow-hidden rounded-lg bg-white p-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
      <div className="flex h-full flex-col space-y-5">
        <div className="flex items-center justify-between">
          <span className="font-semibold">Scheduled Consultation</span>
          <span className="cursor-pointer rounded-full bg-green-400 p-1 text-white">
            <XIcon onClick={toggle} className="h-3 w-3" />
          </span>
        </div>
        <div className="flex items-center text-blueColor">
          <span className="mr-4 rounded-full bg-cyan-100 p-2">MA</span>
          <span>Mohan AK</span>
        </div>
        <div className="flex flex-col">
          <span> Title</span>
          <input className="rounded-md border p-2 pl-3 text-gray-500" />
        </div>
        <div className="flex space-x-3">
          <div className="flex flex-1 flex-col">
            <span> Date</span>
            <input
              type="date"
              className="rounded-md border p-2 pl-3 text-gray-500"
            />
          </div>
          <div className="flex flex-1 flex-col">
            <span> Time</span>
            <input
              type="time"
              className="rounded-md border p-2 pl-3 text-gray-500"
            />
          </div>
        </div>
        <div className="flex flex-col">
          <span> Description</span>
          <textarea className="rounded-md border p-2 pl-3 text-gray-500" />
        </div>

        <div className="mt-10 space-x-4 text-center">
          <OutlineButton className="border-green-400 py-1 px-3 uppercase">
            SAVE
          </OutlineButton>
        </div>
      </div>
    </Dialog.Panel>
  )
}
