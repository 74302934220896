import * as Yup from 'yup'

export const patientInitialState = {
  firstName: '',
  lastName: '',
  age: '',
  ageSuffix: "years",
  gender: '',
  phone: '',
  email: '',
  country: "",
  state: '',
  district: '',
  height: '',
  weight: '',
  userId: '',
  optInUserId: '',
  optIn: false
}

let patternOneDigisAfterComma = /^\d+(\.\d{0,1})?$/;

export const patientvalidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('Name is required')
    .min(2, 'Too Short!')
    .max(25, 'Too Long!')
    .matches(
      /^[a-zA-Z ]*$/,
      'Only alphabet characters are allowed!'
    ),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(25, 'Too Long!')
    .matches(
      /^[a-zA-Z ]*$/,
      'Only alphabet characters are allowed!'
    ),
  age: Yup.number().integer()
    .typeError("Should be a number")
    .max(150, 'Should be less than 150 years')
    .transform((_, value) => {
      if (value.toString().includes('.') || value.toString().includes(',')) {
        return null;
      }
      return +value.toString().replace(/,/, '.');
    })
    .positive(),
  ageSuffix: Yup.string()
    .required()
    .oneOf(['years', 'months']),
  gender: Yup.string()
    // .required('Gender is required')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed')
    .min(2, 'Too Short!')
    .max(10, 'Too Long!'),
  phone: Yup.string()
    .required('Contact Number is required')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(2, 'Too Short!')
    .max(10, 'Too Long!'),
  email: Yup.string()
    .email('Invalid email')
    // .required('Email is required')
    .min(2, 'Too Short!')
    .max(40, 'Too Long!')
    .matches(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/, 'Invalid email'),
  country: Yup.string()
    .min(2, 'Too Short!')
    .max(30, 'Too Long!')
    .matches(
      /^[a-zA-Z ]*$/,
      'Only alphabet characters are allowed!'
    ),
  state: Yup.string()
    // .required('State is required')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed')
    .min(2, 'Too Short!')
    .max(25, 'Too Long!'),
  district: Yup.string()
    // .required('City is required')
    .matches(
      /^[\w\-\s]+$/,
      'Only alphanumerals characters are allowed!'
    ).min(2, 'Too Short!')
    .max(25, 'Too Long!'),
  height: Yup.number().positive()
    // .required('Height is required')
    .typeError("Should be a number")
    .max(300, 'Should be less than 300')
    .test(
      "is-decimal",
      "Maximum Shoud be One digits after Dot",
      (val: any) => {
        if (val != undefined) {
          return patternOneDigisAfterComma.test(val);
        }
        return true;
      }
    ),
  weight: Yup.number().positive()
    // .required('Weight is required')
    .typeError("Should be a number")
    .max(300, 'Should be less than 300')
    .test(
      "is-decimal",
      "Maximum Shoud be One digits after Dot",
      (val: any) => {
        if (val != undefined) {
          return patternOneDigisAfterComma.test(val);
        }
        return true;
      }
    ),
})
