import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from '../../utils/axios'
import crypto from 'crypto'
import { notifier } from './notificationSlice'
import Router from 'next/router'

// declaring the types for our state
export type AuthState = {
  accessToken: string | null
  loading: boolean
  auth: boolean
  user: any | null
  error: any | null
}

const initialState: AuthState = {
  accessToken: null,
  loading: true,
  auth: false,
  user: null,
  error: null,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions.
  // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
  // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setLogin: (state, action: PayloadAction<any>) => {
      localStorage.setItem('token', action.payload.accessToken)
      state.loading = false
      state.auth = true
      state.user = action.payload.user
      state.accessToken = action.payload.accessToken
    },
    setLogout: (state) => {
      localStorage.removeItem('token')
      state.loading = false
      state.auth = false
      state.user = null
    },
    setError: (state, action: PayloadAction<any>) => {
      state.error = action.payload
    },
  },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { setLoading, setLogin, setLogout, setError } = authSlice.actions

//API Calls
export const login =
  (values: any) =>
    async (dispatch: (arg0: { payload: any; type: string }) => void) => {
      try {
        const res = await fetch(`/public.txt`)
        const publicKey = await res.text()
        const encryptedData = crypto.publicEncrypt(
          { key: publicKey },
          Buffer.from(values.password)
        )
        const password = encryptedData.toString('base64')
        var type =
          values.type === 'medstore'
            ? 'medStore'
            : values.type === 'client'
              ? 'orgMembers'
              : values.type
        const response = await axios.post(
          `${process.env.API_URL}/${type}/login`,
          {
            email: values.email.toLowerCase(),
            password,
          }
        )
        if (response.data) {
          const data = response.data.response.data
          console.log(data)
          if (data.token) {
            dispatch(
              setLogin({
                accessToken: data.token,
                user: data.user,
              })
            )
          }
        }

      } catch (e: any) {
        console.error("Catch", e)
        dispatch(setError(e.response?.data.error[0].msg || "Server Error"))
        throw (e)
      }
    }

export const verifySession =
  () => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      const token = localStorage.getItem('token')
      dispatch(setLoading(true))
      if (token) {
        const res = await axios.post('/session/verify', { token })
        const data = res.data.response?.data
        if (!data) throw new Error('Invalid Token')
        console.log({ data, token })
        dispatch(
          setLogin({
            accessToken: token,
            user: data,
          })
        )
      } else {
        dispatch(setLogout())
      }
    } catch (err) {
      dispatch(setLogout())
      dispatch(setLoading(false))
    }
  }

export const signUp =
  (values: any) =>
    async (dispatch: (arg0: { payload: any; type: string }) => void) => {
      try {
        const response = await axios.post("/doctor/signup", values)
        if (response.data) {
          const data = response.data.response.data
          console.log(data)
          notifier.success(response.data.response.msg)
          Router.push("/platform/auth/login")
        }

      } catch (e: any) {
        console.error("Catch", e)
        dispatch(setError(e.response?.data.error[0].msg || "Server Error"))
        throw (e)
      }
    }


// exporting the reducer here, as we need to add this to the store
export default authSlice.reducer
