import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from '../../utils/axios'

// declaring the types for our state
export type RechargeState = {
    loading: boolean
    error: any | null
    data: any | {},
    paymentTxns: any | []
}

const initialState: RechargeState = {
    error: null,
    loading: true,
    data: {},
    paymentTxns: []
}

export const rechargeSlice = createSlice({
    name: 'recharge',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions.
    // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
    // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setData: (state, action: PayloadAction<any>) => {
            state.data = action.payload
        },
        setTransaction: (state, action: PayloadAction<any>) => {
            state.paymentTxns = action.payload
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload
        }
    },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { setLoading, setData, setTransaction, setError } =
    rechargeSlice.actions

//API Calls
export const getTransactionList =
    () =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                let response = await axios.get(`/wallet`)
                const data = response.data.response.data
                const transaction = response.data.response.paymentTxns
                dispatch(setData(data))
                dispatch(setTransaction(transaction))
                dispatch(setLoading(false))
            } catch (error) {
                dispatch(setError(error))
                dispatch(setLoading(false))
            }
        }

export const updateDetails =
    (id: string, val: any) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                await axios.patch(`/update-wallet/${id}`, val)
                let response = await axios.get(`/wallet`)
                const data = response.data.response.data
                const transaction = response.data.response.paymentTxns
                dispatch(setData(data))
                dispatch(setTransaction(transaction))
                dispatch(setLoading(false))
            } catch (err) {
                console.error(err)
                dispatch(setError(err))
                dispatch(setLoading(false))
            }
        }


// exporting the reducer here, as we need to add this to the store
export default rechargeSlice.reducer
