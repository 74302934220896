import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { PlusCircleIcon } from '@heroicons/react/solid'

export const Button = forwardRef(({
  disabled = false,
  children,
  className,
  onBlur = () => { },
  onClick = () => { },
  text = false,
  outline = false,
  active,
  gray,
  green,
  rounded,
  type = 'button',
}: {
  disabled?: boolean
  children: React.ReactNode
  className?: string
  onClick?: (e?: any) => void
  onBlur?: (e?: any) => void
  text?: boolean
  outline?: boolean
  active?: boolean
  gray?: boolean
  green?: boolean
  rounded?: boolean
  type?: 'button' | 'submit' | 'reset'

}, ref: any) => (
  <button
    ref={ref}
    onBlur={onBlur}
    type={type}
    disabled={disabled}
    onClick={onClick}
    className={classNames(
      className,
      //Rounded
      rounded ? 'rounded-full' : 'rounded-md',
      //Text Color
      gray
        ? !active
          ? 'text-gray-500'
          : ''
        : green
          ? 'text-greenColor'
          : active
            ? 'text-white'
            : !outline && !text
              ? 'text-white'
              : !text
                ? 'text-black'
                : 'text-blueColor',
      //Outline Button
      outline
        ? green //Green Outline
          ? 'border border-greenColor hover:bg-greenColor hover:text-white'
          : !active //Inactive Blue Outline
            ? 'border border-blueColor bg-white hover:bg-blueColor hover:text-white'
            : //Text Button
            text
              ? 'bg-none'
              : 'bg-blueColor text-white hover:bg-blueColor hover:text-white'
        : //Solid
        !text && 'bg-blueColor text-white',
      //Active Button
      'inline-flex  items-center justify-center border border-transparent '
    )}
  >
    {children}
  </button>
))

export function AddButton({
  className,
  onClick = () => { },
}: {
  className?: string
  onClick?: () => void
}) {
  return (
    <button
      onClick={onClick}
      className={classNames(
        className,
        'inline-flex items-center justify-center text-sm text-orange-500'
      )}
    >
      <span>
        <PlusCircleIcon className="h-6 w-6" />
      </span>
      <span className="ml-1 text-sm font-semibold">ADD</span>
    </button>
  )
}

export function OutlineButton({
  active = false,
  children,
  className,
  onClick = () => { },
}: {
  active?: boolean
  children: React.ReactNode
  className?: string
  onClick?: () => void
}) {
  return (
    <span
      onClick={onClick}
      className={classNames(
        active ? 'bg-cyan-500 text-white' : 'border-cyan-500 bg-white',
        'inline-flex cursor-pointer items-center rounded-md  border px-3 py-1 hover:bg-cyan-300 hover:text-white',
        className
      )}
    >
      {children}
    </span>
  )
}
