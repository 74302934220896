import axios from '../../utils/axios'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { store } from '../index'
import { notifier } from './notificationSlice'

// declaring the types for our state
export type PrescriptionState = {
  //Prescripton
  loading: Boolean
  prescriptions: any[]
  selectedPrescription: any | null
  error: any | null
  //Prescription History
  loadingHistory: Boolean
  history: any[]
  errorHistory: any | null
  //Submissions
  submissionLoading: Boolean
  submissionError: any | null
  submissionData: any[]
  //Tests Products
  tests_list: any[]
}


const initialState: PrescriptionState = {
  //Prescripton
  loading: true,
  prescriptions: [],
  selectedPrescription: null,
  error: null,
  //Prescription History
  loadingHistory: false,
  history: [],
  errorHistory: null,
  //Submissions
  submissionLoading: false,
  submissionError: null,
  submissionData: [],
  //Tests Products
  tests_list: []

}

export const PrescriptionSlice = createSlice({
  name: 'prescriptions',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions.
  // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
  // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
  reducers: {
    //Prescripton
    setLoading: (state, action: PayloadAction<Boolean>) => {
      state.loading = action.payload
    },
    setPrescriptions: (state, action: PayloadAction<any[]>) => {
      state.prescriptions = action.payload
    },
    setSelectedPrescription: (state, action: PayloadAction<any>) => {
      state.selectedPrescription = action.payload
      state.history = !action.payload ? [] : state.history
    },
    updateSelectedPrescription: (state, action: PayloadAction<any>) => {
      state.selectedPrescription = {
        ...(state.selectedPrescription || {}),
        ...action.payload,
        patientId: state.selectedPrescription?.patientId,
        symptomsId: state.selectedPrescription?.symptomsId,
      }
    },
    setPatient: (state, action: PayloadAction<any>) => {
      state.selectedPrescription = {
        ...state.selectedPrescription,
        patientId: action.payload,
      }
    },
    setSymptoms: (state, action: PayloadAction<any>) => {
      state.selectedPrescription = {
        ...state.selectedPrescription,
        symptomsId: action.payload,
      }
    },

    setError: (state, action: PayloadAction<any>) => {
      state.error = action.payload
    },
    //Prescription History
    setLoadingHistory: (state, action: PayloadAction<Boolean>) => {
      state.loadingHistory = action.payload
    },
    setHistory: (state, action: PayloadAction<any[]>) => {
      state.history = action.payload
    },
    setErrorHistory: (state, action: PayloadAction<any>) => {
      state.errorHistory = action.payload
    },
    //Submissions
    setSubmissionLoading: (state, action: PayloadAction<Boolean>) => {
      state.submissionLoading = action.payload
    },
    setSubmissionError: (state, action: PayloadAction<any>) => {
      state.submissionError = action.payload
    },
    setSubmissionData: (state, action: PayloadAction<any>) => {
      state.submissionData = action.payload
    },
    //Tests 
    setTestsList: (state, action: PayloadAction<any>) => {
      state.tests_list = action.payload
    },
  },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const {
  //Prescripton
  setLoading,
  setPrescriptions,
  setSelectedPrescription,
  setError,
  updateSelectedPrescription,
  setPatient,
  setSymptoms,
  //Prescription History
  setLoadingHistory,
  setHistory,
  setErrorHistory,
  //Submissions
  setSubmissionLoading,
  setSubmissionError,
  setSubmissionData,
  //Tests
  setTestsList
} = PrescriptionSlice.actions

//API Calls
export const getConsultationData =
  (id: string) =>
    async (dispatch: (arg0: { payload: any; type: string }) => void) => {
      try {
        dispatch(setLoading(true))
        const response = await axios.get(`/prescription/${id}`)
        const prescription = response.data.response.data
        console.log({ prescription })
        dispatch(setSelectedPrescription(prescription))
        dispatch(setLoading(false))
        return prescription //For outside use as a promise
      } catch (err) {
        console.error(err)
        dispatch(setError(err))
        dispatch(setLoading(false))
      }
    }

export const getConsultationHistory =
  () => async (dispatch: (arg0: { payload: any; type: string }) => void) => {
    try {
      dispatch(setLoadingHistory(true))
      const prescription = store.getState().prescription.selectedPrescription
      const response = await axios.get(
        `/consultation_history/${prescription._id}`
      )
      const prescriptions = response.data.response.data
      dispatch(setHistory(prescriptions))
      dispatch(setLoadingHistory(false))
    } catch (err) {
      console.error(err)
      dispatch(setLoadingHistory(false))
    }
  }

export const getPrescriptions =
  (type: 'review' | 'reviewed') => async (dispatch: any) => {
    try {
      let query = ''
      if (type === 'review') query = '?status=Review'
      if (type === 'reviewed') query = '?status=Reviewed'
      dispatch(setLoading(true))
      const response = await axios.get('/prescription/' + query)
      const data = response.data.response.data
      dispatch(setPrescriptions(data))
      dispatch(setSelectedPrescription(data[0]))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setError(error))
      dispatch(setLoading(false))
    }
  }

export const updatePatient =
  (id: string, data: any) =>
    async (dispatch: (arg0: { payload: any; type: string }) => void) => {
      try {
        data.patientId = id
        const response = await axios.patch('/patients', data)
        const patient = response.data.response.data
        console.log({ patient })
        dispatch(setPatient(patient))
        notifier.success(response.data.response.msg)
      } catch (error) {
        console.error(error)
        dispatch(setError(error))
      }
    }

export const registerPatient =
  (data: any) =>
    async (dispatch: (arg0: { payload: any; type: string }) => void) => {
      try {
        dispatch(setLoading(true))
        var res = await axios.post('/camp/patients', data)
        var pres: any = res.data.response.data
        let response = await axios.get(`/prescription/${pres._id}`)
        let prescription = response.data.response.data
        dispatch(setSelectedPrescription(prescription))
        dispatch(setLoading(false))
        notifier.success(res.data.response.msg)
      } catch (error) {
        console.error(error)
        dispatch(setLoading(false))
        dispatch(setError(error))
      }
    }

export const updatePrescription =
  (id: string, data: any) =>
    async (dispatch: (arg0: { payload: any; type: string }) => void) => {
      try {
        const response = await axios.patch('/prescription/' + id, data)
        const prescription = response.data.response.data
        console.log({ prescription })
        dispatch(updateSelectedPrescription(prescription))
      } catch (error) {
        console.error(error)
        dispatch(setError(error))
      }
    }

export const addDocData =
  (type: string, data: any) =>
    async (dispatch: (arg0: { payload: any; type: string }) => void) => {
      try {
        const prescription = store.getState().prescription.selectedPrescription
        const response = await axios.post(`/prescription/add/${type}`, {
          prescriptionId: prescription._id,
          userId: prescription.userId,
          productCode: prescription.productCode,
          ...data,
        })
        dispatch(updateSelectedPrescription(response.data.response.data))
        notifier.success(response.data.response.msg)
      } catch (error) {
        console.error(error)
        dispatch(setError(error))
      }
    }

export const updateDocData =
  (type: string, data: any) =>
    async (dispatch: (arg0: { payload: any; type: string }) => void) => {
      try {
        const prescription = store.getState().prescription.selectedPrescription
        const response = await axios.patch(`/prescription/add/${type}`, {
          prescriptionId: prescription._id,
          userId: prescription.userId,
          productCode: prescription.productCode,
          ...data,
        })
        dispatch(updateSelectedPrescription(response.data.response.data))
        notifier.success(response.data.response.msg)
      } catch (error) {
        console.error(error)
        dispatch(setError(error))
      }
    }

export const deleteDocData =
  (type: string, data: any) =>
    async (dispatch: (arg0: { payload: any; type: string }) => void) => {
      try {
        const prescription = store.getState().prescription.selectedPrescription
        const response = await axios.post(`/prescription/delete/${type}`, {
          prescriptionId: prescription._id,
          userId: prescription.userId,
          productCode: prescription.productCode,
          ...data,
        })
        dispatch(updateSelectedPrescription(response.data.response.data))
        notifier.success(response.data.response.msg)
      } catch (error) {
        console.error(error)
        dispatch(setError(error))
      }
    }

export const updateMedicalRecord =
  (data: any) => async (dispatch: (arg0: any) => void) => {
    try {
      const prescription = store.getState().prescription.selectedPrescription
      const response = await axios.patch(`/patients`, {
        patientId: prescription.patientId._id,
        medicalRecords: data,
      })
      dispatch(setPatient(response.data.response.data))
      notifier.success(response.data.response.msg)
    } catch (error) {
      console.error(error)
      dispatch(setError(error))
    }
  }

export const updateSymptoms =
  (symptoms: any[], otherSymptoms: any[]) =>
    async (dispatch: (arg0: any) => void) => {
      try {
        const prescription = store.getState().prescription.selectedPrescription
        const symptom_response = await axios.post(`/patientSymptoms`, {
          prescriptionId: prescription._id,
          otherUnderlyingConditions: otherSymptoms,
          hopi_symptoms: symptoms,
        })
        const symptomsDoc = symptom_response.data.response.data
        dispatch(setSymptoms(symptomsDoc))
        dispatch(
          updatePrescription(prescription._id, { symptomsId: symptomsDoc })
        )
        notifier.success(symptom_response.data.response.msg)
      } catch (error) {
        console.error(error)
        dispatch(setError(error))
      }
    }

//Folowups
export const getSubmissionReport =
  (prescription: any) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true))
      const response = await axios.patch('/followup/submissions/report', {
        prescriptionId: prescription._id,
        days: 11,
      })
      const data = response.data.response.data
      dispatch(setSubmissionData(data))
      dispatch(setLoading(false))
    } catch (error) {
      dispatch(setError(error))
    }
  }

//Deactivation
export const deactivatePrescription = () => async (dispatch: any) => {
  try {
    const prescription = store.getState().prescription.selectedPrescription
    const response = await axios.patch(
      `/prescription/deactivate/${prescription._id}`
    )
    const data = response.data.response.data
    dispatch(updateSelectedPrescription(data))
    notifier.success(response.data.response.msg)
  } catch (error) {
    console.error(error)
    dispatch(setError(error))
  }
}

export const getTestProducts = () => async (dispatch) => {
  try {
    // const prescription = store.getState().prescription.selectedPrescription
    const response = await axios.get(`/product?productType=test`)
    const data = response.data.response.data
    dispatch(setTestsList(data))
    // notifier.success(response.data.response.msg)
  } catch (error) {
    console.error(error)
    // dispatch(setError(error))
  }
}

export const addVitalTest = (productId) => async (dispatch) => {
  try {
    const prescription = store.getState().prescription.selectedPrescription
    const response = await axios.patch(`/prescription/addon/add`, {
      prescriptionId: prescription._id,
      productId
    })
    const data = response.data.response.data
    dispatch(updateSelectedPrescription(data))
    notifier.success(response.data.response.msg)
  } catch (error) {
    console.error(error)
    // dispatch(setError(error))
  }
}

export const removeVitalTest = (productId) => async (dispatch) => {
  try {
    const prescription = store.getState().prescription.selectedPrescription
    const response = await axios.patch(`/prescription/addon/remove`, {
      prescriptionId: prescription._id,
      productId
    })
    const data = response.data.response.data
    dispatch(updateSelectedPrescription(data))
    notifier.success(response.data.response.msg)
  } catch (error) {
    console.error(error)
    // dispatch(setError(error))
  }
}


// exporting the reducer here, as we need to add this to the store
export default PrescriptionSlice.reducer
