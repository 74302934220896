import * as Yup from 'yup'

export const educationInitialState = {
  college_name: '',
  degree: '',
  start_date: null,
  end_date: null,
}

export const educationvalidationSchema = Yup.object().shape({
  college_name: Yup.string()
    .required('college name is required')
    .max(80, 'college name should not exceed 80 characters'),
  degree: Yup.string()
    .required('degree is required')
    .max(80, 'degree should not exceed 80 characters'),
  start_date: Yup.date()
    .typeError('start date should be a valid date')
    .required('start date is required'),
  end_date: Yup.date()
    .typeError('end date should be a valid date')
    .required('end date is required')
    .min(Yup.ref('start_date'), 'end date should be greater than start date'),
})
