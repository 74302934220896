import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from '../../utils/axios'

// declaring the types for our state
export type SliderState = {
  profileSlider: {
    show: boolean
    data: any
  }
  symptomsSlider: {
    show: boolean
    data: any
  }
  medicalRecordSlider: {
    show: boolean
    data: any
  }
  vitalsSlider: {
    show: boolean
    data: any
  }
  noteSlider: {
    show: boolean
    data: any
  }
  medicineScheduleSlider: {
    show: boolean
    data: any
  }
  healthIndicatorSlider: {
    show: boolean
    data: any
  }
  experienceSlider: {
    show: boolean
    data: any
  }
  educationSlider: {
    show: boolean
    data: any
  }
  diagnosticSlider: {
    show: boolean
    data: any
  }
}

const initialState: SliderState = {
  symptomsSlider: {
    show: false,
    data: null,
  },
  profileSlider: {
    show: false,
    data: null,
  },
  medicalRecordSlider: {
    show: false,
    data: null,
  },
  vitalsSlider: {
    show: false,
    data: null,
  },
  noteSlider: {
    show: false,
    data: null,
  },
  medicineScheduleSlider: {
    show: false,
    data: null,
  },
  healthIndicatorSlider: {
    show: false,
    data: null,
  },
  experienceSlider: {
    show: false,
    data: null,
  },
  educationSlider: {
    show: false,
    data: null,
  },
  diagnosticSlider: {
    show: false,
    data: null,
  },
}

export const sliderSlice = createSlice({
  name: 'slider',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions.
  // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
  // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
  reducers: {
    toggleProfileSlider: (state, action: PayloadAction<any>) => {
      state.profileSlider.show = !state.profileSlider.show
      state.profileSlider.data = action.payload
    },
    toggleSymptomsSlider: (state, action: PayloadAction<any>) => {
      state.symptomsSlider.show = !state.symptomsSlider.show
      state.symptomsSlider.data = action.payload
    },
    toggleMedicalRecordSlider: (state, action: PayloadAction<any>) => {
      state.medicalRecordSlider.show = !state.medicalRecordSlider.show
      state.medicalRecordSlider.data = action.payload
    },
    toggleVitalsSlider: (state, action: PayloadAction<any>) => {
      state.vitalsSlider.show = !state.vitalsSlider.show
      state.vitalsSlider.data = action.payload
    },
    toggleNoteSlider: (state, action: PayloadAction<any>) => {
      state.noteSlider.show = !state.noteSlider.show
      state.noteSlider.data = action.payload
    },
    toggleMedicineScheduleSlider: (state, action: PayloadAction<any>) => {
      state.medicineScheduleSlider.show = !state.medicineScheduleSlider.show
      state.medicineScheduleSlider.data = action.payload
    },
    toggleHealthIndicatorSlider: (state, action: PayloadAction<any>) => {
      state.healthIndicatorSlider.show = !state.healthIndicatorSlider.show
      state.healthIndicatorSlider.data = action.payload
    },
    toggleExperienceSlider: (state, action: PayloadAction<any>) => {
      state.experienceSlider.show = !state.experienceSlider.show
      state.experienceSlider.data = action.payload
    },
    toggleEducationSlider: (state, action: PayloadAction<any>) => {
      state.educationSlider.show = !state.educationSlider.show
      state.educationSlider.data = action.payload
    },
    toggleDiagnosticSlider: (state, action: PayloadAction<any>) => {
      state.diagnosticSlider.show = !state.diagnosticSlider.show
      state.diagnosticSlider.data = action.payload
    },
    closeAllSliders: (state) => {
      state.profileSlider.show = false
      state.symptomsSlider.show = false
      state.medicalRecordSlider.show = false
      state.vitalsSlider.show = false
      state.noteSlider.show = false
      state.medicineScheduleSlider.show = false
      state.healthIndicatorSlider.show = false
      state.experienceSlider.show = false
      state.educationSlider.show = false
      state.diagnosticSlider.show = false
    },
  },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const {
  closeAllSliders,
  toggleProfileSlider,
  toggleSymptomsSlider,
  toggleMedicalRecordSlider,
  toggleVitalsSlider,
  toggleNoteSlider,
  toggleMedicineScheduleSlider,
  toggleHealthIndicatorSlider,
  toggleExperienceSlider,
  toggleEducationSlider,
  toggleDiagnosticSlider,
} = sliderSlice.actions

// exporting the reducer here, as we need to add this to the store
export default sliderSlice.reducer
