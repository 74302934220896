import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { format } from 'date-fns'
import React from 'react'
import { useAppDispatch } from '../../../hooks/home'
import { toggleHealthIndicatorModal } from '../../../store/slices/modalSlice'
import PatientFeed from '../shared/PatientFeed'
import { Button } from '../../buttons/button'

export default function HealthIndicatorModal() {
  const dispatch = useAppDispatch()

  const toggle = () => {
    dispatch(toggleHealthIndicatorModal(null))
  }

  return (
    <Dialog.Panel className="relative h-64 w-screen max-w-lg transform overflow-hidden rounded-lg bg-white p-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
      <div className="flex h-full flex-col">
        <div className="flex items-center justify-between">
          <span className="font-semibold">Health Indicator:</span>
          <span className="cursor-pointer rounded-full bg-green-400 p-1 text-white">
            <XIcon onClick={toggle} className="h-3 w-3" />
          </span>
        </div>
        <div className="flex-grow p-0"></div>
        <div className="mt-auto text-center">
          <Button className="px-5">View Submission</Button>
        </div>
      </div>
    </Dialog.Panel>
  )
}
