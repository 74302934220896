import Link from "next/link";
import { useRouter } from "next/router";
// import Burger from "./Burger";
import { useState } from "react";
import classNames from "classnames";
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { toggleComingSoon } from '../store/slices/modalSlice'
import { useAppDispatch } from "../hooks/home";
import Image from "next/image";
export default function Navigation() {
    const router = useRouter();
    const [active, setActive] = useState(false);
    const dispatch = useAppDispatch()




    return (
        <div>
            <Disclosure as="nav" className="bg-white shadow py-1">
                {({ open }) => (
                    <>
                        <div className="max-w-[1400px] mx-auto px-4 sm:px-6 lg:px-2">
                            <div className="flex justify-between h-16">
                                <div className="flex">
                                    <div className="flex-shrink-0 flex items-center">
                                        <Link href="/">
                                            <a>

                                            <Image
                                                src="/images/logo1.png"
                                                layout="intrinsic"
                                                height={130}
                                                width={130}
                                                objectFit="contain"
                                                alt="logo1" />
                                                {/* <img src="/images/logo1.png" alt="Eonmed" className="img-fluid" width="130" /> */}
                                            </a>
                                        </Link>
                                    </div>

                                </div>
                                <div className="hidden sm:ml-6 sm:flex sm:items-center overflow-auto">
                                    <ul className="flex space-x-10">
                                        <div className="relative group">
                                            <button className="flex flex-row items-center md:inline">
                                                <span className="flex">Products<ChevronDownIcon className="h-5 w-5 mt-0.5" /></span>
                                            </button>
                                            <div className="absolute z-10 hidden w-fit bg-grey-200 group-hover:block">

                                                <div className="px-2 pt-2 pb-4 bg-white w-max shadow-lg">
                                                    <div>
                                                        <ul className="space-y-2">
                                                            <li><Link href="/primary-health-care" passHref><a>Primary HealthCare</a></Link></li>
                                                            <li><Link href="/doctor-consultancy" passHref><a>Doctor Consultancy</a></Link></li>
                                                            <li><Link href="/covid-care-plus" passHref><a>Covid Care Plus</a></Link></li>
                                                            <li onClick={() => dispatch(toggleComingSoon())}><Link href="" passHref><a>Covid Self Test</a></Link></li>
                                                            <li><Link href="/smart-clinic" passHref><a>Smart Clinic</a></Link></li>
                                                            <li><Link href="/docqr" passHref><a>DOCQR</a></Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="relative group">
                                            <button className="flex flex-row items-center md:inline">
                                                <span className="flex">Our Initiatives<ChevronDownIcon className="h-5 w-5 mt-0.5" /></span>
                                            </button>
                                            <div className="absolute z-10 hidden w-fit bg-grey-200 group-hover:block">

                                                <div className="px-2 pt-2 pb-4 bg-white w-max shadow-lg">
                                                    <div>
                                                        <ul className="space-y-2">
                                                            <li><Link href="/healthcare-corporate-camps" passHref><a>Healthcare Corporate Camps</a></Link></li>
                                                            <li><Link href="/school-healthcare-camps" passHref><a>Healthcare School Camps</a></Link></li>
                                                            <li><Link href="/mass-covid-test" passHref><a>Mass Covid Testing</a></Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <li><Link href="/blog" passHref><a>Blogs</a></Link></li>
                                        <li><Link href="https://user.eonmed.in/" passHref><a>Consult Now</a></Link></li>
                                        {/* <Link href="https://medicalmart.eonmed.in/" passHref><a>Medical Mart</a></Link> */}
                                        <li onClick={() => dispatch(toggleComingSoon())}><Link href={''} passHref><a>Medical Mart</a></Link></li>
                                        <li><Link href="/smart-clinic" passHref><a>Franchisee-Enquiry</a></Link></li>
                                        <li><Link href="/about-us" passHref><a>About Us</a></Link></li>
                                        <li><Link href="/contact-us" passHref><a>Contact Us</a></Link></li>
                                        <li><Disclosure.Button as="a" href="/platform/dashboard" >
                                            <a>Sign In</a>
                                        </Disclosure.Button>
                                            {/* <Link href="/platform/dashboard" as="/platform/dashboard" prefetch={false}><a>Sign In</a></Link> */}
                                        </li>
                                        <li><Disclosure.Button as="a" href="/platform/auth/signup" >
                                            <a>Register as a Doctor</a>
                                        </Disclosure.Button>
                                            {/* <Link href="/platform/dashboard" as="/platform/dashboard" prefetch={false}><a>Sign In</a></Link> */}
                                        </li>
                                    </ul>
                                </div>
                                <div className="-mr-2 flex items-center sm:hidden">
                                    {/* Mobile menu button */}
                                    <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                        <span className="sr-only">Open main menu</span>
                                        {open ? (
                                            <XIcon className="block h-6 w-6" aria-hidden="true" />
                                        ) : (
                                            <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                                        )}
                                    </Disclosure.Button>
                                </div>
                            </div>
                        </div>

                        <Disclosure.Panel className="sm:hidden">
                            <div className="pt-2 pb-3 space-y-1">
                                <Disclosure as="div" className='px-4'>
                                    {({ open }) => (
                                        <>
                                            <dt className="text-lg">
                                                <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                                                    <span className="flex">Products<ChevronDownIcon className="h-5 w-5 mt-0.5" /></span>
                                                </Disclosure.Button>
                                            </dt>
                                            <Disclosure.Panel as="dd" className="mt-2 pr-12 border-2">


                                                <ul className="space-y-2">
                                                    <li><a href="/primary-health-care">Primary HealthCare</a></li>
                                                    <li><a href="/doctor-consultancy">Doctor Consultancy</a></li>
                                                    <li><a href="/covid-care-plus">Covid Care Plus</a></li>
                                                    <li onClick={() => dispatch(toggleComingSoon())}><a href={''}>Covid Self Test</a></li>
                                                    <li><a href="/smart-clinic">Smart Clinic</a></li>
                                                    <li><a href="/docqr">DOCQR</a></li>
                                                </ul>

                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                                <Disclosure as="div" className='px-4 py-1'>
                                    {({ open }) => (
                                        <>
                                            <dt className="text-lg">
                                                <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                                                    <span className="flex">Our Initiatives<ChevronDownIcon className="h-5 w-5 mt-0.5" /></span>
                                                </Disclosure.Button>
                                            </dt>
                                            <Disclosure.Panel as="dd" className="mt-2 pr-12 border-2">
                                                <ul className="space-y-2">
                                                    <li><a href="/healthcare-corporate-camps">Healthcare Corporate Camps</a></li>
                                                    <li><a href="/school-healthcare-camps">Healthcare School Camps</a></li>
                                                    <li><a href="/mass-covid-test">Mass Covid Testing</a></li>
                                                </ul>

                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                                <Disclosure.Button
                                    as="a"
                                    href="/blog"
                                    className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                                >
                                    <a>Blogs</a>
                                </Disclosure.Button>
                                <Disclosure.Button
                                    as="a"
                                    href="https://user.eonmed.in/"
                                    className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                                >
                                    <a>Consult Now</a>
                                </Disclosure.Button>
                                <Disclosure.Button
                                    //as="a"
                                    //href=""
                                    onClick={() => dispatch(toggleComingSoon())}
                                    //href="https://medicalmart.eonmed.in/"
                                    className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                                >
                                    <a>Medical Mart</a>
                                </Disclosure.Button>
                                <Disclosure.Button
                                    as="a"
                                    href="/smart-clinic"
                                    className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                                >
                                    <a>Franchisee-Enquiry</a>
                                </Disclosure.Button>
                                <Disclosure.Button
                                    as="a"
                                    href="/about-us"
                                    className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                                >
                                    <a>About Us</a>
                                </Disclosure.Button>
                                <Disclosure.Button
                                    as="a"
                                    href="/contact-us"
                                    className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                                >
                                    <a>Contact Us</a>
                                </Disclosure.Button>
                                <Disclosure.Button
                                    as="a"
                                    href="/platform/dashboard"
                                    className="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                                >
                                    <a>Sign In</a>
                                </Disclosure.Button>
                            </div>

                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>

        </div>
    );
}
