import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from '../../../utils/axios'

// declaring the types for our state
export type MedOrderStatusState = {
    loading: boolean
    error: any | null
    orderStatusList: any[]
    selectedOrderStatus: any | null
}

const initialState: MedOrderStatusState = {
    error: null,
    loading: true,
    orderStatusList: [],
    selectedOrderStatus: null
}

export const medProfileSlice = createSlice({
    name: 'medorderstatus',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions.
    // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
    // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setData: (state, action: PayloadAction<any>) => {
            state.orderStatusList = action.payload
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = action.payload
        },
        setSelectedOrderStatus: (state, action: PayloadAction<any>) => {
            state.selectedOrderStatus = action.payload
        },
    },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { setLoading, setData, setError, setSelectedOrderStatus } =
    medProfileSlice.actions

//API Calls
export const getOrderStatus =
    (type: string, date = null) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                var response: any = undefined
                if (date) {
                    response = await axios.get(`/prescription?${type}=true&date=${date}`)
                } else {
                    response = await axios.get(`/prescription?${type}=true`)
                }
                const data = response.data.response.data
                dispatch(setData(data))
                dispatch(setSelectedOrderStatus(data[0]))
                dispatch(setLoading(false))
            } catch (error) {
                dispatch(setError(error))
                dispatch(setLoading(false))
            }
        }


// exporting the reducer here, as we need to add this to the store
export default medProfileSlice.reducer
