import React from 'react'
import classNames from 'classnames'
import Avatar from '../../../components/doctors/shared/Avatar'
import { format } from 'date-fns'
import { togglemedStorePrescription } from '../../../store/slices/modalSlice'
import { useAppDispatch } from '../../../hooks/home'

const QrCardSearch = ({
    qrScanData,
}: {
    qrScanData: any
}) => {

    const dispatch = useAppDispatch()

    const openPrescriptionModal = (pdflink: any) => {
        dispatch(togglemedStorePrescription([pdflink[0], pdflink[1]]))
    }

    return (
        <div
            className='card rounded-md border bg-white p-3 cursor-pointer'
            onClick={() => { openPrescriptionModal([qrScanData.prescription_pdf, qrScanData._id]) }}
        >
            <div className='flex justify-between'>
                <div className="flex space-x-2 items-center">
                    <div
                        className={classNames('bg-cyan-100',
                            'mr-3 inline-flex h-8 w-8 items-center justify-center rounded-full p-6 shadow'
                        )}
                    >
                        {qrScanData.patients?.firstName?.slice(0, 1)}
                        {qrScanData.patients?.lastName?.slice(0, 1)}
                    </div>
                    <div className="flex flex-col">
                        <span className="text-lg font-bold text-blueColor">{qrScanData.patients?.firstName + " " + qrScanData.patients?.lastName}</span>
                        <span className="text-xs text-[#000000]">+91 {qrScanData.patients?.phone}</span>
                    </div>
                </div>
                <span className='text-[#000000] text-sm'>{qrScanData.createdAt ? format(new Date(qrScanData.createdAt), 'MMM dd, yyyy h:mm a') : ''}</span>
            </div>
            <div className="mt-3 flex justify-between">
                {qrScanData.isVideoConsultationDone == true &&
                    <p className='bg-[#03B4EA33] text-[#175069] py-2 px-4 rounded-md'>Consultation Completed</p>
                }
                {qrScanData.isVideoConsultationDone == false &&
                    <p className='bg-[#BF4B4B33] text-[#5C2323] py-2 px-4 rounded-md'>Consultation Not Completed</p>
                }
                {qrScanData.isPaid == true &&
                    <p className='bg-[#6ABF4B33] text-[#325C23] py-2 px-4 rounded-md'>Payment Completed</p>
                }
                {qrScanData.isPaid == false &&
                    <p className='bg-[#BF4B4B33] text-[#5C2323] py-2 px-4 rounded-md'>Payment Not Completed</p>
                }
            </div>
        </div>
    )
}

export default QrCardSearch
