import React from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function MediaShared({ prescription }) {
    if (!prescription) {
        return null;
    }
    return (
        <div onClick={e => e.stopPropagation()} className="z-40">
            <Carousel className='h-full w-full' showStatus={false} showThumbs={false} >
                {prescription?.mediaShared.map((media) =>
                    media.type?.includes("audio")
                        ?
                        (
                            <div className="px-5">
                                <audio controls >
                                    <source src={`${process.env.API_URL}/download/video/${prescription._id}-${media._id}`} type={media.type} />
                                </audio>
                            </div>
                        ) :
                        (
                            <div className="px-5">
                                <video width="100%" controls >
                                    <source src={`${process.env.API_URL}/download/video/${prescription._id}-${media._id}`} type={media.type} />
                                </video>
                            </div>
                        ))}
            </Carousel>
        </div>

    )
}