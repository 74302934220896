import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from '../../../utils/axios'

// declaring the types for our state
export type ClientProductState = {
    loading: boolean
    error: any | null
    data: {
        products: any[]
        tests: any[]
        clientProduct: any
    }

}

const initialState: ClientProductState = {
    error: null,
    loading: true,
    data: {
        products: [],
        tests: [],
        clientProduct: {}
    }
}

export const clientProductSlice = createSlice({
    name: 'clientproduct',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions.
    // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
    // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setProductData: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data.products = action.payload
        },
        setTestData: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data.tests = action.payload
        },
        setClientProductData: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data.clientProduct = action.payload
        },
        setError: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.error = action.payload
        }
    },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { setLoading, setProductData, setTestData, setClientProductData, setError } =
    clientProductSlice.actions

//API Calls
export const getProductList =
    () =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                const response = await axios.get(`client/product`)
                const data = response.data.response.data
                dispatch(setProductData(data))
            } catch (error) {
                dispatch(setError(error))
            }
        }

export const getTestList =
    (id: any) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                const response = await axios.get(`/orgs/${id}?tests=true`)
                const data = response.data.response.data.tests
                dispatch(setTestData(data))
            } catch (error) {
                dispatch(setError(error))
            }
        }

export const getClientProductDetail =
    (id: any) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                let response = await axios.get(`/client/product?productId=${id}`)
                const data = response.data.response.data[0]
                dispatch(setClientProductData(data))
            } catch (error) {
                dispatch(setError(error))
            }
        }
//-----------------------------------------Api Call fOr Get Product Search Detail---------------------------------------------------- 
export const getProductSearchList =
    (Query: any) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                const response = await axios.get(`client/product`)
                const data = response.data.response.data
                console.log("daata", data)

                let obj = data.filter(o => o.name.toLowerCase().includes(Query.toLowerCase()));
                if (obj) {
                    dispatch(setProductData(obj))
                } else {
                    dispatch(setProductData([]))
                }

            } catch (error) {
                dispatch(setError(error))
            }
        }
//-----------------------------------------Api Call fOr Get Test Search Details---------------------------------------------------- 
export const getTestSearchList =
    (id: any, Query: any) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                const response = await axios.get(`/orgs/${id}?tests=true`)
                const data = response.data.response.data.tests
                let obj = data.filter(o => o.id.testName.toLowerCase().includes(Query.toLowerCase()));
                console.log("obj", obj)
                if (obj) {
                    dispatch(setTestData(obj))
                } else {
                    dispatch(setTestData([]))
                }

            } catch (error) {
                dispatch(setError(error))
            }
        }




// exporting the reducer here, as we need to add this to the store
export default clientProductSlice.reducer
