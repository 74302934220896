import { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Header from './Header'
import { useRouter } from 'next/router'
import axios from '../../utils/axios'
import SearchPatients from '../SearchBar'
import NotificationContainer from './shared/NotificationContainer'
import Modals from './modals/Modals'
import DiagnosticSearchModel from './DiagnosticSearchModel'
import { useAppDispatch, useAppSelector } from '../../hooks/home'
import { setModal } from '../../store/slices/ClientSlices/clientDiagnosticSlice'

export default function ClientLayout({
  children,
}: {
  children: React.ReactNode
}) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const { pathname } = useRouter()
  const dispatch = useAppDispatch()
  const [patientList, setPatientList] = useState([])
  const [diagnosticList, setDiagnosticList] = useState([])
  const [search, setSearch] = useState('')
  const [open, setOpen] = useState(true)
  const authState = useAppSelector(state => state.auth)

  useEffect(() => {
    if (!search || pathname.includes('/diagnostics')) return
    axios
      .get('doctor/search/patient?name=' + search)
      .then((response) => {
        const data = response.data.response.data
        setPatientList(data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [search])

  useEffect(() => {
    if (!search || !pathname.includes('/diagnostics')) return
    axios
      .patch('testsByFilter',
        {
          "city": authState?.user?.city,
          "category": "",
          "testName": search
        })
      .then((response) => {
        const data = response.data.response.data
        console.log("diagnostic", data)
        setDiagnosticList(data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [search])


  useEffect(() => {
    if (search) {
      dispatch(setModal(true))
    }

  }, [search])

  useEffect(() => {
    setSearch('')
  }, [pathname])

  return (
    <>
      <div className="flex h-screen flex-col">
        {!pathname.includes('/auth/') &&
          !pathname.match('/platform/consultation') && (
            <Header
              search={search}
              setSearch={setSearch}
              mobileMenuOpen={mobileMenuOpen}
              setMobileMenuOpen={setMobileMenuOpen}
            />
          )}

        {/* Hide Header */}
        <div className="flex flex-1 flex-row overflow-hidden">
          {/* Hide sidebar */}
          {!pathname.includes('/auth/') &&
            !pathname.match('/platform/consultation') && (
              <Sidebar
                mobileMenuOpen={mobileMenuOpen}
                setMobileMenuOpen={setMobileMenuOpen}
              />
            )}

          {/* Main content */}
          <div className="flex flex-1 items-stretch overflow-hidden">
            <main className="flex-1 overflow-y-auto">
              {/* Primary column */}
              <section
                aria-labelledby="primary-heading"
                className="flex h-full min-w-0 flex-1 flex-col lg:order-last"
              >
                {(
                  <>
                    {
                      children
                    }
                    {search && pathname.includes('/diagnostics') &&
                      <DiagnosticSearchModel {...{ open, setOpen }} setSearch={setSearch} search={search} diagnosticList={diagnosticList} />
                    }
                  </>
                )}

              </section>
            </main>
          </div>
        </div>
        <Modals />
        <NotificationContainer />
      </div>
    </>
  )
}
