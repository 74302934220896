import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from '../../utils/axios'
import { notifier } from './notificationSlice'

// declaring the types for our state
export type DoctorState = {
  loading: boolean
  error: any | null
  doctor: any
}

const initialState: DoctorState = {
  error: null,
  loading: true,
  doctor: null,
}

export const doctorSlice = createSlice({
  name: 'doctor',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions.
  // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
  // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setData: (state, action: PayloadAction<any>) => {
      state.loading = false
      state.doctor = action.payload
      //Update in Session
    },
    setError: (state, action: PayloadAction<any>) => {
      state.loading = false
      state.error = action.payload
    },
  },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { setLoading, setData, setError } = doctorSlice.actions

//API Calls
export const getDoctor =
  (id: string) =>
    async (dispatch: (arg0: { payload: any; type: string }) => void) => {
      try {
        dispatch(setLoading(true))
        const response = await axios.get(`/doctor/${id}`)
        const data = response.data.response.data
        dispatch(setData(data))
      } catch (error) {
        dispatch(setError(error))
      }
    }

export const updateDoctor =
  (id: string, data: any) =>
    async (dispatch: (arg0: { payload: any; type: string }) => void) => {
      try {
        dispatch(setLoading(true))
        const response = await axios.patch(`/doctor/${id}`, data)
        const resData = response.data.response.data
        dispatch(setData(resData))
        notifier.success(response.data.response.msg)
        dispatch(setLoading(false))
      } catch (error) {
        dispatch(setError(error))
      }
    }

export const changeDoctorProfile =
  (type: string, action: string, data: any) =>
    async (dispatch: (arg0: { payload: any; type: string }) => void) => {
      try {
        const response = await axios.patch(
          `/doctor/profile/${type}/${action}`,
          data
        )
        const resData = response.data.response.data
        dispatch(setData(resData))
        notifier.success(response.data.response.msg)
      } catch (error) {
        dispatch(setError(error))
      }
    }

export const changePassword =
  (data: any) =>
    async (dispatch: (arg0: { payload: any; type: string }) => void) => {
      try {
        const response = await axios.patch(`/doctor/settings/changePassword`, data)
        notifier.success(response.data.response)
      } catch (error) {
        dispatch(setError(error))
        notifier.error("Old password is incorrect, Please provide the correct password !")
      }
    }

// exporting the reducer here, as we need to add this to the store
export default doctorSlice.reducer
