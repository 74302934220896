import React, { useCallback, useEffect } from 'react'
import { Dialog, Disclosure, Popover } from '@headlessui/react'
import SliderHeader from '../SliderHeader'
import { XIcon } from '@heroicons/react/solid'
import { useAppDispatch, useAppSelector } from '../../../hooks/home'
import { toggleMedicineScheduleSlider } from '../../../store/slices/sliderSlice'
import { Button } from '../../buttons/button'
import { frequency, interval, medicineTypeList, time_slots } from '../config'
import { addDays, format } from 'date-fns'
import { symptom_list, weekDaysList } from '../../../utils/constants'
import { useFormik } from 'formik'
import {
  adviceInitialState,
  advicevalidationSchema,
} from '../../../functionality/addAdvice'
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'
import { getMedicineList } from '../../../store/slices/utilSlice'
import Input from '../../../Comp/Input'
import FormErrorMessage from '../shared/FormErrorMessage'
import {
  addDocData,
  updateDocData,
} from '../../../store/slices/prescriptionSlice'
import { Calendar } from 'react-date-range';

const MedicineScheduleSlider = () => {
  const dispatch = useAppDispatch()
  const show = useAppSelector(
    (state) => state.slider.medicineScheduleSlider.show
  )
  const editState = useAppSelector(
    (state) => state.slider.medicineScheduleSlider.data
  )
  const medicineList: any = useAppSelector((state) => state.util.medicines.list)
  const prescription: any = useAppSelector((state) => state.prescription.selectedPrescription)

  const toggle = () => {
    dispatch(toggleMedicineScheduleSlider(null))
  }
  useEffect(() => {
    dispatch(getMedicineList())
  }, [])

  const onSubmit = useCallback(
    (values) => {
      (async () => {
        try {
          console.log('values', values)
          if (editState) {
            await dispatch(updateDocData('medicines', { medicine: values }))
          } else {
            await dispatch(addDocData('medicines', { medicine: values }))
          }
          toggle()
        } catch (err) {
          console.error(err)
        }
      })();
    }, [editState])

  const formik = useFormik({
    initialValues: adviceInitialState,
    validationSchema: advicevalidationSchema,
    onSubmit: onSubmit,
  })

  //Set Initial Data
  useEffect(() => {
    if (!show) return formik.resetForm();
    if (!editState) {
      formik.resetForm();
      formik.setFieldValue('symptoms', prescription.hopi_symptoms.map(({ key }: { key: string }) => key))
      return;
    }
    formik.setValues({
      ...formik.values,
      ...editState,
      fromDt: new Date(),
      toDt: null,
    })
  }, [])

  //Set ToDt
  useEffect(() => {
    let type = formik.values.course_duration_type
    let count = formik.values.course_duration_count
    let value
    if (!type || !count) return
    switch (type) {
      case 'day':
        value = addDays(new Date(), count)
        break
      case 'week':
        value = addDays(new Date(), count * 7)
        break
      case 'month':
        value = addDays(new Date(), count * 30)
        break
      case 'manual':
        value = null;
        break
      default:
        value = null
        break
    }
    console.log(value)
    if (!value) return
    formik.setFieldValue('toDt', format(value, 'yyyy-MM-dd'))
  }, [formik.values.course_duration_count, formik.values.course_duration_type])

  const onManualSchedule = () => {
    formik.setFieldValue('course_duration_type', 'manual')
    if (!formik.values.toDt) formik.setFieldValue('toDt', format(new Date(), 'yyyy-MM-dd'))
  }

  // Symptoms and Diseases onChange
  const handleChange = (key: string) => {
    formik.values.symptoms.includes(key)
      ? formik.setFieldValue(
        'symptoms',
        formik.values.symptoms.filter((s: string) => s !== key)
      )
      : formik.setFieldValue('symptoms', [...formik.values.symptoms, key])
  }

  //Time Slot
  const handleHour = (h: Number) => {
    if (formik.values.frequency === 24) {
      return
    }
    formik.setFieldValue(
      'time_slot',
      formik.values.time_slot.includes(h)
        ? formik.values.time_slot.filter((i: Number) => i !== h)
        : formik.values.time_slot.length < formik.values.frequency
          ? [...formik.values.time_slot, h]
          : [...formik.values.time_slot.slice(1), h]
    )
  }

  //Interval
  const handleInterval = (i: string) => {
    formik.setValues((v: any) => ({
      ...v,
      interval: i,
      weekDays: i === 'Daily' ? weekDaysList.map((w: any) => w.value) : [],
    }))
  }

  //Frequency
  const handleFrequency = (f: string | number) => {
    formik.setValues((v: any) => ({
      ...v,
      frequency: f,
      time_slot: f === 24 ? time_slots : [],
    }))
  }

  const handleWeekDays = (val: any) => {
    console.log(val)
    formik.setFieldValue('weekDays', val)
    formik.setFieldValue(
      'weekDays',
      val.map((o: any) => o.value)
    )
  }

  const handleNewSymptom = () => {
    if (!formik.values.new_symptom) {
      return formik.setFieldError('new_symptom', 'Please enter a symptom')
    }
    if (
      [...formik.values.otherSymptoms, ...formik.values.symptoms].includes(
        formik.values.new_symptom
      )
    ) {
      return formik.setFieldError('new_symptom', 'Symptom already exists')
    }
    formik.setFieldValue('otherSymptoms', [
      ...formik.values.otherSymptoms,
      formik.values.new_symptom,
    ])
    formik.setFieldValue('symptoms', [
      ...formik.values.symptoms,
      formik.values.new_symptom,
    ])
    formik.setFieldValue('new_symptom', '')
  }

  const selectMedicine = (e?: any) => {
    //Return If Already Set Manually
    formik.setFieldValue('medicine_name', e?.medicine_name)
    if (!e) return;
    if (formik.touched.medicine_type) return;
    //Else Continue
    if (e.medicine_name.includes('TAB')) {
      formik.setFieldValue('medicine_type', 'Tab')
    } else if (e.medicine_name.includes('CAP')) {
      formik.setFieldValue('medicine_type', 'Capsule')
    } else if (e.medicine_name.includes('INJ')) {
      formik.setFieldValue('medicine_type', 'Injection')
    } else if (e.medicine_name.includes('SYRUP')) {
      formik.setFieldValue('medicine_type', 'Syrup')
    } else if (e.medicine_name.includes('OINTMENT')) {
      formik.setFieldValue('medicine_type', 'Ointment')
    } else if (e.medicine_name.includes('LOTION')) {
      formik.setFieldValue('medicine_type', 'Lotion')
    } else if (e.medicine_name.includes('SPRAY')) {
      formik.setFieldValue('medicine_type', 'Spray')
    }
  }

  return (
    <Dialog.Panel className="pointer-events-auto h-full w-screen max-w-6xl">
      <div className="flex h-full flex-col overflow-y-scroll bg-gray-100 py-6 pt-24 shadow-xl">
        {/* <SliderHeader onClose={() => {}} title={undefined} /> */}
        <div className="mt-6 flex flex-col px-4 sm:px-6">
          <div className="flex items-center justify-between">
            <span className="font-semibold">Medicines:</span>
            <span className="cursor-pointer rounded-full bg-green-400 p-1 text-white">
              <XIcon onClick={toggle} className="h-3 w-3" />
            </span>
          </div>
          <div className="flex space-x-3">
            <div className="flex flex-col items-stretch space-y-4">
              <CreatableSelect
                isClearable
                id="medicine_name"
                placeholder="Select Medicine"
                value={
                  formik.values.medicine_name
                    ? { medicine_name: formik.values.medicine_name }
                    : null
                }
                options={medicineList}
                onChange={selectMedicine}
                onBlur={() =>
                  formik.handleBlur({ target: { name: 'medicine_name' } })
                }
                getOptionLabel={(o) => o.medicine_name}
                getOptionValue={(o) => o.medicine_name}
                getNewOptionData={(o: string) => ({
                  medicine_name: o,
                })}
              />
              <FormErrorMessage
                touched={formik.touched.medicine_name}
                error={formik.errors.medicine_name}
              />

              {formik.values.frequency !== 'SOS' && (
                <>
                  <div className="flex flex-col space-y-2">
                    <span className="font-semibold">Course Duration:</span>
                    <div className="flex">
                      <div className="flex items-center space-x-2">
                        <Button
                          active={formik.values.course_duration_type === 'day'}
                          onClick={() =>
                            formik.setFieldValue('course_duration_type', 'day')
                          }
                          className="px-3"
                          gray
                          outline
                        >
                          Days
                        </Button>
                        <Button
                          active={formik.values.course_duration_type === 'week'}
                          onClick={() =>
                            formik.setFieldValue('course_duration_type', 'week')
                          }
                          className="px-3"
                          gray
                          outline
                        >
                          Weeks
                        </Button>
                        <Button
                          active={formik.values.course_duration_type === 'month'}
                          onClick={() =>
                            formik.setFieldValue('course_duration_type', 'month')
                          }
                          className="px-3"
                          gray
                          outline
                        >
                          Months
                        </Button>
                      </div>
                      <Popover className="ml-auto">
                        <Popover.Button as={'div'} >
                          <Button
                            className='px-3'
                            active={formik.values.course_duration_type === 'manual'}
                            onClick={onManualSchedule}

                            gray
                            outline
                          >
                            {formik.values.toDt && formik.values.course_duration_type === 'manual' ? format(new Date(formik.values.toDt), 'dd MMMM yyyy') : 'Manual'}
                          </Button>
                        </Popover.Button>
                        <Popover.Panel>
                          {/* @ts-ignore */}
                          <Calendar
                            minDate={new Date()}
                            className='max-w-full absolute'
                            date={formik.values.toDt ? new Date(formik.values.toDt) : undefined}
                            onChange={date => formik.setFieldValue('toDt', format(date, 'yyyy-MM-dd'))}
                          />

                        </Popover.Panel>
                      </Popover>

                    </div>
                    <FormErrorMessage
                      touched={formik.touched.course_duration_type}
                      error={formik.errors.course_duration_type}
                    />
                    {formik.values.course_duration_type !== 'manual' && <div className="flex space-x-2">
                      {Array.from({ length: 10 }).map((_, i) => (
                        <Button
                          key={i}
                          rounded
                          active={formik.values.course_duration_count === i + 1}
                          onClick={() =>
                            formik.setFieldValue('course_duration_count', i + 1)
                          }
                          className="px-3"
                          gray
                          outline
                        >
                          {i + 1}
                        </Button>
                      ))}
                    </div>}
                    <FormErrorMessage
                      touched={formik.touched.course_duration_count}
                      error={formik.errors.course_duration_count}
                    />
                  </div>

                  <div className="flex flex-col space-y-2">
                    <span className="font-semibold">Interval:</span>
                    <div className="flex space-x-2">
                      {interval.map((f) => (
                        <Button
                          key={f}
                          active={formik.values.interval === f}
                          onClick={() => handleInterval(f)}
                          className="px-3 "
                          gray
                          outline
                        >
                          {f}
                        </Button>
                      ))}
                    </div>
                    <FormErrorMessage
                      touched={formik.touched.interval}
                      error={formik.errors.interval}
                    />
                  </div>

                  {!!formik.values.interval?.includes('eek') && (
                    <div className="flex flex-col space-y-2">
                      <span className="font-semibold">WeekDays:</span>
                      <Select
                        isMulti
                        id="weekDays"
                        placeholder="Select WeekDays"
                        value={formik.values.weekDays.map((day: string) => ({ label: day, value: day }))}
                        options={weekDaysList}
                        onChange={handleWeekDays}
                        onBlur={() =>
                          formik.handleBlur({ target: { name: 'weekDays' } })
                        }
                      />
                      <FormErrorMessage
                        touched={formik.touched.weekDays}
                        error={formik.errors.weekDays}
                      />
                    </div>
                  )}
                </>
              )}


              <div className="flex flex-col space-y-2">
                <span className="font-semibold">Frequency:</span>
                <div className="flex space-x-2">
                  {frequency.map((f, i) => (
                    <Button
                      key={i}
                      active={formik.values.frequency === f.value}
                      onClick={() => handleFrequency(f.value)}
                      className="px-3 "
                      gray
                      outline
                    >
                      {f.name}
                    </Button>
                  ))}
                </div>
                <FormErrorMessage
                  touched={formik.touched.frequency}
                  error={formik.errors.frequency}
                />
              </div>

              {formik.values.frequency !== 'SOS' && <div className="flex flex-col space-y-2">
                <span className="font-semibold">Time:</span>
                <div className="grid flex-shrink grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-1">
                  {time_slots.map((hour: Number, i) => (
                    <Button
                      key={i}
                      active={formik.values.time_slot.includes(hour)}
                      onClick={() => handleHour(hour)}
                      outline
                      gray
                      className=" whitespace-nowrap text-center "
                    >
                      {format(
                        new Date().setHours(Number(hour), 0, 0, 0),
                        'h:mm a'
                      )}{' '}
                      -{' '}
                      {format(
                        new Date().setHours(Number(hour) + 2, 0, 0, 0),
                        'h:mm a'
                      )}
                    </Button>
                  ))}
                </div>
                <FormErrorMessage
                  touched={formik.touched.time_slot}
                  error={formik.errors.time_slot}
                />
              </div>}
            </div>

            <div className="flex flex-shrink flex-col p-2">
              <div className="flex items-center justify-between">
                <span className="text-sm font-semibold">Symptoms:</span>
              </div>
              <div className="mt-3 grid grid-cols-1 md:grid-cols-4 gap-2">
                {Object.entries(symptom_list).map(([key, label]: any) => (
                  <Button
                    key={key}
                    onClick={() => handleChange(key)}
                    active={formik.values.symptoms.includes(key)}
                    className="px-3"
                    gray
                    outline
                  >
                    {label}
                  </Button>
                ))}
                {formik.values.otherSymptoms.map((symptom: string) => (
                  <Button
                    key={symptom}
                    onClick={() => handleChange(symptom)}
                    active={formik.values.symptoms.includes(symptom)}
                    className="px-3"
                    gray
                    outline
                  >
                    {symptom}
                  </Button>
                ))}
              </div>
              <div className="flex ">
                <Input
                  name="new_symptom"
                  value={formik.values.new_symptom}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  className="mr-2 mt-2"
                />
                <Button onClick={handleNewSymptom} className="px-2 mt-2">
                  ADD
                </Button>
              </div>

              <div className="mt-8 flex items-center justify-between">
                <span className="text-sm font-semibold">Type Of Medicine:</span>
              </div>
              <div className="mt-3 grid grid-cols-2 md:grid-cols-4 gap-2">
                {medicineTypeList.map((s) => (
                  <Button
                    key={s}
                    onBlur={() => formik.setFieldTouched('medicine_type', true)}
                    onClick={() => formik.setFieldValue('medicine_type', s)}
                    gray
                    className="px-3"
                    outline
                    active={formik.values.medicine_type === s}
                  >
                    {s}
                  </Button>
                ))}
              </div>
              <FormErrorMessage
                touched={formik.touched.medicine_type}
                error={formik.errors.medicine_type}
              />
              <div className="mt-3 text-right">
                <textarea
                  className='h-28 w-full p-2 rounded-lg'
                  value={formik.values.remarks}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="remarks"
                  placeholder="Remark"
                />
                <span>{formik.values.remarks.length}/ 500</span>
              </div>
              <FormErrorMessage
                touched={formik.touched.remarks}
                error={formik.errors.remarks}
              />
            </div>
          </div>
          <Button
            disabled={formik.isSubmitting}
            onClick={formik.handleSubmit}
            className="mx-auto mt-6 w-40 text-lg"
          >
            {editState && (formik.isSubmitting ? 'Updating...' : 'Update')}
            {!editState && (formik.isSubmitting ? 'Saving...' : 'Save')}
          </Button>
        </div>
      </div>
    </Dialog.Panel>
  )
}

export default MedicineScheduleSlider
