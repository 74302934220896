import { useRouter } from 'next/router'
import React from 'react'
import QrCardSearch from './medstore/qrscanned/QrCardSearch'

const MedSearchBar = ({ medstoreSearchList, onClose }: { medstoreSearchList: any, onClose: any }) => {

    return (
        <div className="h-full bg-[#F5FAFD] p-3">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {medstoreSearchList.map((qrScan: any) => {
                    return <QrCardSearch qrScanData={
                        qrScan
                    } />
                })}
            </div>
        </div>
    )
}

export default MedSearchBar
