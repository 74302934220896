import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from '../../../utils/axios'
import { notifier } from '../notificationSlice'
// declaring the types for our state
export type MedstoreRefferalState = {
    loading: boolean
    error: any | null
    data: any | []
    otpResponse: any | {}
}

const initialState: MedstoreRefferalState = {
    error: null,
    loading: true,
    data: [],
    otpResponse: {}
}

export const medRefferalSlice = createSlice({
    name: 'medrefferal',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions.
    // In this example, 'increment', 'decrement' and 'incrementByAmount' are actions. They can be triggered from outside this slice, anywhere in the app.
    // So for example, if we make a dispatch to the 'increment' action here from the index page, it will get triggered and change the value of the state from 0 to 1.
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setData: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.data = action.payload
        },
        setOtpResponse: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.otpResponse = action.payload
        },
        setError: (state, action: PayloadAction<any>) => {
            state.loading = false
            state.error = action.payload
        }
    },
})
// Here we are just exporting the actions from this slice, so that we can call them anywhere in our app.
export const { setLoading, setData, setOtpResponse, setError } =
    medRefferalSlice.actions

//API Calls
export const getListData =
    () =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                const response = await axios.get(`/refered/users`)
                const data = response.data.response.data
                dispatch(setData(data))
            } catch (error) {
                dispatch(setError(error))
            }
        }

export const sendOTP =
    (data: any) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                const response = await axios.patch(`/sendOTP`, data)
                const resData = response.data.response
                dispatch(setOtpResponse(resData))
                dispatch(setLoading(false))
            } catch (error) {
                dispatch(setError(error))
                notifier.error("Error try Again")
            }
        }


export const resendOTP =
    (data: any) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                const response = await axios.patch(`/resendOTP`, data)
                const resData = response.data.response
                dispatch(setOtpResponse(resData))
                dispatch(setLoading(false))
            } catch (error) {
                dispatch(setError(error))
                notifier.error("Error try Again")
            }
        }


export const confirmOTP =
    (val: any) =>
        async (dispatch: (arg0: { payload: any; type: string }) => void) => {
            try {
                dispatch(setLoading(true))
                await axios.patch(`/confirmOTP`, val)
                const response = await axios.get(`/refered/users`)
                const data = response.data.response.data
                dispatch(setData(data))
                dispatch(setLoading(false))
            } catch (error) {
                dispatch(setError(error))
                notifier.error("Error try Again")
            }
        }





// exporting the reducer here, as we need to add this to the store
export default medRefferalSlice.reducer
