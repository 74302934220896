import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'

const DocViewer = dynamic(() => import('@cyntler/react-doc-viewer'), {
  ssr: false,
})

const DocumentViewer = ({
  documentURL,
  fileName,
}: {
  documentURL?: any
  fileName?: String
}) => {
  const [load, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [plugins, setPlugins] = useState([])

  useEffect(() => {
    const loadPlugins = async () => {
      const renderPlugins = await import('@cyntler/react-doc-viewer').then(
        ({ DocViewerRenderers }) => DocViewerRenderers
      )
      // @ts-ignore
      setPlugins(renderPlugins)
      if (load) {
        setLoading(false)
        setRefresh(true)
      } else {
        setRefresh(false)
        setLoading(true)
      }
    }
    loadPlugins()
  }, [documentURL])

  const docs = Array.isArray(documentURL)
    ? documentURL.map((doc, index) => {
        return { uri: doc, fileName: `${index + 1}_${fileName}` }
      })
    : [{ uri: documentURL, fileName }]
  return load || refresh ? (
  // @ts-ignore
    <DocViewer
      documents={docs as []}
      pluginRenderers={plugins}
      config={{
        header: {
          disableHeader: docs.length < 2,
          disableFileName: true,
        },
      }}
      prefetchMethod="GET"
      style={{ height: '100%', width: '100%' }}
    ></DocViewer>
  ) : (
    <div>Loading ....</div>
  )
}

export default DocumentViewer
