import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { useAppDispatch, useAppSelector } from '../../../hooks/home'
import { toggleComingSoon } from '../../../store/slices/modalSlice'
import Button from '../../../Comp/Button'
import SvgIcon from "../shared/SvgIcon";

const ComingSoonModal = () => {
    const dispatch = useAppDispatch()
    const show = useAppSelector((state) => state.modal.comingSoon.show)

    const toggle = () => {
        dispatch(toggleComingSoon())
    }

    return (

        <Dialog.Panel className="w-full single-article max-w-xs transform overflow-hidden rounded-2xl h-[380px] text-[#1B1E1F] bg-white p-6 text-center align-middle shadow-xl transition-all">


            <div className='my-4 pb-2'>
                <SvgIcon src="/images/ourTeamProduct.svg" className='object-contain mx-auto' alt='ourTeamProduct' />
                <p className='text-[#003B4D] font-semibold text-xl md:text-2xl mt-3 text-center mx-auto'>Our Team is working hard to deliver you the best product. </p>
                <Button onClick={toggle} children="Go Back" className='p-3 px-5 w-fit mt-6' />
            </div>

        </Dialog.Panel>

    )
}

export default ComingSoonModal;