import { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Header from './Header'
import { useRouter } from 'next/router'
import Slider from './Slider'
import Modals from './modals/Modals'
import NotificationContainer from './shared/NotificationContainer'
import axios from '../../utils/axios'
import SearchPatients from '../SearchBar'
import SocketService from '../../services/socketService'
import SearchModal from './SearchModal'
import { useAppDispatch, useAppSelector } from '../../hooks/home'
import { setModal } from '../../store/slices/patientSlice'

export default function DocLayout({ children }: { children: React.ReactNode }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const { pathname } = useRouter()
  const [patientList, setPatientList] = useState([])
  const [search, setSearch] = useState('')
  const [open, setOpen] = useState(true)

  const dispatch = useAppDispatch()

  const modelOpen = useAppSelector(
    (state) => state.patient?.model
  )



  useEffect(() => {
    var socket = SocketService.socket
    if (!socket) socket = SocketService.init()
    socket.on('patient_joined_queue', (data: any) => {
      console.log('patient_joined_queue', data)
      new Notification(
        `${data.firstName} ${data.lastName || ''} is waiting in Queue !`
      )
    })
  }, [])

  useEffect(() => {
    if (!search) return
    axios
      .get('doctor/search/patient?name=' + search)
      .then((response) => {
        const data = response.data.response.data
        setPatientList(data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [search])

  const onClose = () => {
    setSearch('')
  }

  useEffect(() => {
    if (search) {
      dispatch(setModal(true))
    }

  }, [search])

  useEffect(() => {
    setSearch('')
  }, [pathname])


  return (
    <>
      <div className="flex h-screen flex-col">
        {!pathname.includes('/auth/') &&
          // !pathname.includes("/camp") &&
          !pathname.match('/platform/consultation') && (
            <Header
              search={search}
              setSearch={setSearch}
              mobileMenuOpen={mobileMenuOpen}
              setMobileMenuOpen={setMobileMenuOpen}
            />
          )}

        {/* Hide Header */}
        <div className="flex flex-1 flex-row overflow-hidden">
          {/* Hide sidebar */}
          {!pathname.includes('/auth/') &&
            // !pathname.includes("/camp") &&
            !pathname.match('/platform/consultation') && (
              <Sidebar
                mobileMenuOpen={mobileMenuOpen}
                setMobileMenuOpen={setMobileMenuOpen}
              />
            )}

          {/* Main content */}
          <div className="flex flex-1 items-stretch overflow-hidden">
            <main className="flex-1 overflow-y-auto">
              {/* Primary column */}
              <section
                aria-labelledby="primary-heading"
                className="flex h-full min-w-0 flex-1 flex-col lg:order-last"
              >
                {
                  children
                }
                {
                  search &&

                  <SearchModal {...{ open, setOpen }} setSearch={setSearch} search={search} patientList={patientList} />

                }
              </section>
            </main>
          </div>
        </div>
        <Slider />
        <Modals />
        <NotificationContainer />
      </div>
    </>
  )
}
