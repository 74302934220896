import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import DocLayout from './doctors/DocLayout'
import Layout from './Layout'
import { useAppDispatch, useAppSelector } from '../hooks/home'
import { verifySession } from '../store/slices/authSlice'
import MedstoreLayout from './medstore/MedstoreLayout'
import ClientLayout from './client/ClientLayout'

export default function RootLayout({
  children,
}: {
  children: React.ReactNode
}) {
  const dispatch = useAppDispatch()
  const { pathname, ...router } = useRouter()
  const authState = useAppSelector((state) => state.auth)
  const LayoutComponent = pathname.includes('/platform')
    ? DocLayout
    : pathname.includes('/medstore')
      ? MedstoreLayout
      : pathname.includes('/client')
        ? ClientLayout
        : Layout

  //Verify Auth
  useEffect(() => {
    console.log('VERIFY')
    dispatch(verifySession())
  }, [])

  //Conditions
  const isLoading =
    authState.loading ||
    (!authState.auth &&
      !pathname.includes('/platform/auth/login') &&
      !pathname.includes('/platform/auth/signup') &&
      (pathname.includes('/platform') ||
        pathname.includes('/medStore') ||
        pathname.includes('/client'))) ||
    (authState.auth && pathname.includes('/platform/auth/login'))

  const isPrivateRoute =
    !pathname.includes('/platform/auth/login') &&
    !pathname.includes('/platform/auth/signup') &&
    (pathname.includes('/platform') ||
      pathname.includes('/medstore') ||
      pathname.includes('/client'))

  const isDoctor =
    authState.user?.role === 'DOCTOR' && authState.user?.roleType === 'internal' &&
    (pathname.includes('/platform/auth/login') ||
      pathname.includes('/platform/auth/signup') ||
      pathname.includes('/platform/recharge') ||
      pathname.includes('/platform/writepres') ||
      pathname.includes('/medstore') ||
      pathname.includes('/client'))

  const isExternalDoctor =
    authState.user?.role === 'DOCTOR' && authState.user?.roleType === 'external' &&
    (pathname.includes('/platform/auth/login') ||
      pathname.includes('/platform/dashboard') ||
      pathname.includes('/platform/prescriptions') ||
      pathname.includes('/platform/camp') ||
      pathname.includes('/medstore') ||
      pathname.includes('/client'))

  const isMedStore =
    authState.user?.role === 'MED_STORE' &&
    !pathname.includes('/platform/auth/logout') &&
    (pathname.includes('/platform') || pathname.includes('/client'))

  const isClient =
    authState.user?.role === 'ORG_SUPER_ADMIN' &&
    !pathname.includes('/platform/auth/logout') &&
    (pathname.includes('/platform') || pathname.includes('/medStore'))

  useEffect(() => {
    if (authState.loading) return
    if (!authState.auth && isPrivateRoute) {
      router.push('/platform/auth/login')
    }
    //Auth True
    if (!!authState.user) {
      if (isDoctor) {
        router.push('/platform/dashboard')
      }
      if (isExternalDoctor) {
        router.push('/platform/writepres')
      }
      if (isMedStore) {
        router.push('/medstore/dashboard')
      }
      if (isClient) {
        router.push('/client/products')
      }
    }
  }, [authState])

  if (isLoading) {
    return <div>Loading...</div>
  }

  return <LayoutComponent>{children}</LayoutComponent>
}
