import React from 'react'
import { XCircleIcon } from '@heroicons/react/solid'

const LabelTag = ({
  children,
  className,
  onClick,
}: {
  children: React.ReactNode
  className?: string
  onClick?: () => void
}) => {
  return (
    <div
      style={{ backgroundColor: 'rgb(226 232 240)' }}
      className="inline-flex items-center justify-between space-x-1 rounded-md p-1 text-center text-xs shadow-sm"
    >

      <span>{
            // @ts-ignore 
      typeof children === "object"? Object.entries(children).reduce((str, [key, label] : [any, any]) => str+=` ${label}`, "") : children
      }</span>
      {!!onClick && (
        <span onClick={onClick}>
          <XCircleIcon className="h-4 w-4 cursor-pointer" />
        </span>
      )}
    </div>
  )
}

export default LabelTag
