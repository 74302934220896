import React from 'react'
import classNames from 'classnames'
const SubmitButton = ({
    children,
    className,
    onClick = () => { },
}: {
    children: React.ReactNode
    className?: string
    onClick?: () => void
}) => {
    return (
        <button
            onClick={onClick}
            type="button"
            className={classNames(
                className,
                'inline-flex items-center justify-center rounded-md border'
            )}
        >
            {children}
        </button>
    )
}
export default SubmitButton

